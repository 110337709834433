import React from "react";
import VisitDetails from "./VisitDetails";
import {BUTTON_MSG} from "../../../resources/strings";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import BarResult from "./BarResult";
import Temperatures from "./Temperatures";
import CustomerIssues from "./CustomerIssues";
import TechnicalIssues from "./TechnicalIssues";


class EditVisit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visit: props.visit,
            technicians : []
        }

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {

        const target = event.target
        const value = target.value
        const name = target.name
        var visit = this.state.visit
        visit[name] = value
        this.setState({
            visit: visit,
            saved: false
        });


    }



    componentDidMount() {
        this.getTechnicians();
    }

    getTechnicians() {
        const formData = new FormData();
        formData.append('userID', this.props.userID);

        Axios
            .post(API.users.simpleTechniciansList, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        technicians: res.data.data
                    })
                }
            })
    }


    saveReport() {

        if (this.state.visit) {
            const formData = new FormData();
            formData.append('reportID', this.state.visit.id);
            formData.append('visitNotes', this.state.visit.visitNotes);
            formData.append('signed', this.state.visit.signed);
            formData.append('technicianID', this.state.visit.technicianID);
            formData.append('visittype', this.state.visit.visittype);
            Axios
                .post(API.visits.editReport, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({saved:true, saving: false})

                        //if (window.confirm("Report saved. Would you like to re-send the email notification?")) {

                        //    alert("TODO - send the email again")
                        // }


                    } else {
                        var msg = "Error saving report, please check the details entered and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error saving report. "

                if (error.response) {
                    if (error.response.data.error) {
                        msg = msg + error.response.data.error
                    }
                    alert(msg)
                } else {
                    alert(error)

                }
            })
        }


    }


    resendEmail(){
        const formData = new FormData();
        formData.append('visitID', this.state.visit.id);
        formData.append('approved', true);

        Axios
            .post(API.visits.approveVisit, formData)
            .then((res) => {
                if (res.data.success) {

                    alert("Email sent successfully")
                }
            })
    }


    render() {

        return (<div  className="cell small-12 details-wrap small-pad">

            <div className="deets_sect">
                <div className="cell small-12 small-pad">
                    <div className="deet">
                        <span>Re-send visit notification email</span>
                        <div className={"av-button"} onClick={() => this.resendEmail()}>Re-send Email</div>
                    </div>
                </div>
                <div className="clearfix" />
            </div>
            <div className="deets_sect">

                <div className="cell small-6 small-pad">
                    <div className="deet">
                        <span>Visit Comments</span>
                        <textarea rows={7} value={this.state.visit.visitNotes} onChange={this.handleChange} name="visitNotes" lines className="edit-field" />
                    </div>
                </div>
                <div className="cell small-6 small-pad">
                    <div className="deet">
                        <span>Signed Off By:</span>
                        <input className="edit-field" type="text" name="signed" placeholder="Signed" value={this.state.visit.signed} onChange={this.handleChange}/>
                    </div>
                    <div className="deet">
                        <span>Technician:</span>
                        <select className="edit-field" value={this.state.visit.technicianID} name="technicianID" onChange={this.handleChange}>
                            {this.state.technicians.map((technician, c) => {
                                return <option value={technician.id}>{technician.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="deet">
                        <span>Visit Type:</span>
                        <select className="edit-field" value={this.state.visit.visittype} name="visittype" onChange={this.handleChange}>
                            <option value="Clean">ProClean</option>
                            <option value="SOS1">SOS 1</option>
                            <option value="SOS2">SOS 2</option>
                            <option value="SOS3">SOS 3</option>
                        </select>
                    </div>



                </div>
                <div className="cell small-12 small-pad" style={{textAlign:"center"}}>
                {this.state.saving
                    ?
                    <div className="av-button">Saving...</div>
                    :
                    <div className="av-button" onClick={() => this.setState({saved:false, saving: true}, this.saveReport)}>Save Header Changes</div>
                }
                {this.state.saved && <div>Header Changes Saved Successfully</div>}
                </div>
                <div className="clearfix" />
            </div>


            <div className="deets_sect">

                {this.state.visit.bars.map((bar, c) => {

                    return <div className="cell small-6 small-pad">
                        <div className="deet">
                            <span>{bar.barname}</span>
                            <BarResult  barID={bar.id} barName={bar.barname}  visitID={this.state.visit.id}/>
                        </div>
                    </div>

                })}


                <div className="cell small-6 small-pad">
                    <div className="deet">
                        <span>Temperatures</span>
                        <Temperatures  temperatures={this.state.visit.temperatures} bars={this.state.visit.bars}  visitID={this.state.visit.id}/>
                    </div>
                </div>


                <div className="clearfix" />
            </div>


            <div className="deets_sect">





                <div className="cell small-12 small-pad">
                    <div className="deet">
                        <span>Technical Faults</span>
                        <TechnicalIssues  issues={this.state.visit.techFaults}  visitID={this.state.visit.id}/>
                    </div>
                </div>


                <div className="clearfix" />
            </div>
            <div className="deets_sect">




                <div className="cell small-6 small-pad">
                    <div className="deet">
                        <span>Customer Reported Dispense Issues</span>
                        <CustomerIssues  issues={this.state.visit.custFaults}  visitID={this.state.visit.id}/>
                    </div>
                </div>


                <div className="clearfix" />
            </div>

        </div>);
    }
}

export default EditVisit