import React from "react";
import {ADMIN_SECTIONS, BUTTON_MSG} from "../../../resources/strings";
import Axios from "axios";
import {API} from "../../../api/APIClient"
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";

class ClientDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clientDetails: undefined,
            tableData: undefined,
            page: 0,
            brewers: [],
            clients: undefined,
            usersPage: 0,
            archivePage: 0,
            rowsPerPage: 15,
            migrationClient: -1
        };
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {

        this.getBrewers()

        if (this.props.clientID === -1) {
            this.setState({
                clientDetails: {clientname:""},
                edit_clientname: "",
                edit_brewer: undefined,
                edit_tsp_name: "",
                edit_tsp_phone: "",
                edit_tsp_email: ""
            })
        } else {
            this.getClientDetails()
            this.getLocsForClient()
            this.getArchivedLocsForClient()
            this.getAssocUsersForClient()

        }

    }


    getBrewers() {
        const formData = new FormData();

        Axios
            .post(API.warranty.getBrewers, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        brewers: res.data.brewers,
                    })


                }
            })
    }

    getLocsForClient()
    {
        const formData = new FormData();
        formData.append('clientID', this.props.clientID);

        Axios
            .post(API.locations.locationsForClient, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            tableData: []
                        })
                    } else {
                        this.setState({
                            tableData: res.data.data.map((loc, c) => {



                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{loc.id}</TableCell>
                                    <TableCell component="th" scope="row">{loc.locationname}</TableCell>
                                    <TableCell component="th" scope="row">{loc.address}</TableCell>
                                    <TableCell>
                                        <div onClick={() => this.props.didSelectVenue(loc.id)} className="av-button" style={{padding: "2px 30px"}}>Edit</div>
                                        <div onClick={() => this.startMigration(loc)} className="av-button" style={{padding: "2px 30px"}}>Migrate</div>
                                    </TableCell>
                                </TableRow>


                            })

                        })

                    }
                }
            })
    }


    startMigration(loc) {
        this.getClients()
        this.setState({migrationVenue:loc, migrationClient: -1})
    }

    completeMigration() {
        if (this.state.migrationClient > 0) {
            const formData = new FormData();
            formData.append('clientID', this.state.migrationClient);
            formData.append('locationID', this.state.migrationVenue.id);
            Axios
                .post(API.locations.migrateLocation, formData)
                .then((res) => {
                    this.setState({migrationVenue:undefined})
                    alert("Location migrated successfully")
                    this.getLocsForClient()
                })
        } else {
            alert("Please select a client to migrate to.")
        }
    }

    getArchivedLocsForClient()
    {
        const formData = new FormData();
        formData.append('clientID', this.props.clientID);

        Axios
            .post(API.locations.archivedLocationsForClient, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            archiveTableData: []
                        })
                    } else {
                        this.setState({
                            archiveTableData: res.data.data.map((loc, c) => {
                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{loc.id}</TableCell>
                                    <TableCell component="th" scope="row">{loc.locationname}</TableCell>
                                    <TableCell component="th" scope="row">{loc.address}</TableCell>
                                    <TableCell>
                                        <div onClick={() => this.props.didSelectVenue(loc.id)} className="av-button" style={{padding: "2px 30px"}}>Edit</div>
                                    </TableCell>
                                </TableRow>
                            })

                        })

                    }
                }
            })
    }

    getClients() {
        const formData = new FormData();

        Axios
            .post(API.clients.adminClientsList, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        clients: res.data.clients,
                    })


                }
            })
    }


    getAssocUsersForClient()
    {
        const formData = new FormData();
        formData.append('clientID', this.props.clientID);

        Axios
            .post(API.clients.usersForClient, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            usersTableData: []
                        })
                    } else {
                        this.setState({
                            usersTableData: res.data.data.map((user, c) => {



                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{user.name}</TableCell>
                                    <TableCell>{parseInt(user.technician) === 1 ?
                                        <div>{parseInt(user.admin) === 1 ? "Admin Technician" : "Technician"}</div>
                                        :
                                        <div>{parseInt(user.admin) === 1 ? "Administrator" : "Client User"}</div>
                                    }</TableCell>
                                    <TableCell>
                                        <div onClick={() => this.props.didSelectUser(user.id)} className="av-button" style={{padding: "2px 30px"}}>View</div>
                                    </TableCell>
                                </TableRow>


                            })

                        })

                    }
                }
            })
    }


    componentWillUnmount() {

        if(this.props.buttonMessage === BUTTON_MSG.EDIT_CLIENT) {
            if (window.confirm("Editing in progress - do you want to save before you leave?")) {
                this.saveClient()
            }
        }

    }

    componentWillReceiveProps(nextProps) {

        if (this.props.clientID === -1 && nextProps.buttonMessage === undefined)
        {
            this.props.itemClickHandler(ADMIN_SECTIONS.CLIENTS) //go back
        }
        if(nextProps.buttonMessage === BUTTON_MSG.SAVE_CLIENT){
            this.saveClient()
        }
        if(nextProps.buttonMessage === BUTTON_MSG.EDIT_CLIENT && this.props.buttonMessage !== BUTTON_MSG.SAVE_CLIENT){


            this.setState({
                edit_clientname: this.state.clientDetails.clientname,
                edit_brewer: this.state.clientDetails.lead_brewer_id ? this.state.clientDetails.lead_brewer_id : undefined,
                edit_tsp_name: this.state.clientDetails.tsp_name ? this.state.clientDetails.tsp_name : "",
                edit_tsp_phone: this.state.clientDetails.tsp_phone ? this.state.clientDetails.tsp_phone : "",
                edit_tsp_email: this.state.clientDetails.tsp_email ? this.state.clientDetails.tsp_email : ""
            })
        }
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

    }


    saveClient() {

        if (this.state.edit_clientname) {
            const formData = new FormData();
            formData.append('clientID', this.props.clientID);
            formData.append('clientname', this.state.edit_clientname);
            if (this.state.edit_brewer) {
                formData.append('brewerID', this.state.edit_brewer);
            }
            formData.append('tsp_name', this.state.edit_tsp_name);
            formData.append('tsp_phone', this.state.edit_tsp_phone);
            formData.append('tsp_email', this.state.edit_tsp_email);

            Axios
                .post(API.clients.editClient, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.props.buttonMessageHandler(undefined);

                        if (this.props.clientID === -1 && res.data.result){
                            this.props.didSelectClient(res.data.result)
                        } else {
                            this.getClientDetails()
                        }
                    } else {
                        var msg = "Error saving client, please check the details entered and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error saving client. "

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the client name field.")
            this.props.buttonMessageHandler(BUTTON_MSG.EDIT_CLIENT);
        }


    }


    getClientDetails()
    {

        const formData = new FormData();
        formData.append('clientID', this.props.clientID);

        Axios
            .post(API.clients.getClient, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.props.itemClickHandler(ADMIN_SECTIONS.CLIENTS) //no details...just go back?

                    } else {
                        this.setState({
                            clientDetails: res.data.data
                        })
                    }
                }
            })
    }





    render() {

        return(<div>
            {this.state.clientDetails === undefined ?

                <div>Loading...</div>

                :
                <div className="cell small-12 details-wrap user_deets small-pad">

                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Main Details</div>
                        </div>

                        <div className="cell small-6 small-pad">
                            <div className="deet">
                                <span>Client Name</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_CLIENT ?
                                <input className="edit-field" type="text" name="edit_clientname" placeholder="Client Name" value={this.state.edit_clientname} onChange={this.handleChange}/>
                                :
                                <div className="field">{this.state.clientDetails.clientname}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-6 small-pad">
                            <div className="deet">
                                <span>Lead Brewer</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_CLIENT ?

                                    <select className="edit-field" value={this.state.edit_brewer} name="edit_brewer" onChange={this.handleChange}>
                                        <option value="-1">Select....</option>
                                        {this.state.brewers.map((brewer, c) => {
                                            return <option value={brewer.id}>{brewer.brewerName}</option>
                                        })}
                                    </select>
                                :
                                <div className="field">{this.state.clientDetails.lead_brewer_name}</div>

                                 }
                            </div>
                        </div>

                        <div className="clearfix"></div>
                    </div>

                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Tech Service Provider Details</div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>TSP Name</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_CLIENT ?
                                    <input className="edit-field" type="text" name="edit_tsp_name" placeholder="TSP Name" value={this.state.edit_tsp_name} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.clientDetails.tsp_name}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>TSP Phone</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_CLIENT ?
                                    <input className="edit-field" type="text" name="edit_tsp_phone" placeholder="TSP Phone" value={this.state.edit_tsp_phone} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.clientDetails.tsp_phone}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>TSP Email</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_CLIENT ?
                                    <input className="edit-field" type="text" name="edit_tsp_email" placeholder="TSP Email" value={this.state.edit_tsp_email} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.clientDetails.tsp_email}</div>
                                }
                            </div>
                        </div>

                        <div className="clearfix"></div>
                    </div>


                </div>

            }


            {(this.state.tableData && this.props.buttonMessage !== BUTTON_MSG.EDIT_CLIENT) &&
            <div className="cell small-12 admin-wrap">
                <div className="admin-set-box">
                    <div className="cell small-12 title-box">
                        <div className="title">Venues</div>
                    </div>
                    <div className="cell small-12">
                        <div className="av-button"  onClick={() => this.props.didSelectVenue(-1)}>Add New Venue</div>
                    </div>
                    <div className="clearfix" />
                </div>



                <div className="cell small-12 no-pad">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Venue</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.tableData &&
                    <TablePagination
                        rowsPerPageOptions={[15, 30, 100]}
                        component={Paper}
                        count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>

            </div>}



            {(this.state.usersTableData && this.props.buttonMessage !== BUTTON_MSG.EDIT_CLIENT) &&
            <div className="cell small-12 admin-wrap">
                <div className="admin-set-box">
                    <div className="cell small-12 title-box">
                        <div className="title">Associated Users</div>
                    </div>
                    <div className="clearfix" />
                </div>



                <div className="cell small-12 no-pad">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>User Type</TableCell>
                                    <TableCell>  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.usersTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.usersTableData.slice(this.state.usersPage * this.state.rowsPerPage, this.state.usersPage * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.usersTableData &&
                    <TablePagination
                        rowsPerPageOptions={[15, 30, 100]}
                        component={Paper}
                        count={this.state.usersTableData === undefined ? 0 : this.state.usersTableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.usersPage}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({usersPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>

            </div>}




            {(this.state.archiveTableData && this.props.buttonMessage !== BUTTON_MSG.EDIT_CLIENT) &&
            <div className="cell small-12 admin-wrap">
                <div className="admin-set-box">
                    <div className="cell small-12 title-box">
                        <div className="title">Archived Venues</div>
                    </div>
                    <div className="clearfix" />
                </div>



                <div className="cell small-12 no-pad">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Venue</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.archiveTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.archiveTableData.slice(this.state.archivePage * this.state.rowsPerPage, this.state.archivePage * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.archiveTableData &&
                    <TablePagination
                        rowsPerPageOptions={[15, 30, 100]}
                        component={Paper}
                        count={this.state.archiveTableData === undefined ? 0 : this.state.archiveTableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.archivePage}
                        onChangePage={(event, newPage) => {

                            this.setState({archivePage: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({archivePage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>

            </div>}

            {(this.state.migrationVenue) && <div className="modal">
                <div className="modal-contact-box main-wrap" style={{width:"540px"}}>
                    <h1>Migrate Venue</h1>
                    <div>&nbsp;</div>
                    {this.state.clients ?
                        <div className="small-12">
                            <div className="">
                                <div className="title">Select which Client you want to move {this.state.migrationVenue.locationname} to</div>
                                <div className="small-12 cell"></div>
                                <select className="edit-field" value={this.state.migrationClient} name="migrationClient" onChange={this.handleChange}>
                                    <option value="-1">Select a client</option>

                                    {this.state.clients.map((client, c) => {
                                        return <option value={client.id}>{client.name}</option>
                                    })}
                                </select>
                                <div>&nbsp;</div>
                                <div onClick={() => this.completeMigration()} className="av-button" style={{padding: "9px 30px"}}>Migrate</div>
                                <div onClick={()=>this.setState({migrationVenue:undefined})} className="av-button" style={{padding: "9px 30px"}}>Cancel</div>

                            </div>
                        </div>
                        :
                        <div>Loading clients...</div>
                    }
                </div>
            </div>
            }

        </div>)
    }
}
export default ClientDetails;

