import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import MyPie from "../../common/MyPie";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core";


class VisitDetails extends React.Component {

    constructor(props) {
        super(props);

        var waste = 0;
        for (var c=0; c < this.props.visit.bars.length; c++) {
            const parsedVal = parseFloat(this.props.visit.bars[c].wastage, 10);
            if (!isNaN(parsedVal)) {
                waste = waste + parsedVal
            }

        }

        var sumTble = this.props.visit.summaryData.map((row, c) => {

            return <TableRow key={c}>
                <TableCell component="th" scope="row">{row.title}<span className={"dot_" + row.title}></span></TableCell>
                <TableCell><span className={"dot_" + row.value} />{row.value}</TableCell>
                <TableCell>{row.percentage}%</TableCell>
            </TableRow>

        })

        sumTble.push(<TableRow key="totals">
            <TableCell component="th" scope="row">Total</TableCell>
            <TableCell>{this.props.visit.total}</TableCell>
            <TableCell>100%</TableCell>
        </TableRow>)


        this.state = {
            rowsPerPage: 10,
            custFaultPage:0,
            techFaultsPage:0,
            barPage: 0,
            sumPage: 0,
            bar:-1,
            resPage:0,
            tempPage:0,
            wastage: waste.toFixed(1),
            summaryTableData:sumTble,
            summaryPieData: this.props.visit.summaryData.map((segment, c) => {

                var parsedVal = parseFloat(segment.percentage, 10);
                if (isNaN(parsedVal)) {
                    parsedVal = 0
                }

                return {title: segment.title, value: parsedVal, color: segment.color};

            }),
            tempResults: this.props.visit.temperatures.map((row, c) => {

                return <TableRow key={c}>
                    <TableCell component="th" scope="row">{row.type}</TableCell>
                    <TableCell>{row.barname}</TableCell>
                    <TableCell>{row.remotename}</TableCell>
                    <TableCell>{row.reading}</TableCell>
                </TableRow>

            })
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {

            this.setState({ tempResults: nextProps.visit.temperatures.map((row, c) => {

                    return <TableRow key={c}>
                        <TableCell component="th" scope="row">{row.type}</TableCell>
                        <TableCell>{row.barname}</TableCell>
                        <TableCell>{row.remotename}</TableCell>
                        <TableCell>{row.reading}</TableCell>
                    </TableRow>

                })
            });
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });


        if (name === "bar") {
            this.setState({
                [name]: value,
                resPage: 0
            });


            this.getBarCleanResults(value)
        } else if (name === "cleanBar") {
            this.setState({
                [name]: value
            });
            this.getBarSummary(value)
        }
        else {
            this.setState({
                [name]: value
            });


        }
    }

    componentDidMount() {

        if (this.props.visit.bars && this.props.visit.bars.length > 0)
        {
            this.setState({
                "cleanBar" : 0,
                "bar" : 0,
                resPage: 0

            });
            this.getBarSummary(0)
            this.getBarCleanResults(0)

        }

    }


    getBarSummary(bar) {

        if (bar === -1){
            this.setState({barSummaryPieData: undefined, barSummaryTableData : undefined})
            return
        }
        const formData = new FormData();
        formData.append('visitID', this.props.visit.id);
        formData.append('barID', this.props.visit.bars[bar].id);
        formData.append('userID', this.props.userid);

        Axios
            .post(API.locations.summaryForBar, formData)
            .then((res) => {

                if (res.data.success) {

                    var sumTble = res.data.barSummary.summaryData.map((row, c) => {

                        return <TableRow key={c}>
                            <TableCell component="th" scope="row">{row.title}<span className={"dot_" + row.title}></span></TableCell>
                            <TableCell><span className={"dot_" + row.value} />{row.value}</TableCell>
                            <TableCell>{row.percentage}%</TableCell>
                        </TableRow>

                    })
                    sumTble.push(<TableRow key="totals">
                        <TableCell component="th" scope="row">Total</TableCell>
                        <TableCell>{res.data.barSummary.total}</TableCell>
                        <TableCell>100%</TableCell>
                    </TableRow>)

                    this.setState({
                        barWastage: res.data.barSummary.wastage,
                        barSummaryPieData: res.data.barSummary.summaryData.map((segment, c) => {

                            var parsedVal = parseFloat(segment.percentage, 10);
                            if (isNaN(parsedVal)) {
                                parsedVal = 0
                            }

                            return {title: segment.title, value: parsedVal, color: segment.color};

                        }),
                        barSummaryTableData:sumTble
                    })
                }
            })

    }

    getBarCleanResults(bar) {

        if (bar === -1){
            this.setState({barcleanResults: undefined})
                return
        }

        const formData = new FormData();
        formData.append('visitID', this.props.visit.id);
        formData.append('barID', this.props.visit.bars[bar].id);
        formData.append('userID', this.props.userid);

        Axios
            .post(API.locations.resultsForBar, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        barcleanResults: res.data.data.map((row, c) => {

                            return <TableRow key={c}>
                                <TableCell component="th" scope="row">{this.props.visit.bars[bar].barname}</TableCell>
                                <TableCell>{row.product}</TableCell>
                                <TableCell>{row.preclean}</TableCell>
                                <TableCell>{row.phtest === '1' ? 'Yes' : 'No'}</TableCell>
                            </TableRow>

                        })
                    })
                }
            })
    }


    enlargeImg(e, img) {
        e.stopPropagation()
        this.setState({bigImage: img})
    }

    enlargeVid(e, vid) {
        e.stopPropagation()//still needed so we dont trigger the fault details page
       // sbe not needed, there is a full screen button in the small player this.setState({bigVideo: vid})
    }

    cleanType(code) {

        code = code.toLowerCase()
        switch (code) {
            case "clean" :
                return "ProClean"
            case "sos1" :
                return "SOS 1"
            case "sos2" :
                return "SOS 2"
            case "sos3" :
                return "SOS 3"
            default:
                return ""
        }
    }
    render() {
        const theme = createMuiTheme({
            overrides: {
                MuiTableCell: {
                    root: {  //This can be referred from Material UI API documentation.
                        padding: '10px 8px'},
                }
            },
        });
        return (
            <div className="clientsbody visit-wrap">

                <div className="cell small-12 container visit-summary">
                    <div className="title">Single Visit Summary - {this.props.visit.date}</div>
                    <div className="cell small-4 no-pad"><div className="subtitle">Visit Date</div><div className="result">{this.props.visit.date}</div></div>
                    <div className="cell small-4 no-pad"><div className="subtitle">Technician</div><div className="result">{this.props.visit.name}</div></div>
                    <div className="cell small-4 no-pad"><div className="subtitle">Technical Faults</div><div className="result">{this.props.visit.techFaults.length}</div></div>
                    <div className="cell small-4 no-pad"><div className="subtitle">Customer Reported Issues</div><div className="result">{this.props.visit.custFaults.length}</div></div>
                    <div className="cell small-4 no-pad"><div className="subtitle">Signed Off By</div><div className="result">{this.props.visit.signed}</div></div>
                    <div className="cell small-4 no-pad"><div className="subtitle">Visit Type</div><div className="result">{this.cleanType(this.props.visit.visittype)}</div></div>

                </div>
                
                <div className="cell small-12 container">
                    <div className="cell small-6 no-pad">
                        <div className="title"><b>Visit Comments</b></div>

                        {this.props.visit.visitNotes ?

                            <div className="cell small-12 comment-box">
                                <div className="cell small-12 inner-comment" dangerouslySetInnerHTML={{ __html: this.props.visit.visitNotes.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div>
                            </div>
                            :
                            
                            <div className="cell small-12 comment-box">
                                <div className="cell small-12 inner-comment"><span className="nocom">No comments submitted</span></div>
                            </div>
                        }

                    </div>
                        <div className="cell small-6 print-12 no-pad">
                            <div className="title"><b>Customer Reported Dispense Issues</b></div>
                            <div className="cell small-12 small-pad" />
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.props.visit.custFaults.length === 0 ? <span className="nodatamsg">No data found for this selection.</span>
                                                :
                                                this.props.visit.custFaults.slice(this.state.custFaultPage * this.state.rowsPerPage, this.state.custFaultPage * this.state.rowsPerPage + this.state.rowsPerPage).map((row, c) => {


                                                    return <TableRow key={c}>
                                                        <TableCell component="th" scope="row">{row.issue}</TableCell>

                                                    </TableRow>


                                                })


                                        }
                                    </TableBody>
                                </Table>

                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component={Paper}
                                count={this.props.visit.custFaults === undefined ? 0 : this.props.visit.custFaults.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.custFaultPage}
                                onChangePage={(event, newPage) => {

                                    this.setState({custFaultPage: newPage})
                                }}
                                onChangeRowsPerPage={(event) => {
                                    this.setState({custFaultPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                }}
                            />

                        </div>

                </div>


                <div className="cell small-12 container">

                    <div className="cell small-12 no-pad print-12">

                        <div className="title"><b>Technical Faults Report</b></div>
                        <div className="cell small-12 small-pad" />
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="clean results">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Recurring?</TableCell>
                                        <TableCell >Priority</TableCell>
                                        <TableCell>Logged?</TableCell>
                                        <TableCell>Resolved?</TableCell>
                                        <TableCell width={144}>  </TableCell>
                                        <TableCell width={144}>  </TableCell>
                                        <TableCell width={144}> </TableCell>
                                        <TableCell width={144}> </TableCell>
                                        <TableCell width={144}> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.visit.techFaults.length === 0 ? <span className="nodatamsg">No data found for this selection.</span>
                                        :
                                        this.props.visit.techFaults.slice(this.state.techFaultsPage * this.state.rowsPerPage, this.state.techFaultsPage * this.state.rowsPerPage + this.state.rowsPerPage).map((row, c) => {

                                            var imgs = ["", "", "", ""," "];
                                            for (var i = 0; i < row.images.length; i++){
                                                let imgIdx = i;
                                                imgs[i] = <img alt={"fault"} onClick={(e,i) => this.enlargeImg(e,row.images[imgIdx].image)} className="uploadImg" src={row.images[i].image}/>
                                            }

                                            for (var j = 0; j < row.videos.length; j++){
                                                let vidIdx = j;
                                                imgs[i+j] = <div onClick={(e,i) => this.enlargeVid(e,row.videos[vidIdx].video)} className="cell small-12 video no-pad"><video width="99%" autoPlay={false}  muted={false} controls={true} src={row.videos[vidIdx].video}></video></div>
                                            }

                                            return <TableRow key={c} onClick={() => this.props.didSelectFault(row.id)} style={{cursor: "pointer"}}>
                                                <TableCell component="th" scope="row">{row.issue}</TableCell>
                                                <TableCell>{row.recurring === "1" ? "Yes" : "No"}</TableCell>
                                                <TableCell className={"priority_"+row.urgency.toLowerCase()}><span>{row.urgency}</span></TableCell>
                                                <TableCell>{row.logged}</TableCell>
                                                <TableCell>{row.actioned}</TableCell>
                                                <TableCell>{imgs[0]}</TableCell>
                                                <TableCell>{imgs[1]}</TableCell>
                                                <TableCell>{imgs[2]}</TableCell>
                                                <TableCell>{imgs[3]}</TableCell>
                                                <TableCell>{imgs[4]}</TableCell>
                                            </TableRow>


                                        })
                                    }
                                </TableBody>
                            </Table>

                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component={Paper}
                            count={this.props.visit.techFaults === undefined ? 0 : this.props.visit.techFaults.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.techFaultsPage}
                            onChangePage={(event, newPage) => {

                                this.setState({techFaultsPage: newPage})
                            }}
                            onChangeRowsPerPage={(event) => {
                                this.setState({techFaultsPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                            }}
                        />
                    </div>


                </div>

                <div className="cell small-12 container summary-pro-clean">
                    <div className="cell small-12 no-pad">
                        <div className="title"><b>Summary of ProClean Results</b></div>
                    </div>

                    <div className="cell small-6 no-pad"><div className="title">Total for Venue</div></div>
                    <div className="cell small-3 no-pad">&nbsp;</div>
                    <div className="cell small-3 no-pad">
                        <div className="title">Total Venue Wastage From Last Visit In Pints</div>
                    </div>

                    <div className="cell small-6 no-pad">
                        <div className="cell small-12 small-pad"/>

                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>

                                <Table  size="small" padding="none" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Score</TableCell>
                                            <TableCell>Number Of Lines By Result</TableCell>
                                            <TableCell>Total Percentage</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.summaryTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.summaryTableData}
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                    </div>
                    <div className="cell small-3 no-pad small-pie-wrap">
                        <div className="pie-view">
                            <MyPie  data={this.state.summaryPieData} />
                        </div>
                    </div>
                    <div className="cell small-3  no-pad">
                        <div className="wastage">
                        <span className="loz"> 
                            <span className="loz-stat">
                                {Math.round(this.state.wastage * 10) / 10}
                            </span>
                        </span>
                        </div>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="clean results">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Bar Name</b></TableCell>
                                        <TableCell><b>Wastage</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.visit.bars.slice(this.state.barPage * this.state.rowsPerPage, this.state.barPage * this.state.rowsPerPage + this.state.rowsPerPage).map((row, c) => {
                                        return <TableRow key={c}>
                                            <TableCell component="th" scope="row">{row.barname}</TableCell>
                                            <TableCell>{Math.round(row.wastage * 10) / 10}</TableCell>

                                        </TableRow>


                                    })


                                    }
                                </TableBody>
                            </Table>

                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component={Paper}
                            count={this.props.visit.bars === undefined ? 0 : this.props.visit.bars.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.barPage}
                            onChangePage={(event, newPage) => {

                                this.setState({barPage: newPage})
                            }}
                            onChangeRowsPerPage={(event) => {
                                this.setState({barPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                            }}
                        />
                    </div>
                </div>


                


                <div className="cell small-12 container summary-per-bar">
                    <div className="cell small-12 no-pad">
                        <div className="title"><b>Summary of ProClean Results per bar</b></div>
                    </div>

                    <div className="cell small-6 no-pad">
                        <div className="subtitle">Select a bar</div>
                        <select className="edit-field" value={this.state.cleanBar} name="cleanBar" onChange={this.handleChange}>
                            {this.state.bar === -1 && <option value="-1">Choose a bar</option>}
                            {this.props.visit.bars.map((bar, c) => {

                                return <option value={c}>{bar.barname}</option>

                            })}
                        </select>
                        

                        <TableContainer component={Paper}>
                            <ThemeProvider theme={theme}>

                                <Table  size="small" padding="none" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Score</TableCell>
                                            <TableCell>Number Of Lines By Result</TableCell>
                                            <TableCell>Total Percentage</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.barSummaryTableData === undefined ? <TableRow><TableCell><span className="nodatamsg">No data found for this selection.</span></TableCell></TableRow> : this.state.barSummaryTableData}
                                    </TableBody>
                                </Table>
                            </ThemeProvider>
                        </TableContainer>
                    </div>
                    <div className="cell small-3 no-pad">&nbsp;</div>
                    <div className="cell small-3 no-pad">
                        <div className="title">Total Bar Wastage in pints</div>
                    </div>

                    <div className="cell small-6 no-pad">
                        
                    </div>
                    <div className="cell small-3 no-pad small-pie-wrap">
                        <div className="pie-view">
                            <MyPie  data={this.state.barSummaryPieData} />
                        </div>
                    </div>
                    <div className="cell small-3  no-pad">
                        

                        <div className="wastage">
                            <span className="loz"> 
                                <span className="loz-stat">
                                    {this.state.barWastage ? Math.round(this.state.barWastage * 10) / 10
                                         : "-"}
                                </span>
                            </span>
                        </div>

                    </div>
                </div>


                


                <div className="cell small-12 container summary-indiv">
                    <div className="cell small-12 no-pad">
                        
                    </div>
                    <div className="cell small-6 no-pad">
                        <div className="title"><b>ProClean results by bar and tap</b></div>
                        <div className="subtitle">Select a bar</div>

                    
                        <select className="edit-field" value={this.state.bar} name="bar" onChange={this.handleChange}>
                        {this.state.bar === -1 && <option value="-1">Choose a bar</option>}
                        {this.props.visit.bars.map((bar, c) => {

                            return <option value={c}>{bar.barname}</option>

                        })}
                    </select>

                    {this.state.barcleanResults &&

                        <div>

                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Bar</TableCell>
                                            <TableCell>Line</TableCell>
                                            <TableCell>Pre-Clean<br/>result</TableCell>
                                            <TableCell>pH Test<br/>Performed</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.barcleanResults.length === 0 ? <span className="nodatamsg">No data found for this selection.</span>
                                            :
                                            this.state.barcleanResults.slice(this.state.resPage * this.state.rowsPerPage, this.state.resPage * this.state.rowsPerPage + this.state.rowsPerPage) }
                                    </TableBody>
                                </Table>

                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component={Paper}
                                count={this.state.barcleanResults === undefined ? 0 : this.state.barcleanResults.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.resPage}
                                onChangePage={(event, newPage) => {

                                    this.setState({resPage: newPage})
                                }}
                                onChangeRowsPerPage={(event) => {
                                    this.setState({resPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                }}
                            />

                        </div>
                    }

                    </div>
                    <div className="cell small-1 no-pad">&nbsp;</div>

                    <div className="cell small-5 no-pad">
                        <div className="title"><b>Temperatures</b></div>
                        <div className="cell small-12 small-pad"/>

                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="clean results">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Bar</TableCell>
                                        <TableCell>Remote/Cellar Name</TableCell>
                                        <TableCell>Reading</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.tempResults.length === 0 ? <span className="nodatamsg">No data found for this selection.</span>
                                        :
                                        this.state.tempResults.slice(this.state.tempPage * this.state.rowsPerPage, this.state.tempPage * this.state.rowsPerPage + this.state.rowsPerPage) }
                                </TableBody>
                            </Table>

                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component={Paper}
                            count={this.state.tempResults === undefined ? 0 : this.state.tempResults.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.tempPage}
                            onChangePage={(event, newPage) => {

                                this.setState({tempPage: newPage})
                            }}
                            onChangeRowsPerPage={(event) => {
                                this.setState({tempPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                            }}
                        />

                    </div>
                    <div className="cell small-12 small-pad" />

                    <div className="cell small-12 small-pad">
                        All lines are cleaned to a verified 'A' on each ProClean visit</div>
                </div>

                <div className="cell small-12 small-pad" />

                {this.props.visit.signature &&
                <div className="cell small-12 container summary-indiv">
                    <div className="title"><b>Report Signature</b></div>
                    {this.props.visit.signed &&
                    <div className="cell small-12 small-pad" style={{textAlign: "left"}}>
                        Signed off by: {this.props.visit.signed}
                    </div>
                    }

                    <div className="cell small-12 small-pad" style={{textAlign: "left"}}>
                        <img alt={"signature"} src={this.props.visit.signature} style={{border: "1px solid #555", width:"269px"}}/>
                    </div>

                </div>
                }

                <div className="cell small-12 small-pad" />

                {(this.state.bigImage) && <div className="modal">
                    <div className="modal-contact-box main-wrap">
                        <div style={{textDecoration:"underline", cursor:"pointer"}} onClick={()=>this.setState({bigImage:undefined})}>Close</div>
                        <div>&nbsp;</div>
                        <img alt="enlarged fault" width={"69%"} src={this.state.bigImage}/>
                    </div>
                </div>
                }

            </div>

        );
    }
}
export default VisitDetails