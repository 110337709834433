import axios from 'axios'

//const BASE_URL = "http://localhost.charlesproxy.com/avani_dashboard_api/";
//const BASE_URL = "https://avanidevdashapi.app-cms.com/";
const BASE_URL = "https://testavanidashapi.app-cms.com/";
//const BASE_URL = "https://avanidashapi.app-cms.com/";


const VERSION = "1.0.0";
axios.defaults.headers.common['appplatform'] = 'dashboard';
axios.defaults.headers.common['appversion'] = VERSION;


axios.defaults.baseURL = BASE_URL;

export const APIHelper = (function(axios){
    return {
        setAuthDetails: (token, userID) => {
            axios.defaults.headers.common['token'] = token;
            axios.defaults.headers.common['userId'] = userID;
        },
        removeAuthDetails: () => {
            delete axios.defaults.headers.common['token'];
            delete axios.defaults.headers.common['userId'];
        },
        onAuthFailed: (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    }
                    else {
                        return status;
                    }
                }
            }
        }
    }
})(axios);

export const API = {
    BASE_URL: BASE_URL,
    auth: {
        login: "auth/login",
        resetPassword: "auth/requestPasswordReset/",
        changePassword: "auth/changePassword/",
        getLoginExpiry: "auth/getLoginExpiry"
    },
    settings: {
        getTechSetting: "settings/getTechSetting/",
        setTechSetting: "settings/setTechSetting/",
        setWelcomeShown: "settings/setWelcomeShown/"
    },
    locations: {
        locationsForUser: "location/locationsForUser",
        clientsAndVenuesForUser: "location/clientsAndVenuesForUser",
        clientsAndVenuesForUser2: "location/clientsAndVenuesForUser2",
        venuesForUserAndClient : "location/venuesForUserAndClient",
        setEmailSetting: "location/setEmailSetting",
        getDetails: "location/getDetails",
        allClientsAndVenues: "location/allClientsAndVenues",
        locationsForClient: "location/locationsForClient",
        editLocation: "location/editLocation",
        simpleLocationsList: "location/simpleLocationsList",
        latestVisitSimple: "location/latestVisitSimple",
        getVisit: "location/getVisit",
        resultsForBar: "location/resultsForBar",
        getSummaryData: "location/getSummaryData",
        getSummaryData2: "location/getSummaryData2",
        getResDetails2: "location/getResDetails2",
        getBarChartDataForVenue: "location/getBarChartDataForVenue",
        getBarChartDataForVenue2: "location/getBarChartDataForVenue2",
        getFaultsTableData2: "location/getFaultsTableData2",
        getBars: "location/getBars",
        getTaps: "location/getTaps",
        editBar: "location/editBar",
        editTap: "location/editTap",
        deleteBar: "location/deleteBar",
        deleteTap: "location/deleteTap",
        summaryForBar: "location/summaryForBar",
        setLocState:"location/setLocState",
        getLocState: "location/getLocState",
        setAllEmailSettings: "location/setAllEmailSettings",
        archivedLocationsForClient: "location/archivedLocationsForClient",
        getRemotes: "location/getRemotes",
        editRemote: "location/editRemote",
        deleteRemote: "location/deleteRemote",
        getCellarLinks: "location/getCellarLinks",
        saveCellarLinks: "location/saveCellarLinks",
        saveRemoteLinks: "location/saveRemoteLinks",
        getRemoteLinks : "location/getRemoteLinks",
        usersForVenue : "location/usersForVenue",
        getNotes : "location/getNotes",
        deleteNote: "location/deleteNote",
        addNote : "location/addNote",
        getVenueName: "location/getVenueName",
        migrateLocation: "location/migrateLocation",
        showFaultsPDF: "location/getFaultsPDF"

    },
    clients: {
        adminClientsList: "client/adminClientsList",
        getClient: "client/getClient",
        editClient: "client/editClient",
        getSummaryData: "client/getSummaryData",
        getSummaryData2: "client/getSummaryData2",
        getWastageTableData2: "client/getWastageTableData2",
        getFaultsTableData2: "client/getFaultsTableData2",
        simpleClientsList: "client/simpleClientsList",
        simpleClientsList2: "client/simpleClientsList2",
        getLatestData:"client/getLatestData",
        getBarChartDataForClient: "client/getBarChartDataForClient",
        getFaultDetails: "client/getFaultDetails",
        getClientState: "client/getClientState",
        setClientState: "client/setClientState",
        usersForClient: "client/usersForClient",
        getClientName: "client/getClientName",
        getVisitCount: "client/getNoOfVisits",
        getGroupBarChartData: "client/getGroupBarChartData"

    },
    products: {
        productsForUser: "product/productsForUser",
        productsForUser2: "product/productsForUser2",
        productsForFilters: "product/productsForFilters",
        getDataForProduct: "product/getDataForProduct",
        getDataForProduct2: "product/getDataForProduct2",
        getAllProducts: "product/productList",
        saveProduct: "product/saveProduct",
        deleteProduct: "product/deleteProduct",
        getDataForProductAndClient: "product/getDataForProductAndClient",
        getSuggestions: "product/getSuggestions",
        addSuggestion: "product/addSuggestion",
        deleteSuggestion: "product/deleteSuggestion",
    },
    visits: {
        getVisitsForVenue: "visit/getVisitsForVenue",
        getVisitsAwaitingApproval: "visit/getVisitsAwaitingApproval",
        approveVisit: "visit/setVisitState",
        editReport: "visit/editReport",
        editBarResult: "visit/editBarResult",
        editTemperatures: "visit/editTemperatures",
        deleteTemperature: "visit/deleteTemperature",
        addTemperature: "visit/addTemperature",
        editCustIssues: "visit/editCustIssues",
        addCustIssue : "visit/addCustIssue",
        deleteCustIssue : "visit/deleteCustIssue",
        editTechIssues: "visit/editTechIssues",
        addTechIssue : "visit/addTechIssue",
        deleteTechIssue : "visit/deleteTechIssue",
        getImages : "visit/getVisitImages",
        deleteVisitImage : "visit/deleteVisitImage",
        uploadImage : "visit/uploadVisitImage",
        getVideos : "visit/getVisitVideos",
        deleteVisitVideo : "visit/deleteVisitVideo",
        uploadVideo : "visit/uploadVisitVideo",
        locClientInfo : "visit/locClientInfo",
            getVisitPDF: "visit/getVisitPDF"
    }
    ,
    users: {
        getUsers: "user/getUsers",
        getUser: "user/getUser",
        deleteUser: "user/deleteUser",
        editUser: "user/editUser",
        simpleTechniciansList: "user/simpleTechniciansList",
        isSingleClientUser: "user/isSingleClientUser",
        getUserName: "user/getUserName"
    },
    faults: {
        getFaultList: "fault/getFaultList",
        getProductsForFault :  "fault/productsForFault",
        addFix: "fault/addFix",
        deleteFix: "fault/deleteFix"
    },
    export: {
        getCSV: "export/getCSV",
        upload: "export/upload"
    },
    feedback: {
        getFeedback: "feedback/getFeedback",
        getReadFeedback: "feedback/getReadFeedback",
        getFeedbackItem:"feedback/getFeedbackItem",
        sendFeedback: "feedback/sendFeedback",
        markAsRead: "feedback/markAsRead"

    },
    notifications: {
        getRead: "notification/getRead",
        getUnread: "notification/getUnread",
        markAsRead: "notification/markAsRead",
        getmessage: "notification/getMessage",
        getUnreadCount: "notification/getUnreadCount"
    },
    warranty: {
        getParts: "warranty/getMasterPartsList",
        getBrewerParts: "warranty/getBrewerPartsList",
        editPart: "warranty/editPart",
        getBrewers: "warranty/getBrewersList",
        editBrewer: "warranty/editBrewer",
        deleteBrewer: "warranty/deleteBrewer",
        deletePart: "warranty/deletePart",
        removePartFromBrewer: "warranty/removePartFromBrewer",
        assignPartToBrewer: "warranty/assignPartToBrewer",
        brewerReport: "warranty/brewerReport",
        partStockReport: "warranty/partStockReport"
    },
};