import Axios from "axios";
import {API} from "../../api/APIClient"
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";


class Approvals extends React.Component {


    constructor(props) {
        super(props);

        this.state = {tableData: undefined,
            page: 0,
            rowsPerPage: 15,
            editingID: undefined
        };
    }


    componentDidMount() {

        this.getApprovals()

    }



    getApprovals() {
        const formData = new FormData();

        Axios
            .post(API.visits.getVisitsAwaitingApproval, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            visits: []
                        })
                    } else {
                        this.setState({
                                visits: res.data.data

                            },
                            () => this.processTableData())
                    }
                }
            })
    }

    processTableData()
    {
        this.setState({
            tableData: this.state.visits.map((visit, c) => {
                return <TableRow key={c}>
                    <TableCell component="th" scope="row">{visit.date}</TableCell>
                    <TableCell component="th" scope="row">{visit.clientname}</TableCell>
                    <TableCell component="th" scope="row">{visit.locationname}</TableCell>

                    <TableCell>
                        <div onClick={() => this.props.itemClickHandler(visit.id)} className="av-button" style={{padding: "2px 30px"}}>View</div>
                    </TableCell>
                </TableRow>
            })
        })

    }

    render() {

        return(
            <div>

                <div className="cell small-12">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Client</TableCell>
                                    <TableCell>Venue</TableCell>
                                    <TableCell> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.tableData &&
                    <TablePagination
                        rowsPerPageOptions={[15, 30, 100]}
                        component={Paper}
                        count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>

            </div>


        );
    }
}

export default Approvals;