import Axios from "axios";
import {API} from "../../api/APIClient"
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";


class Products extends React.Component {


    constructor(props) {
        super(props);

        this.state = {tableData: undefined,
            page: 0,
            suggestedPage:0,
            rowsPerPage: 15,
            editingID: undefined,
            edit_product_name:'',
            showSuggestions: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        var name = target.name;

        this.setState({
            [name]: value
        }, ()=>this.updateIfNeeded(name))

    }

    updateIfNeeded(name)
    {
        if (name === "search") {
            this.processTableData()
        }
    }

    componentDidMount() {

        this.getProducts()
        this.getSuggestions()
    }

    editProduct(id, name)
    {

        this.setState({editingID: id, edit_product_name: name}, () => this.processTableData())

    }


    deleteProduct(id)
    {

        if (window.confirm("Are you sure you want to delete this product?"))
        {
            const formData = new FormData();
            formData.append('id', id);

            Axios
                .post(API.products.deleteProduct, formData)
                .then((res) => {
                    if (res.data.success) {

                        this.getProducts()

                    } else {
                        var msg = "Error deleting product. "
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error deleting product. "

                if (error.response)
                {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                }else{
                    alert(error)

                }

            })
        }


    }


    addSuggestion(name, id)
    {

        if (window.confirm("Add '"+name+"' to the products list?"))
        {
            const formData = new FormData();
            formData.append('id', id);

            Axios
                .post(API.products.addSuggestion, formData)
                .then((res) => {
                    if (res.data.success) {

                        this.getSuggestions()
                        this.getProducts()

                    } else {
                        var msg = "Error adding product. "
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error adding product. "

                if (error.response)
                {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                }else{
                    alert(error)

                }

            })
        }


    }


    deleteSuggestion(id)
    {


            const formData = new FormData();
            formData.append('id', id);

            Axios
                .post(API.products.deleteSuggestion, formData)
                .then((res) => {
                    if (res.data.success) {

                        this.getSuggestions()

                    } else {
                        var msg = "Error removing suggestion. "
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error removing suggestion. "

                if (error.response)
                {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                }else{
                    alert(error)

                }

            })


    }


    cancelEdit()
    {
        this.setState({editingID: undefined}, () => this.processTableData())
    }

    saveProduct() {


        if (this.state.edit_product_name) {
            const formData = new FormData();
            formData.append('id', this.state.editingID);
            formData.append('name', this.state.edit_product_name);

            Axios
                .post(API.products.saveProduct, formData)
                .then((res) => {

                    if (res.data.success) {

                        var msg = "Product saved successfully"
                        alert(msg)
                        this.setState({edit_product_name:'',
                            editingID: undefined})

                         this.getProducts()

                    } else {
                        var errmsg = "Error saving product, please check the details entered and try again."
                        alert(errmsg)

                    }
                }).catch(error => {

                var msg = "Error saving user. "

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the product name field.")
        }
    }


    getProducts() {
        const formData = new FormData();
        formData.append('usage', 1);

        Axios
            .post(API.products.getAllProducts, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            products: []
                        })
                    } else {
                        this.setState({
                            products: res.data.data

                        }, () => this.processTableData())

                    }
                }
            })
    }


    getSuggestions() {
        const formData = new FormData();

        Axios
            .post(API.products.getSuggestions, formData)
            .then((res) => {

                if (res.data.success) {


                        this.setState({
                            suggestedTableData: res.data.suggestions.map((product, c) => {



                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{product.name}</TableCell>
                                    <TableCell>
                                        <div onClick={() => this.addSuggestion(product.name, product.id)} className="av-button"
                                             style={{padding: "2px 30px"}}>Add
                                        </div>
                                        <div onClick={() => this.deleteSuggestion(product.id)} className="av-button"
                                             style={{padding: "2px 30px"}}>Delete
                                        </div>

                                    </TableCell>
                                </TableRow>
                            })
                        })

                }
            })
    }

    processTableData()
    {
        var matchingProducts = []
        for (var c = 0; c < this.state.products.length; c++) {
            if (! this.state.search || this.state.products[c].name.toLowerCase().includes(this.state.search.toLowerCase()) ){
                matchingProducts.push(this.state.products[c])
            }
        }
        this.setState({
        tableData: matchingProducts.map((product, c) => {



                return <TableRow key={c}>
                    <TableCell component="th" scope="row">{product.name}</TableCell>

                    <TableCell>

                        {this.props.user.admin === "1" &&
                        <div onClick={() => this.editProduct(product.id, product.name)} className="av-button"
                             style={{padding: "2px 30px"}}>Edit
                        </div>
                        }

                    </TableCell>
                </TableRow>
            })
        })

    }

    showHideSuggestions(){
        this.setState({"showSuggestions" : !this.state.showSuggestions})
    }

    render() {

        return(
            <div className="cell small-12 admin-wrap">
                <div className="admin-set-box">
                    <div className="cell small-12 title-box">
                        <div className="title">Product List</div>
                    </div>
                    <div className="cell small-4 search">
                        <input type="text" placeholder="Search"  name="search" value={this.state.search} onChange={this.handleChange}/>
                    </div>

                        {this.props.user.admin === "1" ?

                            <div className="cell small-8">

                            <div className="av-button"  onClick={() => this.editProduct(-1, undefined)}>Add New Product</div><div className="av-button" onClick={() => this.showHideSuggestions()}>
                        {(this.state.showSuggestions) ?
                            "Hide Suggestions"
                            :
                            this.state.suggestedTableData ? this.state.suggestedTableData.length + " New Suggestions" : 0 + " New Suggestions" }
                            </div>
                            </div>

                            :
                            <div className="cell small-8">
                            </div>
                            }


                    <div className="clearfix" />
                </div>
                

                {this.state.showSuggestions &&

                <div className="cell small-12 no-pad">
                    <div className="admin-box">

                    <div className="title">Product Suggestions</div>

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Suggested Name</TableCell>
                                    <TableCell>  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.suggestedTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.suggestedTableData.slice(this.state.page * this.state.rowsPerPage, this.state.suggestedPage * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.suggestedTableData &&
                    <TablePagination
                        rowsPerPageOptions={[15, 30, 100]}
                        component={Paper}
                        count={this.state.suggestedTableData === undefined ? 0 : this.state.suggestedTableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.suggestedPage}
                        onChangePage={(event, newPage) => {

                            this.setState({suggestedPage: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({suggestedPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                    </div>
                </div>
                }

                <div className="cell small-12 no-pad">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product Name</TableCell>
                                    <TableCell>  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.tableData &&
                    <TablePagination
                        rowsPerPageOptions={[15, 30, 100]}
                        component={Paper}
                        count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>


                {(this.state.editingID !== undefined) && <div className="modal">
                    <div className="modal-box main-wrap">
                        {this.state.editingID < 0 ? <div className="call small-12 small-pad alert-title">Enter New Product Name</div> : <div className="call small-12 small-pad alert-title">Edit Product Name</div>}
                        
                        <div className="cell small-12">
                            <input className="edit-field" type="text" name="edit_product_name" placeholder="Product name" value={this.state.edit_product_name} onChange={this.handleChange}/>
                        </div>
                        <div className="cell small-12"/>

                        <div className="av-button" onClick={() => this.saveProduct()}>Save</div>
                        <div className="av-button" onClick={() => this.cancelEdit()}>Cancel</div>
                    </div></div>}

            </div>


                );
    }
}

export default Products;