import React from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Axios from "axios";
import {API} from "../../api/APIClient";
import {Link} from "react-router-dom";
import {ADMIN_SECTIONS, SECTIONS} from "../../resources/strings";

class MiniNotifications extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            page: 0,
            unreadPage:0,
            rowsPerPage: 15
        };


    }


    viewMsg(msgID, markAsRead, additional)
    {
        if (markAsRead) {
            this.markAsRead(msgID)
        }
        window.location = '/'+ SECTIONS.ADMIN + '/' + additional
    }




    componentDidMount()
    {

        this.getUnread();
        this.props.unreadUpdater();
    }


    markAsRead(msg)
    {
        const formData = new FormData();
        formData.append('msgID', msg);

        Axios
            .post(API.notifications.markAsRead, formData)
            .then((res) => {

                this.props.unreadUpdater();

            })
    }

    getMessage(msgID)
    {
        const formData = new FormData();
        formData.append('msgID', msgID);

        Axios
            .post(API.notifications.getmessage, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({

                        messageDetails: res.data.data
                    })
                }
            })
    }

    getUnread()
    {
        const formData = new FormData();

        Axios
            .post(API.notifications.getUnread, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({

                        unreadTableData: res.data.data.map((row, c) => {

                            return <TableRow key={c} >
                                <TableCell component="th" scope="row">{row.sent}</TableCell>
                                <TableCell>{row.message}</TableCell>
                                <TableCell><div className="av-button" style={{padding:"2px 30px"}} onClick={() => this.viewMsg(row.id, true, row.additional)}>View</div></TableCell>
                            </TableRow>


                        })
                    })
                }
            })
    }






    handleItemClick (newScreen)
    {
        this.props.showMenuHandler(false);
        const oldScreen = this.state.currentScreen;
        const navStack = this.state.navStack.concat([oldScreen])
        this.setState({
            currentScreen: newScreen,
            navStack: navStack
        });
    }


    handleBackClick ()
    {
        const navStack = this.state.navStack.slice();

        if (navStack.length > 1)
        {
            this.props.showMenuHandler(false);
        } else {
            this.props.showMenuHandler(true);

        }
        const backScreen = navStack[navStack.length - 1];

        this.setState({
            currentScreen: backScreen,
            navStack: navStack.slice(0,navStack.length - 1)
        });
    }

    render(){



        return(

                

                <div className="cell small-12 admin-wrap">

                <div className="admin-box">

                    <div className="title">Notifications</div>

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>View</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.unreadTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.unreadTableData.slice(0, 3)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    <div className="cell small-12" style={{textAlign: "center"}}><Link to={`/` + SECTIONS.NOTIFICATIONS}><div className="av-button">View More</div></Link></div>

                    <div className="clearfix" />
                
                </div>

                </div>

                

        )


    }


}

export default MiniNotifications;
