import React from 'react'
import Axios from "axios";
import {API} from "../../api/APIClient";
import DatePicker from  "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import Geocode from "react-geocode";
import {DebounceInput} from 'react-debounce-input';



class OverviewFilter2 extends React.Component {

    venuesRequestSource = undefined;
    productsRequestSource = undefined;

    constructor(props) {
        super(props);

        var filters = {
            date: {
                isOn: false,
                value: this.getDatetimeDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1))),
                rawValue: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toString()
            },
            dateTo: {
                isOn: false,
                value: this.getDatetimeDate(new Date()),
                rawValue: new Date().toString()
            },
            clients: {
                isOn: !!props.clientIDS,
                isRestrict: true,
                isExclude: false,
                list: props.clientIDS || []
            },
            distance: {
                isOn: false,
                value: "5"
            },
            postcode: {
                isOn: false,
                value: null,
                valueRaw: null
            },
            products: {
                isOn: false,
                isRestrict: true,
                isExclude: false,
                list: []
            },
            venues: {
                isOn: !!props.venueIDS,
                isRestrict: true,
                isExclude: false,
                list: props.venueIDS || []
            },
            bars: {
                isOn: false,
                isRestrict: true,
                isExclude: false,
                list:  []
            },
            faults: {
                isOn: false,
                value: true
            },
            technicians: {
                isOn: false,
                isRestrict: true,
                isExclude: false,
                list: []
            }
        }

        if (this.props.filters) {
            filters = this.props.filters
        }

        var hideFaults = false
        if (this.props.hideFaults) {
            hideFaults = this.props.hideFaults
        }


        this.state = {
            expanded: false,
            clients: [],
            venues: [],
            bars: [],
            products: [],
            technicians: [],
            client: null,
            venue: null,
            hideFaults: hideFaults,
            product: null,
            technician: null,
            isLoadingClients: false,
            isLoadingVenues: false,
            isLoadingBars: false,
            isLoadingProducts: false,
            isLoadingTechnicians: false,
            filters: filters,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    getDatetimeDate(date) {

        let month = '0' +(date.getMonth()+1)
        let day = '0' + date.getDate()
        return date.getFullYear() + "-" + month.slice(-2) + "-"  + day.slice(-2) + " 00:00:01"
    }


    handleUpdateResults = () => {
        if (this.state.filters.postcode.isOn && this.state.filters.postcode.value == null) {
            alert("Unable to find " + this.state.filters.postcode.valueRaw + ". Please enter another Postcode / Town and try again.")
        } else {
            this.setState({
                expanded: false
            }, () => {
                if (typeof this.props.onChange === 'function') {
                    this.props.onChange(this.state.filters);
                }
            })
        }


    }

    handleSelectChange(event, data) {
        const target = event.target;
        const name = target.name;
        const index = target.value;

        if (index && data && index < data.length) {
            let newState = {};
            newState[name] = data[index];

            this.setState(newState);
        }
    }

    handleChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.updateFilter(name, value);
    }

    updateFilter = (name, value) => {
        const id = name.split(".");
        if (id.length !== 2) { return; }
        const key = id[0];
        const property = id[1];

        let filters = Object.assign({}, this.state.filters);

        if (key === "date") {
            if (property === "isOn") {
                filters[key][property] = value;
                filters.dateTo.isOn = value;
                if (!value) {
                    filters.dateTo.value = null;
                    filters.dateTo.rawValue = null;
                }
            }
            else if (property === "value") {
                filters[key][property] = value ? this.getDatetimeDate(value) : value;
                filters[key]["rawValue"] = value ? value.toString() : value;
            }
        }
        else if (key === "dateTo") {
            if (property === "value") {
                filters[key][property] = value ? this.getDatetimeDate(value) : value;
                filters[key]["rawValue"] = value ? value.toString() : value;
            }
        }
        else if (key === "distance") {
            filters[key][property] = value;
            if (property === "isOn") {
                filters.postcode.isOn = value;
                if (!value) {
                    filters.postcode.value = null;
                }
            }
        }
        else if (key === "clients" || key === "products" || key === "venues" || key === "bars" || key === "technicians") {
            filters[key][property] = value;
            if (property === "isOn" && !value) {
                filters[key].isRestrict = true;
                filters[key].isExclude = false;
                filters[key].list = [];
            }
            else if (property === "isRestrict") {
                filters[key].isExclude = !value;
            }
            else if (property === "isExclude") {
                filters[key].isRestrict = !value;
            }
        }
        else {
            filters[key][property] = value;
        }

        if (key === "postcode"  && property === "valueRaw"  && value && value.length < 4) {
            filters.postcode.value = null
        }

        this.setState({
            filters: filters
        }, () => {

            if (key === "postcode" && property === "valueRaw" && value && value.length >= 4) {
                console.log("psotcdeo "+ value)
                Geocode.setApiKey("AIzaSyASR6vqBuN-lvHwVF2oy3o8JutLDGk0hfI");
                Geocode.setLanguage("en");
                Geocode.setRegion("uk");
                Geocode.setLocationType("APPROXIMATE");
                Geocode.fromAddress(value).then(
                    response => {
                        const { lat, lng } = response.results[0].geometry.location;
                        console.log("psotcdeo "+lat + " " + lng)
                        if (this.state.filters.postcode.valueRaw === value && this.state.filters.postcode.isOn) {
                            let filters = Object.assign({}, this.state.filters);

                            filters.postcode.value = lat+","+lng
                            this.setState({
                                filters: filters
                            })
                        } else {
                            let filters = Object.assign({}, this.state.filters);
                            filters.postcode.value = null
                            this.setState({
                                filters: filters
                            })
                        }
                    },
                    error => {
                        let filters = Object.assign({}, this.state.filters);
                        filters.postcode.value = null
                        this.setState({
                            filters: filters
                        })
                        console.log("postcode" + error);
                    }
                );
            }
            if (key === "clients") {
                this.handleClientsListUpdated();
            }
            if (key === "venues") {
                this.handleVenueListUdpated();
            }
            if (key === "bars") {
                this.handleBarListUdpated();
            }
        })
    }

    updateList(name, obj, remove)
    {
        if (!obj) { return; }
        const id = name.split(".");
        if (id.length !== 2) { return; }
        const key = id[0];
        const property = id[1];
        let filters = Object.assign({}, this.state.filters);


        if (remove) {
            filters[key][property] = filters[key][property].filter((o) => {
                return o.id !== obj.id;
            });
        }
        else {
            let items = filters[key][property];
            if (filters[key][property].findIndex((o) => o.id === obj.id) === -1) {
                items.push(obj);
                filters[key][property] = items;
            }
        }

        this.setState({
            filters: filters
        }, () => {
            if (name === "clients.list") {
                this.handleClientsListUpdated();
            }
            else if (name === "venues.list") {
                this.handleVenueListUdpated();
            }else if (name === "bars.list") {
                this.handleBarListUdpated();
            }
        })
    }

    handleClientsListUpdated = () => {
        let filters = Object.assign({}, this.state.filters);
        filters.venues.list = [];
        filters.products.list = [];
        this.setState({
            venues: [],
            venue: null,
            products: [],
            product: [],
            filters: filters
        }, () => {
            this.getVenues();
            this.getProducts();
        })
    }

    handleBarListUdpated = () => {
        let filters = Object.assign({}, this.state.filters);
        filters.products.list = [];
        this.setState({
            products: [],
            product: [],
            filters: filters
        }, () => {
            this.getProducts();
        })
    }

    handleVenueListUdpated = () => {
        let filters = Object.assign({}, this.state.filters);
        filters.products.list = [];
        this.setState({
            products: [],
            product: [],
            filters: filters
        }, () => {
            this.getProducts();
        })
    }

    componentDidMount() {

        if (! this.props.hideClients){
            this.getClients();
        }
        if (! this.props.hideVenues) {
            this.getVenues();
        }
        if (this.props.showBars) {
            this.getBars();
        }
        this.getProducts();

        if (! this.props.hideTechnicians) {

            this.getTechnicians();
        }
    }

    getClients() {
        if (this.state.isLoadingClients) {
            return;
        }

        this.setState({
            isLoadingClients: true
        }, () => {

            const formData = new FormData();
            formData.append('userID', this.props.user.id);

            Axios
                .post(API.clients.simpleClientsList, formData)
                .then((res) => {

                    if (res.data.success) {
                        this.setState({
                            isLoadingClients: false,
                            clients: res.data.data,
                            client: res.data.data.length > 0 ? res.data.data[0] : null
                        })
                    }
                    else {
                        this.setState({
                            isLoadingClients: false,
                            clients: [],
                            client: null
                        })
                    }
                })

        })
    }

    getVenues() {

        if (this.venuesRequestSource) {
            this.venuesRequestSource.cancel("getVenues");
        }

        this.setState({
            isLoadingVenues: true
        }, () => {

            const formData = new FormData();
            formData.append('userID', this.props.user.id);

            if (this.state.filters.clients.isRestrict) {
                this.state.filters.clients.list.forEach((o) => {
                    formData.append("inClientIDS[]", o.id)
                });
            }
            else {
                this.state.filters.clients.list.forEach((o) => {
                    formData.append("notInClientIDS[]", o.id)
                });
            }

            const cancelToken = Axios.CancelToken;
            this.venuesRequestSource = cancelToken.source();

            Axios
                .post(API.locations.simpleLocationsList, formData, {
                    cancelToken: this.venuesRequestSource.token
                })
                .catch(function(thrown) {
                    if (Axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    }
                })
                .then((res) => {
                    this.venuesRequestSource = undefined;
                    if (!res) { return; } // For some reason if request is cancelled it still returns a promise but with an empty result
                    if (res.data.success) {
                        this.setState({
                            isLoadingVenues: false,
                            venues: res.data.data,
                            venue: res.data.data.length > 0 ? res.data.data[0] : null
                        })
                    }
                    else {
                        this.setState({
                            isLoadingVenues: false,
                            venues: [],
                            venue: null
                        })
                    }
                })

        })
    }



    getBars() {
        this.setState({
            isLoadingBars: true
        }, () => {

            const formData = new FormData();
            let venu = this.props.venueIDS[0]
            formData.append("venueID", venu.id)

            Axios
                .post(API.locations.getBars, formData, {})
                .catch(function(thrown) {
                    if (Axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    }
                })
                .then((res) => {
                    if (res.data.success) {
                        this.setState({
                            isLoadingBars: false,
                            bars: res.data.data,
                            bar: res.data.data.length > 0 ? res.data.data[0] : null
                        })
                    }
                    else {
                        this.setState({
                            isLoadingBars: false,
                            bars: [],
                            bar: null
                        })
                    }
                })

        })
    }


    getProducts() {

        if (this.productsRequestSource) {
            this.productsRequestSource.cancel("getProducts");
        }

        this.setState({
            isLoadingProducts: true
        }, () => {

            const formData = new FormData();
            formData.append('userID', this.props.user.id);

            if (this.state.filters.clients.isRestrict) {
                this.state.filters.clients.list.forEach((o) => {
                    formData.append("inClientIDS[]", o.id)
                });
            }
            else {
                this.state.filters.clients.list.forEach((o) => {
                    formData.append("notInClientIDS[]", o.id)
                });
            }

            if (this.state.filters.clients.isRestrict) {
                this.state.filters.venues.list.forEach((o) => {
                    formData.append("inVenueIDS[]", o.id)
                });
            }
            else {
                this.state.filters.venues.list.forEach((o) => {
                    formData.append("notInVenueIDS[]", o.id)
                });
            }

            if (this.state.filters.bars.isRestrict) {
                this.state.filters.bars.list.forEach((o) => {
                    formData.append("inBarIDS[]", o.id)
                });
            }
            else {
                this.state.filters.bars.list.forEach((o) => {
                    formData.append("notInBarIDS[]", o.id)
                });
            }

            const cancelToken = Axios.CancelToken;
            this.productsRequestSource = cancelToken.source();

            Axios
                .post(API.products.productsForFilters, formData, {
                    cancelToken: this.productsRequestSource.token
                })
                .catch(function(thrown) {
                    if (Axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    }
                })
                .then((res) => {
                    this.productsRequestSource = undefined;
                    if (!res) { return; } // For some reason if request is cancelled it still returns a promise but with an empty result
                    if (res.data.success) {
                        this.setState({
                            isLoadingProducts: false,
                            products: res.data.data,
                            product: res.data.data.length > 0 ? res.data.data[0] : null
                        })
                    }
                    else {
                        this.setState({
                            isLoadingProducts: false,
                            products: [],
                            product: null
                        })
                    }
                })
        })

    }

    getTechnicians() {
        if (this.state.isLoadingTechnicians) {
            return;
        }

        this.setState({
            isLoadingTechnicians: true
        }, () => {
            const formData = new FormData();
            formData.append('userID', this.props.user.id);

            Axios
                .post(API.users.simpleTechniciansList, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({
                            isLoadingTechnicians: false,
                            technicians: res.data.data,
                            technician: res.data.data.length > 0 ? res.data.data[0] : null
                        })
                    }
                    else {
                        this.setState({
                            isLoadingTechnicians: false,
                            technicians: [],
                            technician: null
                        })
                    }
                })
        })
    }

    expandOrContract()
    {
        this.setState({expanded : !this.state.expanded});
    }

    render() {

        const fromDate = this.state.filters.date.value ? Date.parse(this.state.filters.date.rawValue) : null;
        const toDate = this.state.filters.date.value ? Date.parse(this.state.filters.dateTo.rawValue) : null;

        let numberOfFilters = 0;
        numberOfFilters += (this.state.filters.date.isOn && this.state.filters.dateTo.isOn) ? 1 : 0;
        numberOfFilters += (this.state.filters.distance.isOn && this.state.filters.postcode.isOn) ? 1: 0;
        numberOfFilters += (this.state.filters.clients.isOn && !this.props.hideClients) ? 1 : 0;
        numberOfFilters += (this.state.filters.venues.isOn && !this.props.hideVenues) ? 1: 0;
        numberOfFilters += (this.state.filters.bars.isOn && this.props.showBars) ? 1: 0;
        numberOfFilters += (this.state.filters.products.isOn) ? 1: 0;
        numberOfFilters += (this.state.filters.technicians.isOn  && !this.props.hideTechnicians) ? 1: 0;
        numberOfFilters += (this.state.filters.faults.isOn) ? 1: 0;

        return(<div className="cell small-12 container filter-wrap">

            <div className="filters">
                <div className="white-arrow" onClick={() => this.expandOrContract()}>{this.state.expanded ? <span className='innerarrow down'></span> : <span className='innerarrow'></span>}</div>

                <div className="ddtitle" onClick={() => this.expandOrContract()}>Filters {numberOfFilters > 0 && <span className="filters-badge">{numberOfFilters}</span>} </div>

                {this.state.expanded &&
                <div style={{textAlign: "left"}}>


                    <div className="filterbox">

                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Date Range</b>
                                <input
                                    type="checkbox"
                                    name="date.isOn"
                                    checked={this.state.filters.date.isOn}
                                    onClick={this.handleChange}
                                />
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.state.filters.date.isOn &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className="edit-field"
                                    selected={fromDate}
                                    onChange={(date) => this.updateFilter("date.value", date)}
                                />
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">to</label>
                            </div>
                            <div className="cell small-2 no-pad">
                                <DatePicker
                                    className="edit-field"
                                    dateFormat="dd/MM/yyyy"
                                    selected={toDate}
                                    onChange={(date) => this.updateFilter("dateTo.value", date)}
                                />
                            </div>
                        </React.Fragment>
                        }
                        <div className="cell small-7 no-pad"></div>

                    </div>


                    {!this.props.hideClients &&

                    <div className="filterbox">


                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Clients</b>
                                <input  type="checkbox" name="clients.isOn" checked={this.state.filters.clients.isOn} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.state.filters.clients.isOn &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <label className="filter-title">
                                    Restrict to
                                    <input
                                        type="checkbox"
                                        name="clients.isRestrict"
                                        checked={this.state.filters.clients.isRestrict}
                                        onClick={this.handleChange}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">or</label>
                            </div>
                            <div className="cell small-9 no-pad">
                                <label className="filter-title">Exclude
                                    <input
                                        type="checkbox"
                                        name="clients.isExclude"
                                        checked={this.state.filters.clients.isExclude}
                                        onClick={this.handleChange}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-6 no-pad">
                                <select
                                    className="edit-field"
                                    name="client"
                                    onChange={(event) => this.handleSelectChange(event, this.state.clients)}
                                    disabled={this.state.isLoadingClients}
                                >
                                    {this.state.clients.map((client, c) => {
                                        return <option value={c}>{client.clientname}</option>
                                    })}
                                </select>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="av-button" style={{fontSize: "1.1em"}} onClick={() => this.updateList("clients.list", this.state.client, false)}>
                                    Add
                                </div>
                            </div>
                            {this.state.filters.clients.list.length > 0 ?
                                <div className="cell small-12 small-pad filteritems">
                                    {this.state.filters.clients.list.map((client, c) => {
                                        return <span value={client.id}>{client.clientname} <span className="filterx" onClick={() => this.updateList("clients.list", client, true)}>X</span></span>
                                    })}
                                </div>
                                :
                                <div>Select an item from above and tap Add.</div>
                            }
                        </React.Fragment>
                        }
                    </div>
                    }


                    {!this.props.hideVenues &&

                    <div className="filterbox">


                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Venues</b>
                                <input  type="checkbox" name="venues.isOn" checked={this.state.filters.venues.isOn} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.state.filters.venues.isOn &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <label className="filter-title">Restrict to
                                    <input  type="checkbox" name="venues.isRestrict" checked={this.state.filters.venues.isRestrict} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">or</label>
                            </div>
                            <div className="cell small-9 no-pad">
                                <label className="filter-title">Exclude
                                    <input  type="checkbox" name="venues.isExclude" checked={this.state.filters.venues.isExclude} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-6 no-pad">
                                <select
                                    className="edit-field"
                                    name="venue"
                                    onChange={(event) => this.handleSelectChange(event, this.state.venues)}
                                    disabled={this.state.isLoadingVenues}
                                >
                                    {this.state.venues.map((venue, c) => {
                                        return <option value={c}>{venue.locationname}</option>

                                    })}
                                </select>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="av-button" style={{fontSize: "1.1em"}} onClick={() => this.updateList("venues.list", this.state.venue, false)}>
                                    Add
                                </div>
                            </div>
                            {this.state.filters.venues.list.length > 0 ?
                                <div className="cell small-12 small-pad filteritems">
                                    {this.state.filters.venues.list.map((venue, c) => {
                                        return <span value={venue.id}>{venue.locationname} <span className="filterx" onClick={() => this.updateList("venues.list", venue, true)}>X</span></span>
                                    })}
                                </div>
                                :
                                <div>Select an item from above and tap Add.</div>
                            }
                        </React.Fragment>
                        }
                    </div>


                    }


                    {this.props.showBars &&

                    <div className="filterbox">


                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Bars</b>
                                <input  type="checkbox" name="bars.isOn" checked={this.state.filters.bars.isOn} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.state.filters.bars.isOn &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <label className="filter-title">Restrict to
                                    <input  type="checkbox" name="bars.isRestrict" checked={this.state.filters.bars.isRestrict} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">or</label>
                            </div>
                            <div className="cell small-9 no-pad">
                                <label className="filter-title">Exclude
                                    <input  type="checkbox" name="bars.isExclude" checked={this.state.filters.bars.isExclude} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-6 no-pad">
                                <select
                                    className="edit-field"
                                    name="bar"
                                    onChange={(event) => this.handleSelectChange(event, this.state.bars)}
                                    disabled={this.state.isLoadingBars}
                                >
                                    {this.state.bars.map((bar, c) => {
                                        return <option value={c}>{bar.barname}</option>

                                    })}
                                </select>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="av-button" style={{fontSize: "1.1em"}} onClick={() => this.updateList("bars.list", this.state.bar, false)}>
                                    Add
                                </div>
                            </div>
                            {this.state.filters.bars.list.length > 0 ?
                                <div className="cell small-12 small-pad filteritems">
                                    {this.state.filters.bars.list.map((bar, c) => {
                                        return <span value={bar.id}>{bar.barname} <span className="filterx" onClick={() => this.updateList("bars.list", bar, true)}>X</span></span>
                                    })}
                                </div>
                                :
                                <div>Select an item from above and tap Add.</div>
                            }
                        </React.Fragment>
                        }
                    </div>


                    }

                    { (!this.props.hideProducts && this.state.products) &&

                    <div className="filterbox">

                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Product Lines</b>
                                <input  type="checkbox" name="products.isOn" checked={this.state.filters.products.isOn} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.state.filters.products.isOn &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <label className="filter-title">Restrict to
                                    <input  type="checkbox" name="products.isRestrict" checked={this.state.filters.products.isRestrict} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">or</label>
                            </div>
                            <div className="cell small-9 no-pad">
                                <label className="filter-title">Exclude
                                    <input  type="checkbox" name="products.isExclude" checked={this.state.filters.products.isExclude} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-6 no-pad">
                                <select
                                    className="edit-field"
                                    name="product"
                                    onChange={(event) => this.handleSelectChange(event, this.state.products)}
                                    disabled={this.state.isLoadingProducts}
                                >
                                    {this.state.products.map((product, c) => {
                                        return <option value={c}>{product.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="av-button" style={{fontSize: "1.1em"}} onClick={() => this.updateList("products.list", this.state.product, false)}>
                                    Add
                                </div>
                            </div>
                            {this.state.filters.products.list.length > 0 ?
                                <div className="cell small-12 small-pad filteritems">
                                    {this.state.filters.products.list.map((product, c) => {
                                        return <span value={product.id}>{product.name} <span className="filterx" onClick={() => this.updateList("products.list", product, true)}>X</span></span>
                                    })}
                                </div>
                                :
                                <div>Select an item from above and tap Add.</div>
                            }
                        </React.Fragment>
                        }
                    </div>


                    }


                    {(!this.state.hideFaults) &&

                    <div className="filterbox">
                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Technical Faults</b>
                                <input  type="checkbox" name="faults.isOn" checked={this.state.filters.faults.isOn} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>
                            {this.state.filters.faults.isOn &&
                            <div className="cell small-12 no-pad">
                                <label className="filter-title">Will only show venues with technical faults</label>

                            </div>
                            }
                        </div>
                    </div>
                    }

                    {(this.state.technicians && this.props.user.admin === "1" && !this.props.hideTechnicians) &&

                    <div className="filterbox">


                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Technicians</b>
                                <input  type="checkbox" name="technicians.isOn" checked={this.state.filters.technicians.isOn} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.state.filters.technicians.isOn &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <label className="filter-title">Restrict to
                                    <input  type="checkbox" name="technicians.isRestrict" checked={this.state.filters.technicians.isRestrict} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>

                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">or</label>
                            </div>
                            <div className="cell small-9 no-pad">
                                <label className="filter-title">Exclude
                                    <input  type="checkbox" name="technicians.isExclude" checked={this.state.filters.technicians.isExclude} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-6 no-pad">
                                <select
                                    className="edit-field"
                                    name="technician"
                                    onChange={(event) => this.handleSelectChange(event, this.state.technicians)}
                                    disabled={this.state.isLoadingTechnicians}
                                >
                                    {this.state.technicians.map((technician, c) => {
                                        return <option value={c}>{technician.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="av-button" style={{fontSize: "1.1em"}} onClick={() => this.updateList("technicians.list", this.state.technician, false)}>
                                    Add
                                </div>
                            </div>
                            {this.state.filters.technicians.list.length > 0 ?
                                <div className="cell small-12 small-pad filteritems">
                                    {this.state.filters.technicians.list.map((technician, c) => {
                                        return <span value={technician.id}>{technician.name} <span className="filterx" onClick={() => this.updateList("technicians.list", technician, true)}>X</span></span>
                                    })}
                                </div>
                                :
                                <div>Select an item from above and tap Add.</div>
                            }
                        </React.Fragment>
                        }
                    </div>
                    }

                    {!this.props.hideLocation &&
                    <div className="filterbox">
                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Location</b>
                                <input  type="checkbox" name="distance.isOn" checked={this.state.filters.distance.isOn} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        {this.state.filters.distance.isOn &&
                        <React.Fragment>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">Within</label>
                            </div>
                            <div className="cell small-3 no-pad">
                                <select className="edit-field" value={this.state.filters.distance.value} name="distance.value" onChange={this.handleChange}>
                                    <option value="5">5 miles</option>
                                    <option value="10">10 miles</option>
                                    <option value="25">25 miles</option>
                                    <option value="50">50 miles</option>
                                    <option value="100">100 miles</option>
                                </select>
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">of</label>
                            </div>
                            <div className="cell small-3 no-pad">
                                <DebounceInput className="edit-field" type="text" name="postcode.valueRaw"  placeholder="Postcode / Town"  value={this.state.filters.postcode.valueRaw} debounceTimeout={666}  onChange={this.handleChange} />
                            </div>
                            <div className="cell small-4 no-pad"></div>
                        </React.Fragment>
                        }
                    </div>
                    }

                    <div className="filterbox">
                        <div className="av-button" style={{fontSize: "1.1em"}} onClick={this.handleUpdateResults}>
                            Update Results
                        </div>
                    </div>
                </div>
                }


            </div>

        </div>);
    }

}


export default OverviewFilter2;