import React from 'react'
import {FILTERS} from '../../resources/strings'
import Axios from "axios";
import {API} from "../../api/APIClient";
import DatePicker from  "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import Geocode from "react-geocode";



class OverviewFilter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            client: 0,
            venue: 0,
            product: 0,
            technician: 0,
            fromDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
            toDate: new Date()
        };
        this.handleChange = this.handleChange.bind(this);
    }



    handleChange(event) {

        const target = event.target;
        const name = target.name;

        if (name === FILTERS.CLIENTS+FILTERS.IS_ON) {
            this.props.updateFilters(FILTERS.CLIENTS,FILTERS.IS_ON, target.checked )
        } else if (name === FILTERS.CLIENTS+FILTERS.RESTRICT) {
            this.props.updateFilters(FILTERS.CLIENTS,FILTERS.RESTRICT, target.checked )
        }else if (name === FILTERS.CLIENTS+FILTERS.EXCLUDE) {
            this.props.updateFilters(FILTERS.CLIENTS,FILTERS.EXCLUDE, target.checked )
        } else if (name === FILTERS.VENUES+FILTERS.IS_ON) {
            this.props.updateFilters(FILTERS.VENUES,FILTERS.IS_ON, target.checked )
        } else if (name === FILTERS.VENUES+FILTERS.RESTRICT) {
            this.props.updateFilters(FILTERS.VENUES,FILTERS.RESTRICT, target.checked )
        }else if (name === FILTERS.VENUES+FILTERS.EXCLUDE) {
            this.props.updateFilters(FILTERS.VENUES,FILTERS.EXCLUDE, target.checked )
        }


        else if (name === FILTERS.PRODUCTS+FILTERS.IS_ON) {
            this.props.updateFilters(FILTERS.PRODUCTS,FILTERS.IS_ON, target.checked )
        } else if (name === FILTERS.PRODUCTS+FILTERS.RESTRICT) {
            this.props.updateFilters(FILTERS.PRODUCTS,FILTERS.RESTRICT, target.checked )
        }else if (name === FILTERS.PRODUCTS+FILTERS.EXCLUDE) {
            this.props.updateFilters(FILTERS.PRODUCTS,FILTERS.EXCLUDE, target.checked )
        }


        else if (name === FILTERS.TECHNICIANS+FILTERS.IS_ON) {
            this.props.updateFilters(FILTERS.TECHNICIANS,FILTERS.IS_ON, target.checked )
        } else if (name === FILTERS.TECHNICIANS+FILTERS.RESTRICT) {
            this.props.updateFilters(FILTERS.TECHNICIANS,FILTERS.RESTRICT, target.checked )
        }else if (name === FILTERS.TECHNICIANS+FILTERS.EXCLUDE) {
            this.props.updateFilters(FILTERS.TECHNICIANS,FILTERS.EXCLUDE, target.checked )
        }


        else if (name === FILTERS.DISTANCE+FILTERS.IS_ON) {
            this.props.updateFilters(FILTERS.DISTANCE,FILTERS.IS_ON, target.checked )
        } else if (name === FILTERS.DISTANCE+FILTERS.VALUE) {
            this.props.updateFilters(FILTERS.DISTANCE,FILTERS.VALUE, target.value )
        }

        else if (name === FILTERS.POSTCODE+FILTERS.VALUE) {
            this.setState({
                [name]: target.value
            });
            if (target.value.length > 4){

                //geocode!

                Geocode.setApiKey("AIzaSyASR6vqBuN-lvHwVF2oy3o8JutLDGk0hfI");

                Geocode.setLanguage("en");

                Geocode.setRegion("uk");

                Geocode.fromAddress(target.value).then(
                    response => {
                        alert("resp")
                        const { lat, lng } = response.results[0].geometry.location;
                        this.props.updateFilters(FILTERS.POSTCODE,FILTERS.VALUE, lat+","+lng )
                        console.log(lat, lng);
                    },
                    error => {
                        alert("error")

                        console.error(error);
                        this.props.updateFilters(FILTERS.POSTCODE,FILTERS.VALUE, 0+","+0 )

                    }
                );

            }

        }


        else if (name === FILTERS.DATE+FILTERS.IS_ON) {
            this.props.updateFilters(FILTERS.DATE,FILTERS.IS_ON, target.checked )

        }else {

            this.setState({
                [name]: target.value
            });
        }

    }

    addToList(type, index)
    {
        this.props.updateFilters(type,FILTERS.ADD, this.state[type][index] )
    }

    removeFromList(type, obj)
    {
        this.props.updateFilters(type,FILTERS.REMOVE, obj)
    }


    componentDidMount() {

        if (! this.props.hideClients){
            this.getClients()
        }
        if (! this.props.hideVenues) {
            this.getVenues()
        }
        this.getProducts()
        this.getTechnicians()
    }

    getClients() {
        const formData = new FormData();
        formData.append('userID', this.props.userID);

        Axios
            .post(API.clients.simpleClientsList, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        clients: res.data.data
                    })
                }
            })
    }

    getVenues() {
        const formData = new FormData();
        formData.append('userID', this.props.userID);

        Axios
            .post(API.locations.simpleLocationsList, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        venues: res.data.data
                    })
                }
            })
    }


    getProducts() {
        const formData = new FormData();
        formData.append('userID', this.props.userID);

        Axios
            .post(API.products.productsForUser, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        products: res.data.data
                    })
                }
            })
    }

    getTechnicians() {
        const formData = new FormData();
        formData.append('userID', this.props.userID);

        Axios
            .post(API.users.simpleTechniciansList, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        technicians: res.data.data
                    })
                }
            })
    }

    expandOrContract()
    {
        this.setState({expanded : !this.state.expanded});

    }

    fromDateChanged(date)
    {
        this.setState({fromDate: date})
        this.props.updateFilters(FILTERS.DATE,FILTERS.VALUE, date.toString().substring(0, 10) )

    }


    toDateChanged(date)
    {
        this.setState({toDate: date})
        this.props.updateFilters(FILTERS.DATETO,FILTERS.VALUE, date.toString().substring(0, 10) )

    }


    render() {

        return(<div className="cell small-12 container filter-wrap">

            <div className="filters">
                <div className="white-arrow" onClick={() => this.expandOrContract()}>{this.state.expanded ? <span className='innerarrow down'></span> : <span className='innerarrow'></span>}</div>

                <div className="ddtitle" onClick={() => this.expandOrContract()}>Filters</div>

                {this.state.expanded &&
                <div style={{textAlign: "left"}}>


                    <div className="filterbox">

                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Date Range</b>
                                <input
                                    type="checkbox"
                                    name={FILTERS.DATE+FILTERS.IS_ON}
                                    checked={this.props.filters[FILTERS.DATE][FILTERS.IS_ON]}
                                    onClick={this.handleChange}
                                />
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.props.filters[FILTERS.DATE][FILTERS.IS_ON] &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className="edit-field"
                                    selected={this.state.fromDate}
                                    onChange={(date)=>this.fromDateChanged(date)}
                                />
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">to</label>
                            </div>
                            <div className="cell small-2 no-pad">
                                <DatePicker
                                    className="edit-field"
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.toDate}
                                    onChange={(date)=>this.toDateChanged(date)}
                                />
                            </div>
                        </React.Fragment>
                        }
                        <div className="cell small-7 no-pad"></div>

                    </div>


                    {this.state.clients &&

                    <div className="filterbox">


                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Clients</b>
                                <input  type="checkbox" name={FILTERS.CLIENTS+FILTERS.IS_ON} checked={this.props.filters[FILTERS.CLIENTS][FILTERS.IS_ON]} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.props.filters[FILTERS.CLIENTS][FILTERS.IS_ON] &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <label className="filter-title">
                                    Restrict to
                                    <input
                                        type="checkbox"
                                        name={FILTERS.CLIENTS+FILTERS.RESTRICT}
                                        checked={this.props.filters[FILTERS.CLIENTS][FILTERS.RESTRICT]}
                                        onClick={this.handleChange}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">or</label>
                            </div>
                            <div className="cell small-9 no-pad">
                                <label className="filter-title">Exclude
                                    <input
                                        type="checkbox"
                                        name={FILTERS.CLIENTS+FILTERS.EXCLUDE}
                                        checked={this.props.filters[FILTERS.CLIENTS][FILTERS.EXCLUDE]}
                                        onClick={this.handleChange}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-6 no-pad">
                                <select className="edit-field" value={this.state.client} name="client" onChange={this.handleChange}>
                                    {this.state.clients.map((client, c) => {
                                        return <option value={c}>{client.clientname}</option>
                                    })}
                                </select>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="av-button" style={{fontSize: "1.1em"}} onClick={() => this.addToList(FILTERS.CLIENTS, this.state.client)}>
                                    Add
                                </div>
                            </div>
                            {this.props.filters[FILTERS.CLIENTS][FILTERS.LIST].length > 0 ?
                                <div className="cell small-12 small-pad filteritems">
                                    {this.props.filters[FILTERS.CLIENTS][FILTERS.LIST].map((client, c) => {
                                        return <span value={client.id}>{client.clientname} <span className="filterx" onClick={() => this.removeFromList(FILTERS.CLIENTS, client)}>X</span></span>
                                    })}
                                </div>
                                :
                                <div>Select an item from above and tap Add.</div>
                            }
                        </React.Fragment>
                        }
                    </div>
                    }


                    {this.state.venues &&

                    <div className="filterbox">


                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Venues</b>
                                <input  type="checkbox" name={FILTERS.VENUES+FILTERS.IS_ON} checked={this.props.filters[FILTERS.VENUES][FILTERS.IS_ON]} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.props.filters[FILTERS.VENUES][FILTERS.IS_ON] &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <label className="filter-title">Restrict to
                                    <input  type="checkbox" name={FILTERS.VENUES+FILTERS.RESTRICT} checked={this.props.filters[FILTERS.VENUES][FILTERS.RESTRICT]} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">or</label>
                            </div>
                            <div className="cell small-9 no-pad">
                                <label className="filter-title">Exclude
                                    <input  type="checkbox" name={FILTERS.VENUES+FILTERS.EXCLUDE} checked={this.props.filters[FILTERS.VENUES][FILTERS.EXCLUDE]} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-6 no-pad">
                                <select className="edit-field" value={this.state.venue} name="venue" onChange={this.handleChange}>
                                    {this.state.venues.map((venue, c) => {
                                        return <option value={c}>{venue.locationname}</option>

                                    })}
                                </select>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="av-button" style={{fontSize: "1.1em"}} onClick={() => this.addToList(FILTERS.VENUES, this.state.venue)}>
                                    Add
                                </div>
                            </div>
                            {this.props.filters[FILTERS.VENUES][FILTERS.LIST].length > 0 ?
                                <div className="cell small-12 small-pad filteritems">
                                    {this.props.filters[FILTERS.VENUES][FILTERS.LIST].map((venue, c) => {
                                        return <span value={venue.id}>{venue.locationname} <span className="filterx" onClick={() => this.removeFromList(FILTERS.VENUES, venue)}>X</span></span>
                                    })}
                                </div>
                                :
                                <div>Select an item from above and tap Add.</div>
                            }
                        </React.Fragment>
                        }
                    </div>


                    }


                    {this.state.products &&

                    <div className="filterbox">


                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Product Lines</b>
                                <input  type="checkbox" name={FILTERS.PRODUCTS+FILTERS.IS_ON} checked={this.props.filters[FILTERS.PRODUCTS][FILTERS.IS_ON]} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.props.filters[FILTERS.PRODUCTS][FILTERS.IS_ON] &&
                        <React.Fragment>
                            <div className="cell small-2 no-pad">
                                <label className="filter-title">Restrict to
                                    <input  type="checkbox" name={FILTERS.PRODUCTS+FILTERS.RESTRICT} checked={this.props.filters[FILTERS.PRODUCTS][FILTERS.RESTRICT]} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">or</label>
                            </div>
                            <div className="cell small-9 no-pad">
                                <label className="filter-title">Exclude
                                    <input  type="checkbox" name={FILTERS.PRODUCTS+FILTERS.EXCLUDE} checked={this.props.filters[FILTERS.PRODUCTS][FILTERS.EXCLUDE]} onClick={this.handleChange}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="cell small-6 no-pad">
                                <select className="edit-field" value={this.state.product} name="product" onChange={this.handleChange}>
                                {this.state.products.map((product, c) => {
                                    return <option value={c}>{product.name}</option>
                                })}
                                </select>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="av-button" style={{fontSize: "1.1em"}} onClick={() => this.addToList(FILTERS.PRODUCTS, this.state.product)}>
                                    Add
                                </div>
                            </div>
                            {this.props.filters[FILTERS.PRODUCTS][FILTERS.LIST].length > 0 ?
                                <div className="cell small-12 small-pad filteritems">
                                    {this.props.filters[FILTERS.PRODUCTS][FILTERS.LIST].map((product, c) => {
                                        return <span value={product.id}>{product.name} <span className="filterx" onClick={() => this.removeFromList(FILTERS.PRODUCTS, product)}>X</span></span>
                                    })}
                                </div>
                                :
                                <div>Select an item from above and tap Add.</div>
                            }
                        </React.Fragment>
                        }
                    </div>


                    }



                    {this.state.technicians &&

                    <div className="filterbox">


                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Technicians</b>
                                <input  type="checkbox" name={FILTERS.TECHNICIANS+FILTERS.IS_ON} checked={this.props.filters[FILTERS.TECHNICIANS][FILTERS.IS_ON]} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>

                        </div>

                        {this.props.filters[FILTERS.TECHNICIANS][FILTERS.IS_ON] &&
                            <React.Fragment>
                                <div className="cell small-2 no-pad">
                                    <label className="filter-title">Restrict to
                                        <input  type="checkbox" name={FILTERS.TECHNICIANS+FILTERS.RESTRICT} checked={this.props.filters[FILTERS.TECHNICIANS][FILTERS.RESTRICT]} onClick={this.handleChange}/>
                                        <span className="checkmark"></span>
                                    </label>

                                </div>
                                <div className="cell small-1 no-pad">
                                    <label className="filter-title">or</label>
                                </div>
                                <div className="cell small-9 no-pad">
                                    <label className="filter-title">Exclude
                                        <input  type="checkbox" name={FILTERS.TECHNICIANS+FILTERS.EXCLUDE} checked={this.props.filters[FILTERS.TECHNICIANS][FILTERS.EXCLUDE]} onClick={this.handleChange}/>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="cell small-6 no-pad">
                                    <select className="edit-field" value={this.state.technician} name="venue" onChange={this.handleChange}>
                                        {this.state.technicians.map((technician, c) => {
                                            return <option value={c}>{technician.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="cell small-6 no-pad">
                                    <div className="av-button" style={{fontSize: "1.1em"}} onClick={() => this.addToList(FILTERS.TECHNICIANS, this.state.technician)}>
                                        Add
                                    </div>
                                </div>
                                {this.props.filters[FILTERS.TECHNICIANS][FILTERS.LIST].length > 0 ?
                                    <div className="cell small-12 small-pad filteritems">
                                        {this.props.filters[FILTERS.TECHNICIANS][FILTERS.LIST].map((technician, c) => {
                                            return <span value={technician.id}>{technician.name} <span className="filterx" onClick={() => this.removeFromList(FILTERS.TECHNICIANS, technician)}>X</span></span>
                                        })}
                                    </div>
                                    :
                                    <div>Select an item from above and tap Add.</div>
                                }
                            </React.Fragment>
                        }
                    </div>
                    }

                    <div className="filterbox">
                        <div className="cell small-12 no-pad">
                            <label className="filter-title"><b>Location</b>
                                <input  type="checkbox" name={FILTERS.DISTANCE+FILTERS.IS_ON} checked={this.props.filters[FILTERS.DISTANCE][FILTERS.IS_ON]} onClick={this.handleChange}/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        {this.props.filters[FILTERS.DISTANCE][FILTERS.IS_ON] &&
                        <React.Fragment>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">Within</label>
                            </div>
                            <div className="cell small-3 no-pad">
                                <select className="edit-field" value={this.state.distance} name="distance" onChange={this.handleChange}>
                                    <option value="5">5 miles</option>
                                    <option value="10">10 miles</option>
                                    <option value="25">25 miles</option>
                                    <option value="50">50 miles</option>
                                    <option value="100">100 miles</option>
                                </select>
                            </div>
                            <div className="cell small-1 no-pad">
                                <label className="filter-title">of Postcode:</label>
                            </div>
                            <div className="cell small-3 no-pad">
                                <input className="edit-field" type="text" name={FILTERS.POSTCODE+FILTERS.VALUE}  placeholder="Postcode" value={this.state[FILTERS.POSTCODE+FILTERS.VALUE]} onChange={this.handleChange}/>
                            </div>
                            <div className="cell small-4 no-pad"></div>
                        </React.Fragment>
                        }
                    </div>


                </div>
                }
            </div>

        </div>);
    }

}


export default OverviewFilter;