import React from 'react'
import {FILTERS, CLIENT_TABS} from '../../resources/strings'
import ClientProduct from "./ClientProduct";
import Axios from "axios";
import {API} from "../../api/APIClient";
import ClientVenueHead from "./ClientVenueHead";
import Client from './Client'
import OverviewFilter2 from "./OverviewFilter2";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import MyPie from "../common/MyPie";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import LatestClientResults from "./LatestClientResults";
import { ThemeProvider } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles';

class ClientsBody extends React.Component {

    constructor(props) {
        super(props);

        

        this.state = {
            products: [],
            clients: [],
            filters: null,
            page: 0,
            rowsPerPage: 10,
            wastagePage:0,
            faultsPage:0,
            clientsForResults: []
        };
        //this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.updateData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.filters !== this.state.filters) {
            this.updateData();
        }
    }

    updateData = () => {
        this.getProducts();
        this.getSummaryData();
        this.getWastageData();
        this.getFaultsData();
        this.getSimpleClientsList();
        this.getClientsVenues();
    }

    getProducts() {
        const formData = new FormData();

        let filters = Object.assign({},this.state.filters);
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.products.productsForUser2, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        products: res.data.data.map((prod, c) => {

                            return <ClientProduct
                                        key={prod.id}
                                        prod={prod.id}
                                        userid={this.props.user.id}
                                        name={prod.name}
                                        filters={this.state.filters}
                                        didSelectVenue={this.props.didSelectVenue}
                                    />;
                        })
                    })
                }
            })
    }



    getClientsVenues() {
        const formData = new FormData();
        formData.append('userID', this.props.user.id);

        let filters = Object.assign({},this.state.filters);
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.locations.clientsAndVenuesForUser2, formData)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        clients: res.data.data
                    })
                }
            })

    }

    getSimpleClientsList() {
        const formData = new FormData();
        formData.append('userID', this.props.user.id);

        let filters = Object.assign({},this.state.filters);
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.simpleClientsList2, formData)
            .then((res) => {

                if (res.data.success) {

                    console.log("clientForResults updated");
                    this.setState({
                        clientsForResults: res.data.data.map((client, c) => {
                            let timestamp = Date.now().toString(16);
                            return <LatestClientResults
                                key={client.id}
                                index={c}
                                clientID={client.id}
                                userid={this.props.user.id}
                                name={client.clientname}
                                onShowDetails={() => this.props.didSelectClient(client.id, client.clientname)}
                                filters={filters}
                            />;
                        })
                    })
                }
            })
    }




    getFaultsData() {
        const formData = new FormData();
        formData.append('userID', this.props.user.id);

        let filters = Object.assign({},this.state.filters);
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.getFaultsTableData2, formData)
            .then((res) => {

                if (res.data.success) {

                    console.log("tableData");
                    console.log(res.data.tableData[0]);

                    this.setState({

                        faultsTableData: res.data.tableData.map((row, c) => {

                                return <TableRow key={c} >
                                    <TableCell component="th" scope="row" ><span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.props.didSelectVenue(row.clientID, row.clientName, row.locationID, row.locationname)}>{row.locationname}</span></TableCell>
                                    <TableCell>{row.low}</TableCell>
                                    <TableCell>{row.standard}</TableCell>
                                    <TableCell>{row.high}</TableCell>
                                    <TableCell>{row.recurring}</TableCell>
                                    <TableCell>{parseInt(row.low)+parseInt(row.standard)+parseInt(row.high)}</TableCell>
                                </TableRow>


                        })
                    })
                }
            })
    }


    getWastageData() {
        const formData = new FormData();
        formData.append('userID', this.props.user.id);

        let filters = Object.assign({},this.state.filters);
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.getWastageTableData2, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({

                        wastageTableData: res.data.tableData.map((row, c) => {

                            console.log(row);

                            var parsedVal = parseFloat(row.wastage, 10);
                            if (isNaN(parsedVal)) {
                                parsedVal = 0;
                            }

                                return <TableRow key={c}>
                                    <TableCell><span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.props.didSelectVenue(row.clientID, row.clientname, row.id, row.locationname)}>{row.locationname}</span></TableCell>
                                    <TableCell>{parsedVal.toFixed(1)}</TableCell>
                                </TableRow>

                        })
                    })
                }
            })
    }

    getSummaryData() {

        const formData = new FormData();
        formData.append('userID', this.props.user.id);

        let filters = Object.assign({},this.state.filters);
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.getSummaryData2, formData)
            .then((res) => {

                if (res.data.success) {

                    var sumTble = res.data.summaryData.chart.map((row, c) => {

                        return <TableRow key={c}>
                            <TableCell component="th" scope="row">{row.title}<span className={"dot_" + row.title}></span></TableCell>
                            <TableCell><span className={"dot_" + row.value} />{row.value}</TableCell>
                            <TableCell>{row.percentage}%</TableCell>
                        </TableRow>

                    })
                    sumTble.push(<TableRow key="totals">
                        <TableCell component="th" scope="row">Total</TableCell>
                        <TableCell>{res.data.summaryData.total}</TableCell>
                        <TableCell>100%</TableCell>
                    </TableRow>)

                    this.setState({
                        clientCount: res.data.summaryData.numClients,
                        venueCount: res.data.summaryData.numVenues,
                        summaryPieData: res.data.summaryData.chart.map((segment, c) => {

                            var parsedVal = parseFloat(segment.percentage, 10);
                            if (isNaN(parsedVal)) {
                                parsedVal = 0
                            }

                            return {title: segment.title, value: parsedVal, color: segment.color};

                        }),
                        summaryTableData:sumTble
                    })
                }
            })
    }

    showWastageMessage()
    {
        this.setState({showWastageMessage: true})
    }
    hideWastageMessage(){
        this.setState({showWastageMessage: false})
    }

    clientsSearchTxtChanged(txt)
    {
        const searchText = txt.toLowerCase();
        let matchingClients = [];
        for (let c = 0; c < this.state.clients.length; c++) {
            const client = this.state.clients[c];
            if (client.name.toLowerCase().includes(searchText)) {
                matchingClients.push(client);
                continue;
            }
            for (let l = 0; l < client.locations.length; l++) {
                const location = client.locations[l];
                if (location.locationname.toLowerCase().includes(searchText)) {
                    matchingClients.push(client);
                    break;
                }
            }
        }

            this.setState({clientSearchTxt: txt,
            matchingClients: matchingClients})
    }


    render() {
        const theme = createMuiTheme({
            overrides: {
                MuiTableCell: {
                    root: {  //This can be referred from Material UI API documentation.
                        padding: '10px 8px'},
                }
            },
        });

        switch (this.props.currentTab) {

            case CLIENT_TABS.OVERVIEW :
                return (
                    <div className="clientsbody">

                        <OverviewFilter2 filters={this.state.filters} user={this.props.user} onChange={(filters) => this.setState({filters: filters})}/>

                        <div className="cell small-12 small-pad"/>

                        <div className="cell small-12 container">
                            <div className="title"> Summary Of Latest Results</div>
                        <div className="cell small-6 summary-wrap">

                            <div className="cell small-6 summary-stats"><span className="loz">Number of Clients <span className="loz-stat">{this.state.clientCount}</span></span></div><div className="cell small-6 summary-stats"><span className="loz">Number of Venues <span className="loz-stat">{this.state.venueCount}</span></span></div>
                            {/* <div className="cell small-12"/> REMOVED BUT MAY BE NEEDED FOR SOMETHING */}
                            <TableContainer component={Paper}>
                                <ThemeProvider theme={theme}>

                                <Table  size="small" padding="none" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Score</TableCell>
                                            <TableCell>Number Of Lines By Result</TableCell>
                                            <TableCell>Total Percentage</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.summaryTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.summaryTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                    </TableBody>
                                </Table>
                                </ThemeProvider>
                            </TableContainer>

                        </div>
                            {/* <div className="cell small-1"/> removed but may need */}

                                <div className="cell small-6 pie-wrap">
                            {this.state.summaryPieData === undefined ? <div>Loading...</div> :

                                <div className="title pie-view" style={{textAlign: "center"}}>

                                    <MyPie data={this.state.summaryPieData} />
                                </div>
                            }
                        </div>
                            {/* <div className="cell small-1"/> removed but may need */}

                        </div>

                        <div className="cell small-12 small-pad" />
                        <div className="cell small-12 container">
                            <div className="cell small-5 small-pad">
                                <div className="title">Total Venue Wastage From Last Visit In Pints<div className="tooltip"><span className="tooltip-reveal">Wastage is a measure of the amount of beer (pints) held in lines that has to be discarded before a ProClean.</span></div></div>


                                <div className="cell small-12" />
                                <TableContainer component={Paper}>
                                    <Table  size="small" aria-label="clean results">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Venue</TableCell>
                                                <TableCell>Wastage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.wastageTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.wastageTableData.slice(this.state.wastagePage * this.state.rowsPerPage, this.state.wastagePage * this.state.rowsPerPage + this.state.rowsPerPage)}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                                {this.state.wastageTableData &&
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.wastageTableData === undefined ? 0 : this.state.wastageTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.wastagePage}
                                    onChangePage={(event, newPage) => {

                                        this.setState({wastagePage: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({wastagePage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />}
                            </div>
                            <div className="cell small-1 small-pad"></div>
                                <div className="cell small-6 small-pad">
                                <div className="title"> Total Technical Faults</div>


                                <div className="cell small-12" />
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="clean results">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Venue</TableCell>
                                                <TableCell>Low</TableCell>
                                                <TableCell>Standard</TableCell>
                                                <TableCell>High</TableCell>
                                                <TableCell>Recurring</TableCell>
                                                <TableCell>Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.faultsTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.faultsTableData.slice(this.state.faultsPage * this.state.rowsPerPage, this.state.faultsPage * this.state.rowsPerPage + this.state.rowsPerPage)}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                                {this.state.faultsTableData &&
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.faultsTableData === undefined ? 0 : this.state.faultsTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.faultsPage}
                                    onChangePage={(event, newPage) => {

                                        this.setState({faultsPage: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({faultsPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />}


                            </div>
                        </div>

                        <div className="cell small-12 small-pad" />

                        <div className="title cell small-12">Latest Visit Results From each Client & Venue</div>
                        <p>A list showing a summary of all of the latest single visits from each venue per client</p>

                        <div className="cell small-12 over-latest-wrapper" >
                            {this.state.clientsForResults}
                        </div>
                        <div className="cell small-12 small-pad" />


                        {this.state.showWastageMessage && <div className="modal">
                            <div className="modal-content"><div><b>What is wastage?</b></div>Wastage is...[definition TBC]
                                <div className="cell small-12"/>

                                <div className="av-button" onClick={() => this.hideWastageMessage()}>OK</div>
                            </div></div>}

                    </div>

                );
            case CLIENT_TABS.CLIENT_LIST :
                return (
                    <div className="clientsbody">
                        <OverviewFilter2  filters={this.state.filters} user={this.props.user} onChange={(filters) => this.setState({filters: filters})}/>

                        <div className="cell small-12 small-pad"/>
                        <div className="clientvenue-list">
                            <ClientVenueHead searchTextChanged={(txt)=>this.clientsSearchTxtChanged(txt)} />

                            {this.state.clientSearchTxt ?

                                this.state.matchingClients.map((client, c) => {

                                return <Client selectMode={true} key={client.id} clientID={client.id} userid={this.props.user.id}
                                name={client.name} venues={client.locations} locCount={client.locCount}
                                didSelectClient={this.props.didSelectClient} didSelectVenue={(clientID, clientName, venueID, venueName) => this.props.didSelectVenue(clientID, clientName, venueID, venueName)}
                                />;
                            })

                            :

                                this.state.clients.map((client, c) => {

                                return <Client selectMode={true} key={client.id} clientID={client.id} userid={this.props.user.id}
                                name={client.name} venues={client.locations} locCount={client.locCount}
                                didSelectClient={this.props.didSelectClient} didSelectVenue={(clientID, clientName, venueID, venueName) => this.props.didSelectVenue(clientID, clientName, venueID, venueName)}
                                />;
                            })}

                            <div className="cell small-12"/>

                        </div>
                    </div>
                );
            case CLIENT_TABS.PRODUCTS :
                return (
                    <div className="clientsbody">
                        <OverviewFilter2 filters={this.state.filters} user={this.props.user}  onChange={(filters) => this.setState({filters: filters})}/>

                        <div className="product-list-wrap">
                        <div className="cell small-12 no-pad">

                            <div className="clientsvenues" >
                                <div className="title">List of Products used:</div>

                            </div>
                        </div>

                        

                        {this.state.products}
                        </div>
                        

                    </div>

                );
            default :
                return (<div>unknown screen</div>);

        }
    }





}
export default ClientsBody;
