import React from "react";
import {ADMIN_SECTIONS, BUTTON_MSG} from "../../../resources/strings";
import Axios from "axios";
import {API} from "../../../api/APIClient"
import Bar from "./Bar";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";



class VenueDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            locationDetails: undefined,
            remotesPage: 0,
            usersPage:0,
            notesPage: 0,
            rowsPerPage: 15
        };
        this.handleChange = this.handleChange.bind(this);
    }


    componentWillUnmount() {

        if(this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE) {
            if (window.confirm("Editing in progress - do you want to save before you leave?")) {
                this.saveVenue()
            }
        }

    }
    componentDidMount() {

        setTimeout(
            () => this.loadDetails(),
            100
        );

    }
    loadDetails() {
        if (this.props.venueID === -1) {
            this.setState({
                locationDetails: {locationname: ""},
                edit_locationname: "",
                edit_address: "",
                edit_address2: "",
                edit_town: "",
                edit_county: "",
                edit_postcode: "",
                edit_contact: "",
                edit_email: "",
                edit_phonenumber: "",
                edit_notes: ""
            })
        } else {
            this.getVenueDetails()
            this.getVenueBars()
            this.getProducts()
            this.getRemotes()
            this.getAssocUsersForVenue()
            this.getUserNotes()
        }

    }

    hideNoteModal() {
        this.setState({editingNote: false})
    }

    hideBarModal() {
        this.setState({selectedBar: undefined, editingBar: false})
    }
    hideRemoteModal() {
        this.setState({selectedRemote: undefined, editingRemote: false})
    }
    hideCellarLinksModal() {
        this.setState({cellarLinks: undefined, editingCellarLinks: undefined})
    }
    hideRemoteLinksModal() {
        this.setState({remoteLinks: undefined, editingRemoteLinks: undefined})
    }
    saveCellarLinksClicked()
    {
        const formData = new FormData();
        formData.append('cellarID', this.state.remotes[this.state.editingCellarLinks].id);
        formData.append('venueID', this.props.venueID);
        var remoteIDs = []
        var index;
        for (index = 0; index < this.state.cellarLinks.length; ++index) {

            if (this.state.cellarLinks[index].linkID) {
                remoteIDs.push(this.state.cellarLinks[index].id)
            }
        }
        formData.append('remoteIDs', remoteIDs);

        Axios
            .post(API.locations.saveCellarLinks, formData)
            .then((res) => {

                if (res.data.success) {

                    alert("Changes saved successfully")
                    this.setState({
                        cellarLinks: undefined, editingCellarLinks: undefined
                    })
                }

            })
    }



    saveRemoteLinksClicked()
    {
        const formData = new FormData();
        formData.append('remoteID', this.state.remotes[this.state.editingRemoteLinks].id);
        formData.append('venueID', this.props.venueID);
        var barIDs = []
        var index;
        for (index = 0; index < this.state.remoteLinks.length; ++index) {

            if (this.state.remoteLinks[index].linkID) {
                barIDs.push(this.state.remoteLinks[index].id)
            }
        }
        formData.append('barIDs', barIDs);

        Axios
            .post(API.locations.saveRemoteLinks, formData)
            .then((res) => {

                if (res.data.success) {

                    alert("Changes saved successfully")
                    this.setState({
                        remoteLinks: undefined, editingRemoteLinks: undefined
                    })
                }

            })
    }



    componentWillReceiveProps(nextProps) {

        if (this.props.venueID === -1 && nextProps.buttonMessage === undefined) {
            this.props.itemClickHandler(ADMIN_SECTIONS.CLIENTS) //go back
        }
        if (nextProps.buttonMessage === BUTTON_MSG.SAVE_VENUE) {
            this.saveVenue()
        }
        if (nextProps.buttonMessage === BUTTON_MSG.EDIT_VENUE && this.props.buttonMessage !== BUTTON_MSG.SAVE_VENUE) {

            this.setState({
                edit_locationname: this.state.locationDetails.locationname,
                edit_address: this.state.locationDetails.address ? this.state.locationDetails.address : "",
                edit_address2: this.state.locationDetails.address2 ? this.state.locationDetails.address2 : "",
                edit_town: this.state.locationDetails.town ? this.state.locationDetails.town : "",
                edit_county: this.state.locationDetails.county ? this.state.locationDetails.county : "",
                edit_postcode: this.state.locationDetails.postcode ? this.state.locationDetails.postcode : "",
                edit_contact: this.state.locationDetails.contact ? this.state.locationDetails.contact : "",
                edit_email: this.state.locationDetails.email ? this.state.locationDetails.email : "",
                edit_phonenumber: this.state.locationDetails.phonenumber ? this.state.locationDetails.phonenumber : "",
                edit_notes: this.state.locationDetails.notes ? this.state.locationDetails.notes : ""

            })
        }
    }

    getUserNotes() {
        const formData = new FormData();
        formData.append('venueID', this.props.venueID);

        Axios
            .post(API.locations.getNotes, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            notesTableData: []
                        })
                    } else {
                        this.setState({
                            notesTableData: res.data.data.map((note, c) => {



                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{note.date}</TableCell>
                                    <TableCell>{note.name}</TableCell>
                                    <TableCell>
                                        <div onClick={() => this.showNote(note)} className="av-button" style={{padding: "2px 30px"}}>View</div>
                                        <div onClick={() => this.deleteNote(note.id)} className="av-button" style={{padding: "2px 30px"}}>Delete</div>
                                    </TableCell>
                                </TableRow>


                            })

                        })

                    }
                }
            })
    }


    getAssocUsersForVenue()
    {
        const formData = new FormData();
        formData.append('venueID', this.props.venueID);

        Axios
            .post(API.locations.usersForVenue, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            usersTableData: []
                        })
                    } else {
                        this.setState({
                            usersTableData: res.data.data.map((user, c) => {



                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{user.name}</TableCell>
                                    <TableCell>{parseInt(user.technician) === 1 ?
                                        <div>{parseInt(user.admin) === 1 ? "Admin Technician" : "Technician"}</div>
                                        :
                                        <div>{parseInt(user.admin) === 1 ? "Administrator" : "Client User"}</div>
                                    }</TableCell>
                                    <TableCell>
                                        <div onClick={() => this.props.didSelectUser(user.id)} className="av-button" style={{padding: "2px 30px"}}>View</div>
                                    </TableCell>
                                </TableRow>


                            })

                        })

                    }
                }
            })
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        const splitName = name.split("_");
        if (splitName.length === 2 && splitName[0] === "cellarlink") {
            const checked = target.checked;

            var updatedLinks = this.state.cellarLinks.slice(0)
            updatedLinks[parseInt(splitName[1])].linkID = checked
            this.setState({cellarLinks: updatedLinks})

        } else if (splitName.length === 2 && splitName[0] === "remotelink") {
            const checked = target.checked;

            var updatedLinks = this.state.remoteLinks.slice(0)
            updatedLinks[parseInt(splitName[1])].linkID = checked
            this.setState({remoteLinks: updatedLinks})
        } else if (name === "edit_line_length") {
            this.setState({
                edit_wastage: (parseFloat(value) + 0.3),
                [name]: value
            });
        } else {
            this.setState({
                [name]: value
            });
        }

    }

    deleteTapClicked() {
        const formData = new FormData();

        formData.append('tapID', this.state.deletingTap);


        Axios
            .post(API.locations.deleteTap, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({deletingTap: undefined, barToRefresh: this.state.deletingTapBar})
                } else {
                    var msg = "Error deleting tap, please try again."
                    alert(msg)

                }
            }).catch(error => {

            var msg = "Error deleting tap. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)

            }
        })
    }


    deleteNote(id) {

        const formData = new FormData();

        formData.append('noteID', id);


        Axios
            .post(API.locations.deleteNote, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({notesTabledata : undefined},
                        () => this.getUserNotes())

                } else {
                    var msg = "Error deleting note, please try again."
                    alert(msg)

                }
            }).catch(error => {

            var msg = "Error note bar. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)

            }
        })
    }



    deleteBarClicked() {

        const formData = new FormData();

        formData.append('barID', this.state.bars[this.state.deletingBar].id);


        Axios
            .post(API.locations.deleteBar, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({deletingBar: undefined, bars : undefined},
                        () => this.getVenueBars())

                } else {
                    var msg = "Error deleting bar, please try again."
                    alert(msg)

                }
            }).catch(error => {

            var msg = "Error deleting bar. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)

            }
        })

    }

    deleteRemoteClicked() {

        const formData = new FormData();

        formData.append('remoteID', this.state.remotes[this.state.deletingRemote].id);


        Axios
            .post(API.locations.deleteRemote, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({deletingRemote: undefined, remotes : undefined, remotesTabledata : undefined},
                        () => this.getRemotes())

                } else {
                    var msg = "Error deleting item, please try again."
                    alert(msg)

                }
            }).catch(error => {

            var msg = "Error deleting item. "

            if (error.response) {
                if (error.response.data.error) {

                    msg = msg + error.response.data.error

                }

                alert(msg)
            } else {
                alert(error)

            }
        })

    }

    hideNote() {
        this.setState({showingNote: undefined})
    }

    showNote(note)
    {
        this.setState({showingNote: note})

    }
    cancelBarDelete()
    {
        this.setState({deletingBar: undefined})
    }

    cancelRemoteDelete()
    {
        this.setState({deletingRemote: undefined})
    }

    cancelTapDelete()
    {
        this.setState({deletingTap: undefined})
    }
    hideTapModal()
    {
        this.setState({editingTap: undefined, editingTapName: undefined, editingTapProduct: undefined})

    }
    getProducts() {
        const formData = new FormData();

        Axios
            .post(API.products.getAllProducts, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            products: []
                        })
                    } else {
                        this.setState({
                            products: res.data.data

                        })

                    }
                }
            })
    }


    getRemotes()
    {
        const formData = new FormData();
        formData.append('venueID', this.props.venueID);

        Axios
            .post(API.locations.getRemotes, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            remotesTableData: [],
                            remotes: [],
                        })
                    } else {
                        this.setState({
                            remotes: res.data.data,
                            remotesTableData: res.data.data.map((remote, c) => {

                                return <TableRow key={c}>
                                    <TableCell component="th" scope="row">{remote.type}</TableCell>
                                    <TableCell component="th" scope="row">{remote.name}</TableCell>
                                    <TableCell>
                                        <div onClick={() => this.editRemoteClicked(c)} className="av-button" style={{padding: "2px 30px"}}>Edit</div>
                                        <div onClick={() => this.handleRemoteDelete(c, remote.type)} className="av-button" style={{padding: "2px 30px"}}>Delete</div>
                                        {remote.type === "Cellar" ?
                                            <div onClick={() => this.editCellarLinks(c)} className="av-button" style={{padding: "2px 30px"}}>Edit Links</div>

                                            :
                                            <div onClick={() => this.editRemoteLinks(c)} className="av-button" style={{padding: "2px 30px"}}>Edit Links</div>

                                        }
                                    </TableCell>
                                </TableRow>


                            })

                        })

                    }
                }
            })
    }



    saveTapClicked(){
            if (this.state.editingTapName) {
                const formData = new FormData();
                if (this.state.editingTap === -1) {
                    formData.append('tapID', -1);
                } else {
                    formData.append('tapID', this.state.editingTap);
                }

                formData.append('barID',this.state.bars[this.state.editingTapBar].id);
                formData.append('linenumber', this.state.editingTapName);
                formData.append('productID', this.state.editingTapProduct);

                Axios
                    .post(API.locations.editTap, formData)
                    .then((res) => {

                        if (res.data.success) {

                            this.setState({editingTap: undefined, editingTapName: undefined, editingTapProduct: undefined,barToRefresh: this.state.editingTapBar})
                        } else {
                            var msg = "Error saving tap, please check the details entered and try again."
                            alert(msg)

                        }
                    }).catch(error => {

                    var msg = "Error saving tap. "

                    if (error.response) {
                        if (error.response.data.error) {

                            msg = msg + error.response.data.error

                        }

                        alert(msg)
                    } else {
                        alert(error)

                    }
                })
            } else {
                alert("Please fill in the tap number field.")
            }
    }

    saveBarClicked() {
        if (this.state.edit_barname) {
            const formData = new FormData();
            if (this.state.selectedBar === -1) {
                formData.append('barID', -1);

            } else {
                formData.append('barID', this.state.bars[this.state.selectedBar].id);

            }
            formData.append('venueID', this.props.venueID);
            formData.append('barname', this.state.edit_barname);
            formData.append('line_length', this.state.edit_line_length);
            formData.append('line_age', this.state.edit_line_age);
            formData.append('line_type', this.state.edit_line_type);
            formData.append('wastage', this.state.edit_wastage);

            Axios
                .post(API.locations.editBar, formData)
                .then((res) => {

                    if (res.data.success) {

                        alert("Changes saved successfully");
                        this.setState({selectedBar: undefined, editingBar: false, bars : undefined},
                            () => this.getVenueBars())
                    } else {
                        var msg = "Error saving venue, please check the details entered and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error saving venue. "

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the bar name field.")
        }
    }

    saveNoteClicked() {
        if (this.state.edit_note) {
            const formData = new FormData();

            formData.append('venueID', this.props.venueID);
            formData.append('text', this.state.edit_note);

            Axios
                .post(API.locations.addNote, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.setState({editingNote: undefined,  notesTableDate : undefined},
                            () => this.getUserNotes())
                    } else {
                        var msg = "Error saving note, please check the details entered and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error saving note. "

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the note text field.")
        }
    }

    saveRemoteClicked() {
        if (this.state.edit_remotename) {
            const formData = new FormData();
            if (this.state.selectedRemote === -1) {
                formData.append('remoteID', -1);
                formData.append('type', this.state.edit_remoteType);
            } else {
                formData.append('remoteID', this.state.remotes[this.state.selectedRemote].id);

            }
            formData.append('venueID', this.props.venueID);
            formData.append('remotename', this.state.edit_remotename);

            Axios
                .post(API.locations.editRemote, formData)
                .then((res) => {

                    if (res.data.success) {

                        alert("Changes saved successfully");
                        this.setState({selectedRemote: undefined, editingRemote: false, remotes : undefined, remotesTabledata : undefined},
                            () => this.getRemotes())
                    } else {
                        var msg = "Error saving remote, please check the details entered and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error saving remote. "

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the  name field.")
        }
    }

    saveVenue() {

        if (this.state.edit_locationname) {
            const formData = new FormData();
            formData.append('venueID', this.props.venueID);
            formData.append('clientID', this.props.clientID);
            formData.append('locationname', this.state.edit_locationname);
            formData.append('address', this.state.edit_address);
            formData.append('address2', this.state.edit_address2);
            formData.append('town', this.state.edit_town);
            formData.append('county', this.state.edit_county);
            formData.append('postcode', this.state.edit_postcode);
            formData.append('contact', this.state.edit_contact);
            formData.append('email', this.state.edit_email);
            formData.append('phonenumber', this.state.edit_phonenumber);
            formData.append('notes', this.state.edit_notes);

            Axios
                .post(API.locations.editLocation, formData)
                .then((res) => {

                    if (res.data.success) {

                        this.props.buttonMessageHandler(undefined);

                        if (this.props.venueID === -1 && res.data.result) {


                            //was a new one...add another?
                            if (window.confirm("Venue added. Would you like to add another?")) {
                                this.props.didSelectClient(this.props.clientID)
                                this.props.didSelectVenue(-1);
                            } else {
                                this.props.didSelectClient(this.props.clientID)
                                this.props.didSelectVenue(res.data.result)
                            }
                        } else {
                            this.getVenueDetails()
                        }
                    } else {
                        var msg = "Error saving venue, please check the details entered and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error saving venue. "

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the name field.")
            this.props.buttonMessageHandler(BUTTON_MSG.EDIT_VENUE);
        }


    }

    getVenueBars() {
        const formData = new FormData();
        formData.append('venueID', this.props.venueID);

        Axios
            .post(API.locations.getBars, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        bars: res.data.data
                    })
                }
            })
    }

    getVenueDetails() {

        const formData = new FormData();
        formData.append('venueID', this.props.venueID);

        Axios
            .post(API.locations.getDetails, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false) {
                        this.props.itemClickHandler(ADMIN_SECTIONS.CLIENTS) //no details...just go back?

                    } else {
                        this.setState({
                            locationDetails: res.data.data
                        })
                    }
                }
            })
    }

    getCellarLinks(cellarID) {

        const formData = new FormData();
        formData.append('cellarID', cellarID);
        formData.append('venueID', this.props.venueID);

        Axios
            .post(API.locations.getCellarLinks, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        cellarLinks: res.data.result
                    })
                }

            })
    }

    getRemoteLinks(remoteID) {

        const formData = new FormData();
        formData.append('remoteID', remoteID);
        formData.append('venueID', this.props.venueID);

        Axios
            .post(API.locations.getRemoteLinks, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        remoteLinks: res.data.result
                    })
                }

            })
    }


    handleBarDetailsClick(indx) {
        this.setState({selectedBar: indx})
    }

    handleBarDeleteClick(index) {
        this.setState({deletingBar: index})

    }

    handleRemoteDelete(index,type) {
        this.setState({deletingRemote: index, deletingRemoteType: type})

    }


    handleTapDeleteClick(id, barID) {
        this.setState({deletingTap: id, deletingTapBar: barID})

    }
    handleTapEditClick(id, name, prodID, barID) {
        this.setState({editingTap: id, editingTapName: name,editingTapProduct: prodID, editingTapBar: barID})
    }

    newTapClicked(barID){
        this.setState({editingTap: -1, editingTapName: "",editingTapProduct: this.state.products[0].id, editingTapBar: barID})
    }


    newBarClicked()
    {
        this.setState({selectedBar: -1,
            editingBar: true,
            edit_barname: "",
            edit_line_length: "",
            edit_line_age:"",
            edit_line_type: "",
            edit_wastage: ""
        })
    }

    newNoteClicked() {
        this.setState({
            editingNote: true,
            edit_note: ""
        })
    }


    newRemoteClicked(type)
    {
        this.setState({selectedRemote: -1,
            editingRemote: true,
            edit_remotename: "",
            edit_remoteType: type

        })
    }
    editBarClicked()
    {
        this.setState({editingBar: true,
            edit_barname: this.state.bars[this.state.selectedBar].barname,
            edit_line_length: this.state.bars[this.state.selectedBar].line_length ? this.state.bars[this.state.selectedBar].line_length : "",
            edit_line_age: this.state.bars[this.state.selectedBar].line_age ? this.state.bars[this.state.selectedBar].line_age : "",
            edit_line_type: this.state.bars[this.state.selectedBar].line_type ? this.state.bars[this.state.selectedBar].line_type : "",
            edit_wastage: this.state.bars[this.state.selectedBar].line_length ? parseFloat(this.state.bars[this.state.selectedBar].line_length) + 0.3 : ""
        })


    }

    editRemoteClicked(remoteIndex)
    {
        this.setState({selectedRemote: remoteIndex,
            editingRemote: true,
            edit_remotename: this.state.remotes[remoteIndex].name

        })
    }

    editCellarLinks(remoteIndex) {
        this.setState({
            editingCellarLinks: remoteIndex
        })
        this.getCellarLinks(this.state.remotes[remoteIndex].id)
    }

    editRemoteLinks (remoteIndex)
    {
        this.setState({
            editingRemoteLinks: remoteIndex
        })
        this.getRemoteLinks(this.state.remotes[remoteIndex].id)
    }

    render() {

        return(<div>
            {this.state.locationDetails === undefined ?

                <div>Loading...</div>

                :
                <div>
                <div className="cell small-12 user_deets details-wrap small-pad">

                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Main Details</div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Venue Name</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_locationname" placeholder="Venue Name" value={this.state.edit_locationname} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.locationname}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Address Line 1</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_address" placeholder="Address" value={this.state.edit_address} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.address}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Address Line 2</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_address2" placeholder="Address line 2" value={this.state.edit_address2} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.address2}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Town</span>
                                {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_town" placeholder="Town" value={this.state.edit_town} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.town}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>County</span>
                               {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_county" placeholder="County" value={this.state.edit_county} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.county}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Postcode</span>
                               {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_postcode" placeholder="Postcode" value={this.state.edit_postcode} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.postcode}</div>
                                }
                            </div>
                        </div>
            
                        <div className="clearfix" />
                    </div>

                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Contact Details</div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Contact</span>
                               {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_contact" placeholder="Contact" value={this.state.edit_contact} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.contact}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Email</span>
                               {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_email" placeholder="Email" value={this.state.edit_email} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.email}</div>
                                }
                            </div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Phone</span>
                               {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_phonenumber" placeholder="Phone" value={this.state.edit_phonenumber} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.phonenumber}</div>
                                }
                            </div>
                        </div>

                        <div className="clearfix" />
                    </div>




                    <div className="deets_sect">
                        <div className="cell small-12 small-pad">
                            <div className="title">Notes</div>
                        </div>

                        <div className="cell small-12 small-pad">
                            <div className="deet">
                                <span>Notes</span>
                               {this.props.buttonMessage === BUTTON_MSG.EDIT_VENUE ?
                                    <input className="edit-field" type="text" name="edit_notes" placeholder="notes" value={this.state.edit_notes} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.locationDetails.notes}</div>
                                }
                            </div>
                        </div>

                        <div className="clearfix" />
                    </div>

                </div>



                    {this.state.bars &&

                    <div className="cell small-12 admin-wrap">

                        <div className="admin-set-box">
                            <div className="cell small-12 title-box">
                                <div className="title">Bars and taps</div>
                            </div>
                            <div className="cell small-12">
                                <span onClick={() => this.newBarClicked()} className="av-button">Add Bar</span>
                            </div>
                            <div className="clearfix" />
                        </div>

                        <div className="bar-list">

                            {this.state.bars.map((bar, c) => {

                                return <Bar name={bar.barname} id={bar.id} index={c} detailsClicked={(c) => this.handleBarDetailsClick(c)}
                                            deleteClicked={(c) => this.handleBarDeleteClick(c)}
                                            deleteTapClicked={(c,d) => this.handleTapDeleteClick(c,d)}
                                            editTapClicked={(c,d,e,f) => this.handleTapEditClick(c,d,e,f)}
                                            barToRefresh={this.state.barToRefresh}
                                            newTapClicked={(a) => this.newTapClicked(a)}
                                />

                            })}
                            <div className="clearfix" />
                        </div>

                        <div className="clearfix" />
                    </div>

                    }



                    {this.state.remotesTableData &&

                    <div className="cell small-12 admin-wrap">

                        <div className="admin-set-box">
                            <div className="cell small-12 title-box">
                                <div className="title">List of Cellars and remotes</div>
                            </div>
                            <div className="cell small-12">
                                <span onClick={() => this.newRemoteClicked("Remote")} className="av-button">Add Remote</span>
                                <span onClick={() => this.newRemoteClicked("Cellar")} className="av-button">Add Cellar</span>
                            </div>
                            <div className="clearfix" />
                        </div>

                        <div className="cell small-12 no-pad">

                            <TableContainer component={Paper}>
                                <Table  size="small" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>  </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.remotesTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.remotesTableData.slice(this.state.remotesPage * this.state.rowsPerPage, this.state.remotesPage * this.state.rowsPerPage + this.state.rowsPerPage)}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                            {this.state.remotesTableData &&
                            <TablePagination
                                rowsPerPageOptions={[15, 30, 100]}
                                component={Paper}
                                count={this.state.remotesTableData === undefined ? 0 : this.state.remotesTableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.remotesPage}
                                onChangePage={(event, newPage) => {

                                    this.setState({remotesPage: newPage})
                                }}
                                onChangeRowsPerPage={(event) => {
                                    this.setState({remotesPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                }}
                            />}
                        </div>
                        <div className="clearfix" />
                    </div>

                    }



                    {(this.state.usersTableData && this.props.buttonMessage !== BUTTON_MSG.EDIT_VENUE) &&
                    <div className="cell small-12 admin-wrap">
                        <div className="admin-set-box">
                            <div className="cell small-12 title-box">
                                <div className="title">Associated Users</div>
                            </div>
                            <div className="clearfix" />
                        </div>



                        <div className="cell small-12 no-pad">

                            <TableContainer component={Paper}>
                                <Table  size="small" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>User Type</TableCell>
                                            <TableCell>  </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.usersTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.usersTableData.slice(this.state.usersPage * this.state.rowsPerPage, this.state.usersPage * this.state.rowsPerPage + this.state.rowsPerPage)}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                            {this.state.usersTableData &&
                            <TablePagination
                                rowsPerPageOptions={[15, 30, 100]}
                                component={Paper}
                                count={this.state.usersTableData === undefined ? 0 : this.state.usersTableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.usersPage}
                                onChangePage={(event, newPage) => {

                                    this.setState({usersPage: newPage})
                                }}
                                onChangeRowsPerPage={(event) => {
                                    this.setState({usersPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                }}
                            />}
                        </div>

                    </div>}



                    {(this.state.notesTableData && this.props.buttonMessage !== BUTTON_MSG.EDIT_VENUE) &&
                    <div className="cell small-12 admin-wrap">
                        <div className="admin-set-box">
                            <div className="cell small-12 title-box">
                                <div className="title">Venue Notes</div>
                            </div>
                            <div className="cell small-12">
                                <span onClick={() => this.newNoteClicked()} className="av-button">Add Note</span>
                            </div>
                            <div className="clearfix" />
                        </div>



                        <div className="cell small-12 no-pad">

                            <TableContainer component={Paper}>
                                <Table  size="small" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>  </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.notesTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.notesTableData.slice(this.state.notesPage * this.state.rowsPerPage, this.state.usersPage * this.state.rowsPerPage + this.state.rowsPerPage)}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                            {this.state.notesTableData &&
                            <TablePagination
                                rowsPerPageOptions={[15, 30, 100]}
                                component={Paper}
                                count={this.state.notesTableData === undefined ? 0 : this.state.notesTableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.notesPage}
                                onChangePage={(event, newPage) => {

                                    this.setState({notesPage: newPage})
                                }}
                                onChangeRowsPerPage={(event) => {
                                    this.setState({notesPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                }}
                            />}
                        </div>

                    </div>}


               
                </div>

            }

            

            

            {( this.state.selectedBar !== undefined ) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div>
                        
                        {this.state.selectedBar === -1 ?
                            <div className="cell small-12 small-pad title">Enter The Details</div>
                            :
                            <div className="cell small-8 small-pad title">{this.state.bars[this.state.selectedBar].barname} Details</div>}
                            {(!this.state.editingBar) &&
                        <div className="cell small-4 edit-btn-box small-pad"><div className="av-button" onClick={() => this.editBarClicked()}>Edit</div></div>}
                    </div>
                    
                    <div className="cell small-12 small-pad modal-section">

                        <div className="cell small-12">
                            <span>Bar Name</span>
                            {this.state.editingBar ?
                                    <input className="edit-field" type="text" name="edit_barname" placeholder="Bar Name" value={this.state.edit_barname} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.bars[this.state.selectedBar].barname}</div>

                                }
                        </div>

                        <div className="cell small-12">
                            <span>Line Length</span>
                            {this.state.editingBar ?
                                    <input className="edit-field" type="text" name="edit_line_length" placeholder="Line Length" value={this.state.edit_line_length} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.bars[this.state.selectedBar].line_length}</div>

                                }
                        </div>

                        <div className="cell small-12">
                            <span>Line Age</span>
                            {this.state.editingBar ?
                                    <input className="edit-field" type="text" name="edit_line_age" placeholder="Line Age" value={this.state.edit_line_age} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.bars[this.state.selectedBar].line_age}</div>

                                }
                        </div>

                        <div className="cell small-12">
                            <span>Line Type</span>
                            {this.state.editingBar ?
                                    <input className="edit-field" type="text" name="edit_line_type" placeholder="Line Type" value={this.state.edit_line_type} onChange={this.handleChange}/>
                                    :
                                    <div className="field">{this.state.bars[this.state.selectedBar].line_type}</div>

                                }
                        </div>

                        <div className="cell small-12">
                            <span>Wastage Per Tap</span>

                            {this.state.editingBar ?

                                    <div className="field">{Math.round(this.state.edit_wastage * 10) / 10 }</div>

                                :
                                    <div className="field">{this.state.bars[this.state.selectedBar].line_length ?parseFloat(this.state.bars[this.state.selectedBar].line_length) + 0.3 : ""}</div>
                            }
                        </div>
                    </div>

                    {this.state.editingBar ? <div><div className="av-button" onClick={() => this.saveBarClicked()}>Save </div> <div className="av-button" onClick={() => this.hideBarModal()}>Cancel</div></div>
                        :
                        <div className="av-button" onClick={() => this.hideBarModal()}>OK</div>}

                    <div className="clearfix" />
                </div></div>}




            {( this.state.editingNote ) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div>

                            <div className="cell small-12 small-pad title">Enter The Note Text</div>

                    </div>

                    <div className="cell small-12 small-pad modal-section">

                        <div className="cell small-12">
                            <span>Text</span>

                            <textarea value={this.state.edit_note} onChange={this.handleChange} name="edit_note"  className="edit-field" />

                        </div>

                    </div>

                    <div><div className="av-button" onClick={() => this.saveNoteClicked()}>Save </div> <div className="av-button" onClick={() => this.hideNoteModal()}>Cancel</div></div>

                    <div className="clearfix" />
                </div></div>}


            {( this.state.selectedRemote !== undefined ) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div>
                        {this.state.selectedRemote === -1 ?
                            <div className="cell small-12 small-pad title">Enter The Details</div>
                            :
                            <div className="cell small-8 small-pad title">{this.state.remotes[this.state.selectedRemote].name} Details</div>}
                    </div>

                    <div className="cell small-12 small-pad modal-section">

                        <div className="cell small-12">
                            <span>{this.state.edit_remoteType} Name</span>
                            <input className="edit-field" type="text" name="edit_remotename" placeholder="Name" value={this.state.edit_remotename} onChange={this.handleChange}/>

                        </div>

                    </div>

                    <div><div className="av-button" onClick={() => this.saveRemoteClicked()}>Save </div> <div className="av-button" onClick={() => this.hideRemoteModal()}>Cancel</div></div>

                    <div className="clearfix" />
                </div></div>}




            {( this.state.selectedRemote !== undefined ) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div>
                        {this.state.selectedRemote === -1 ?
                            <div className="cell small-12 small-pad title">Enter The Details</div>
                            :
                            <div className="cell small-8 small-pad title">{this.state.remotes[this.state.selectedRemote].name} Details</div>}
                    </div>

                    <div className="cell small-12 small-pad modal-section">

                        <div className="cell small-12">
                            <span>{this.state.edit_remoteType} Name</span>
                            <input className="edit-field" type="text" name="edit_remotename" placeholder="Name" value={this.state.edit_remotename} onChange={this.handleChange}/>

                        </div>

                    </div>

                    <div><div className="av-button" onClick={() => this.saveRemoteClicked()}>Save </div> <div className="av-button" onClick={() => this.hideRemoteModal()}>Cancel</div></div>

                    <div className="clearfix" />
                </div></div>}


            {( this.state.editingCellarLinks !== undefined ) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div>
                        <div className="cell small-12 small-pad title">Select the remotes this cellar is linked to</div>

                    </div>

                    {this.state.cellarLinks ?

                        this.state.cellarLinks.map((remote, c) => {

                            return <div className="cell small-12 small-pad modal-section">

                                <div className="cell small-12">
                                    <span>
                                        <input type="checkbox" name={"cellarlink_" + c} checked={this.state.cellarLinks[c].linkID} onClick={this.handleChange}/> {remote.name}
                                    </span>
                                </div>

                            </div>

                        })

                        :
                        <div>Loading....</div>}

                    <div><div className="av-button" onClick={() => this.saveCellarLinksClicked()}>Save </div> <div className="av-button" onClick={() => this.hideCellarLinksModal()}>Cancel</div></div>

                    <div className="clearfix" />
                </div></div>}


            {( this.state.editingRemoteLinks !== undefined ) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div>
                        <div className="cell small-12 small-pad title">Select the bars this remote is linked to</div>

                    </div>

                    {this.state.remoteLinks ?

                        this.state.remoteLinks.map((bar, c) => {

                            return <div className="cell small-12 small-pad modal-section">

                                <div className="cell small-12">
                                    <span>
                                        <input type="checkbox" name={"remotelink_" + c} checked={this.state.remoteLinks[c].linkID} onClick={this.handleChange}/> {bar.barname}
                                    </span>
                                </div>

                            </div>

                        })

                        :
                        <div>Loading....</div>}

                    <div><div className="av-button" onClick={() => this.saveRemoteLinksClicked()}>Save </div> <div className="av-button" onClick={() => this.hideRemoteLinksModal()}>Cancel</div></div>

                    <div className="clearfix" />
                </div></div>}






            {( this.state.editingTap !== undefined ) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div className="cell small-12 small-pad title">
                        Tap {this.state.editingTapName} Details
                    </div>

                    <div className="cell small-12 small-pad modal-section">

                        <div className="cell small-12">
                            <span>Tap Number</span>
                            <input className="edit-field" type="text" name="editingTapName" placeholder="Tap No." value={this.state.editingTapName} onChange={this.handleChange}/>

                        </div>

                        <div className="cell small-12">
                            <span>Product</span>
                            <select className="edit-field" value={this.state.editingTapProduct} name="editingTapProduct" onChange={this.handleChange}>
                                {this.state.products.map((prod, c) => {

                                    return <option value={prod.id}>{prod.name}</option>

                                })}
                            </select>
                        </div>

                    </div>

                    <div><div className="av-button" onClick={() => this.saveTapClicked()}>Save </div> <div className="av-button" onClick={() => this.hideTapModal()}>Cancel</div></div>


                </div></div>}



            {(this.state.showingNote !== undefined) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div className="cell small-12 alert-title">
                        Added {this.state.showingNote.date} by {this.state.showingNote.name}
                    </div>
                    <div className="cell small-12">

                        {this.state.showingNote.text}

                    </div>
                    <div>
                        <div className="av-button" onClick={() => this.hideNote()}>OK</div>
                    </div>
                </div></div>}



            {(this.state.deletingBar !== undefined) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div className="cell small-12 alert-title">
                        Are you sure you want to delete this bar?
                    </div>

                    <div>
                        <div className="av-button" onClick={() => this.deleteBarClicked()}>OK</div>
                        <div className="av-button" onClick={() => this.cancelBarDelete()}>Cancel</div>
                    </div>
                </div></div>}



            {(this.state.deletingRemote !== undefined) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div className="cell small-12 alert-title">
                        Are you sure you want to delete this {this.state.deletingRemoteType}?
                    </div>

                    <div>
                        <div className="av-button" onClick={() => this.deleteRemoteClicked()}>OK</div>
                        <div className="av-button" onClick={() => this.cancelRemoteDelete()}>Cancel</div>
                    </div>
                </div></div>}


            {(this.state.deletingTap !== undefined) && <div className="modal">
                <div className="modal-box main-wrap">
                    <div className="cell small-12 alert-title">
                        Are you sure you want to delete this tap?
                    </div>

                    <div><div className="av-button" onClick={() => this.deleteTapClicked()}>OK</div>
                        <div className="av-button" onClick={() => this.cancelTapDelete()}>Cancel</div></div>
                </div></div>}
        </div>)
    }
}
export default VenueDetails;

