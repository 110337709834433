import React from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";


class VenueVisitTable extends React.Component {

    constructor(props) {
        super(props);

        var tdata = props.visits.map((row, c) => {

            var total = 0;
            var aCount = 0;
            var scoreTxt="";
            if (row.pre !== undefined) {
                row.pre.map((pre, c) => {
                    if (pre.preclean.length === 1)
                    {
                        total += parseInt(pre.precleanCount);
                    }
                    if (pre.preclean === "A")
                    {
                        aCount += parseInt(pre.precleanCount);
                    }

                })
                scoreTxt = Math.round(aCount/total * 100) + "%";
            }
            return <TableRow key={c}>
                <TableCell component="th" scope="row">{row.date}</TableCell>
                <TableCell>{scoreTxt}</TableCell>
                <TableCell>{row.issueCount}</TableCell>
                <TableCell><div onClick={() => this.props.didSelectVisit(row.id)} className="av-button" style={{padding:"2px 30px"}}>View</div></TableCell>

            </TableRow>

        })

        this.state = {
            page: 0,
            rowsPerPage: 15,
            tableData: tdata.length === 0 ? "No visit data found for this Venue" : tdata
        };
    }


    componentDidMount() {



    }





    render() {

        return (<div>


                <div className="cell small-12">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Pre-Clean A Score</TableCell>
                                    <TableCell>Number of Issues</TableCell>
                                    <TableCell>  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.tableData &&
                    <TablePagination
                        rowsPerPageOptions={[15, 30, 100]}
                        component={Paper}
                        count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>
        </div>)
    }

}

export default VenueVisitTable;

