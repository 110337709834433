import React from 'react'
import {BUTTON_MSG, CLIENT_TABS,} from '../../../resources/strings'
import ClientProduct from "../ClientProduct";
import Axios from "axios";
import {API} from "../../../api/APIClient";
import VenueDetails from "./VenueDetails"
import VenueVisitTable from "./VenueVisitTable";
import VisitDetails from  "../visit/VisitDetails"
import OverviewFilter2 from "../OverviewFilter2";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import MyPie from "../../common/MyPie";
import {Bar} from "react-chartjs-2";
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core";
import EditVisit from "../visit/EditVisit";
import FileDownload from "js-file-download";

class VenueLevelBody extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            venues: [],
            selectedVisit: undefined,
            filters: null,
            page: 0,
            resPage: 0,
            faultsPage: 0,
            rowsPerPage: 10,
        };
        //this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        this.updateData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.filters !== this.state.filters) {
            this.updateFilteredData();
        }
    }


    updateData = () => {
        this.getProducts();
        this.getVisits();
        this.updateFilteredData();
    }

    updateFilteredData = () => {
        this.getFaultsData();
        this.getResData();
        this.getBarChartData();
        this.getSummaryData();
    }


    getBarChartData() {
        const formData = new FormData();
        formData.append('userID', this.props.userid);
        formData.append('venueID', this.props.venue.id);

        let filters = Object.assign({},this.state.filters);
        filters.venues = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.venue.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.locations.getBarChartDataForVenue2, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        barChartData: res.data.chartData
                    })
                }
            })
    }

    getResData() {
        const formData = new FormData();
        formData.append('venueID', this.props.venue.id);
        formData.append('userID', this.props.userid);

        let filters = Object.assign({},this.state.filters);
        filters.venues = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.venue.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.locations.getResDetails2, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        barCleanResults: res.data.visitData
                    })
                }
            })
    }


    getSummaryData() {

        const formData = new FormData();
        formData.append('userID', this.props.user.id);
        formData.append('venueID', this.props.venue.id);

        let filters = Object.assign({},this.state.filters);
        filters.venues = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.venue.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.locations.getSummaryData2, formData)
            .then((res) => {

                if (res.data.success) {

                    var avgA = "-"
                    var sumTble = res.data.summaryData.chart.map((row, c) => {

                        if (row.title === 'A') {
                            avgA = row.percentage+"%"
                        }
                        return <TableRow key={c}>
                            <TableCell component="th" scope="row">{row.title}<span className={"dot_" + row.title}></span></TableCell>
                            <TableCell><span className={"dot_" + row.value} />{row.value}</TableCell>
                            <TableCell>{row.percentage}%</TableCell>
                        </TableRow>

                    })
                    sumTble.push(<TableRow key="totals">
                        <TableCell component="th" scope="row">Total</TableCell>
                        <TableCell>{res.data.summaryData.total}</TableCell>
                        <TableCell>100%</TableCell>
                    </TableRow>)



                    this.setState({
                        avgA: avgA,
                        summaryPieData: res.data.summaryData.chart.map((segment, c) => {

                            var parsedVal = parseFloat(segment.percentage, 10);
                            if (isNaN(parsedVal)) {
                                parsedVal = 0
                            }

                            return {title: segment.title, value: parsedVal, color: segment.color};

                        }),
                        summaryTableData: sumTble
                    })
                }
            })
    }


    didSelectVisit(visitID) {


        this.setState({
            selectedVisit: visitID,
            selectedVisitDetails: undefined

        });
        this.props.tabClickHandler(CLIENT_TABS.SINGLE_VISIT);
        this.getSelectedVisit(visitID)
    }

    getFaultsData() {
        const formData = new FormData();
        formData.append('userID', this.props.user.id);
        formData.append('venueID', this.props.venue.id);

        let filters = Object.assign({},this.state.filters);
        filters.venues = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.venue.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.locations.getFaultsTableData2, formData)
            .then((res) => {

                if (res.data.success) {

                    var faultTableData = res.data.tableData.map((row, c) => {


                        return <TableRow key={c} onClick={() => this.props.didSelectFault(row.id)} style={{cursor: "pointer"}} >
                            <TableCell component="th" scope="row">{row.date}</TableCell>
                            <TableCell>{row.issue}</TableCell>
                            <TableCell>{row.recurring === "1" ? "Yes" : "No"}</TableCell>
                            <TableCell className={"priority_" + (row.urgency || "").toLowerCase()}>{row.urgency}</TableCell>
                            <TableCell>{row.logged}</TableCell>
                            <TableCell>{row.actioned}</TableCell>

                        </TableRow>


                    })

                    this.setState({

                        faultsTableData: (faultTableData.length === 0) ? "No technical faults found" : faultTableData
                    })
                }
            })
    }


    getVisits() {
        const formData = new FormData();
        formData.append('venueID', this.props.venue.id);

        Axios
            .post(API.visits.getVisitsForVenue, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false)
                    {
                        this.setState({
                            visits: [], selectedVisit:-1
                        })
                    } else {
                        var selectedVisit = -1
                        if (res.data.data.length > 0)
                        {
                            selectedVisit = res.data.data[0]["id"]
                            if (this.props.gotoReportID) {
                                this.getSelectedVisit(this.props.gotoReportID)
                            } else {
                                this.getSelectedVisit(selectedVisit)
                            }

                        }
                        this.setState({
                            visits: res.data.data, selectedVisit: selectedVisit})

                    }


                }
            })
    }



    getSelectedVisit(visitID) {
        const formData = new FormData();
        formData.append('visitID', visitID);

        Axios
            .post(API.locations.getVisit, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.visit === false) {

                    } else {
                        this.setState({
                            selectedVisitDetails: res.data.visit
                        }, () => this.props.resetGotoReportID())
                    }

                }
            })
    }

    generatePDF() {

        window.open(API.BASE_URL + API.visits.getVisitPDF + "/" + this.state.selectedVisit, "_self")


    }


    getProducts() {
        const formData = new FormData();
        formData.append('userID', this.props.user.id);
        var filters = {

            venues: {
                isOn: true,
                    isRestrict: true,
                    isExclude: false,
                    list:  [{"id": this.props.venue.id}]
            }
        }
        formData.append('filters', JSON.stringify(filters));
       // formData.append('venueID', this.props.venue.id);

        Axios
            .post(API.products.productsForUser2, formData)
            .then((res) => {

                if (res.data.success) {

                    if (res.data.data === false) {

                    } else {
                        this.setState({
                            products: res.data.data.map((prod, c) => {

                                return <ClientProduct key={prod.id} prod={prod.id} userid={this.props.user.id}
                                                      venue={this.props.venue} name={prod.name} showBarChart={true} didSelectVenue={(clientID, clientName, venueID, venueName) => { this.props.didSelectVenue(venueID, venueName) }} />;
                            })
                        })
                    }

                }
            })
    }

    showFaultsPDF() {

        window.open(API.BASE_URL + API.locations.showFaultsPDF + "/" + this.props.user.id, "_self")

    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.buttonMessage === BUTTON_MSG.PDF_REPORT){
            this.generatePDF()
            this.props.buttonMessageHandler(undefined)
        }


        if (nextProps.buttonMessage === BUTTON_MSG.EDIT_REPORT && this.props.buttonMessage !== BUTTON_MSG.SAVE_REPORT && this.state.selectedVisitDetails) {

            this.setState({
                edited_report: JSON.parse(JSON.stringify(this.state.selectedVisitDetails))
            })
        }
        if (nextProps.buttonMessage === undefined){
            if (this.state.edited_report) {
                let repID = this.state.edited_report.id
                this.setState({
                    edited_report: undefined
                },this.getSelectedVisit(repID))            }

        }
    }




    render() {
        const theme = createMuiTheme({
            overrides: {
                MuiTableCell: {
                    root: {  //This can be referred from Material UI API documentation.
                        padding: '10px 8px'},
                }
            },
        });

        switch (this.props.currentTab) {

            case CLIENT_TABS.SINGLE_VISIT :

                if (this.state.edited_report) {
                    return  (

                        <div>
                        <div className="cell small-12 no-pad">

                            <EditVisit visit={this.state.edited_report} />

                        </div>
                    </div>
                    );

                } else if (this.state.selectedVisitDetails) {
                        return  (
                            <div>
                                <div className="cell small-12 no-pad">

                                    <VisitDetails visit={this.state.selectedVisitDetails}  didSelectFault={this.props.didSelectFault} />

                                </div>
                            </div>

                        );
                    } else {
                        if (this.state.selectedVisit === -1)
                        {
                            return  (
                                <div>
                                    <div className="cell small-12">
                                        No visits found for this venue.
                                    </div>
                                </div>

                            );
                        } else {
                            return  (
                                <div>
                                    <div className="cell small-12 ">
                                        Loading...
                                    </div>
                                </div>

                            );
                        }

                    }



            case CLIENT_TABS.TRENDS :
                return (
                    <div className="clientsbody trend-wrap">
                        <OverviewFilter2 filters={this.state.filters}
                                         hideFaults={true}  hideClients={true}
                                         showBars={true} hideTechnicians={true}
                                         hideVenues={true} hideLocation={true} user={this.props.user}
                                         venueIDS={[{id: this.props.venue.id}]} onChange={(filters) => this.setState({filters: filters})}/>

                        <div className="cell small-12 small-pad"/>

                        <div className="cell small-12 container trend-summary">
                            <div className="title">Trends over time Information</div>
                            <p>Use this section to compare visits for this venue over the time and parameters set within the filters</p>
                            <div className="cell small-6 no-pad">
                                <div className="subtitle">Number of visits</div>
                                <div className="result">{this.state.barCleanResults ?
                                this.state.barCleanResults.visits.length : '-'}</div>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="subtitle">Average Pre-Clean A Score</div>
                                <div className="result">{this.state.avgA === undefined ? '-' : this.state.avgA}</div>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="subtitle">Number of Issues</div>
                                <div className="result">{(this.state.faultsTableData === undefined || this.state.faultsTableData === "No technical faults found" )? '-' : this.state.faultsTableData.length}</div>
                            </div>




                            
                        </div>
                        <div className="cell small-12 small-pad"/>

                        <div className="cell small-12 container trend-summary-pro-clean">
                            <div className="cell small-12 no-pad">
                                <div className="title">Summary Of Results For These Parameters</div>
                            </div>
                            <div className="cell small-12 small-pad" />
                            <div className="cell small-7 no-pad">
                                <TableContainer component={Paper}>
                                    <Table  size="small" aria-label="clean results">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Score</TableCell>
                                                <TableCell>Number Of Lines By Result</TableCell>
                                                <TableCell>Total Percentage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.summaryTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.summaryTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                        </TableBody>
                                    </Table>

                                </TableContainer>

                            </div>
                            
                            <div className="cell small-5 pie-wrap">
                                {this.state.summaryPieData === undefined ? <div>Loading...</div> :
                                    <div className="pie-view">
                                        <MyPie data={this.state.summaryPieData} />
                                    </div>
                                }
                            </div>
                            

                        </div>


                        <div className="cell small-12 small-pad" />

                        <div className="cell small-12 container">

                            <div className="title">  Results, Details & Temperatures</div>

                            <div className="scroller">
                                <div className="table">
                                    <div className="date">&nbsp;</div>
                                    <TableContainer component={Paper}>
                                        <ThemeProvider theme={theme}>

                                            <Table  size="small" padding="none" aria-label="clean results">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Bar<br/>&nbsp;</TableCell>
                                                        <TableCell>Line&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>&nbsp;</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.barCleanResults === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.barCleanResults.barsAndLines.map((line, c) => {

                                                        return <TableRow key={c}>
                                                            <TableCell component="th" scope="row">{line.barname}</TableCell>
                                                            <TableCell>{line.name}</TableCell>
                                                        </TableRow>
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </ThemeProvider>
                                    </TableContainer>
                                </div>
                                {this.state.barCleanResults &&
                                this.state.barCleanResults.visits.map((visit, c) => {

                                    return <div className="table">
                                        <div className="date">Date of Visit - {visit.date}</div>
                                        <TableContainer component={Paper}>
                                            <ThemeProvider theme={theme}>

                                                <Table  size="small" padding="none" aria-label="clean results">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Pre-Clean<br/>result</TableCell>
                                                            <TableCell>pH Test<br/>Performed?</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.barCleanResults === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.barCleanResults.barsAndLines.map((line, c) => {

                                                            return <TableRow key={c}>
                                                                <TableCell component="th" scope="row">{visit.results[line.barID+"_"+line.lineID] ? visit.results[line.barID+"_"+line.lineID].preclean : " - "}</TableCell>
                                                                <TableCell>{visit.results[line.barID+"_"+line.lineID] ?
                                                                    (visit.results[line.barID+"_"+line.lineID].phtest == 1 && visit.results[line.barID+"_"+line.lineID].preclean.length > 0) ? "Yes" : "-"
                                                                    : " - "}</TableCell>
                                                            </TableRow>
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </ThemeProvider>
                                        </TableContainer>

                                    </div>
                                })}
                            </div>


                            <div className="title">  Temperatures Mapped Against Pre-Clean Condition</div>


                            <div className="scroller">
                                <div className="table">
                                    <div className="date">&nbsp;</div>
                                    <TableContainer component={Paper}>
                                        <ThemeProvider theme={theme}>

                                            <Table  size="small" padding="none" aria-label="clean results">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.barCleanResults === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.barCleanResults.remotesAndCellars.map((line, c) => {

                                                        return <TableRow key={c}>
                                                            <TableCell component="th" scope="row">{line.remotetype}</TableCell>
                                                            <TableCell>{line.remotename}</TableCell>
                                                        </TableRow>
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </ThemeProvider>
                                    </TableContainer>
                                </div>
                                {this.state.barCleanResults &&
                                this.state.barCleanResults.visits.map((visit, c) => {

                                    return <div className="table">
                                        <div className="date">Date of Visit - {visit.date}</div>
                                        <TableContainer component={Paper}>
                                            <ThemeProvider theme={theme}>

                                                <Table  size="small" padding="none" aria-label="clean results">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Temperature</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.barCleanResults === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.barCleanResults.remotesAndCellars.map((line, c) => {
                                                            let rowNo= this.state.barCleanResults.remotesAndCellars.length - c
                                                            let guess = visit.results["ul_"+rowNo] ? visit.results["ul_"+rowNo].temp : "-"
                                                            return <TableRow key={c}>
                                                                <TableCell component="th" scope="row">{visit.results["temp_"+line.remoteID] ? visit.results["temp_"+line.remoteID].temp : guess}</TableCell>
                                                            </TableRow>
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </ThemeProvider>
                                        </TableContainer>

                                    </div>
                                })}
                            </div>

                        </div>

                        <div className="cell small-12 small-pad" />

                        {this.state.barChartData &&
                        <div className="cell small-12 container">
                            <div className="title" style={{textAlign: "center"}}></div>
                            <Bar
                                data={this.state.barChartData}
                                height={400}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            id: "barx"
                                        },{
                                            stacked: false,
                                            display: false,
                                            id: "linex"
                                        }],
                                        yAxes: [{
                                            stacked: true,
                                            position: "right",
                                            scaleLabel: {
                                                display: true,
                                                labelString: "%"
                                            },
                                            id: "bary"

                                        },{
                                            stacked: false,
                                            position: "left",
                                            ticks : {
                                                suggestedMax: 30,
                                                suggestedMin: -4
                                            },
                                            scaleLabel: {
                                                display: true,
                                                labelString: "°C"
                                            },
                                            id: "liney"
                                        }]
                                    }
                                }}
                            />
                        </div>}

                        <div className="cell small-12 small-pad" />
                        <div className="cell small-12 container">
                            <div className="title"> Technical Fault Reports</div>
                            <div style={{float:"right"}} className="av-button" onClick={() => this.showFaultsPDF()}>View PDF</div>


                            <div className="cell small-12" />
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="clean results">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Recurring?</TableCell>
                                            <TableCell>Priority</TableCell>
                                            <TableCell>Logged?</TableCell>
                                            <TableCell>Resolved?</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(this.state.faultsTableData === undefined || this.state.faultsTableData === "No technical faults found" ) ? <TableRow><TableCell>{this.state.faultsTableData === undefined ?  "Loading..." :this.state.faultsTableData }</TableCell></TableRow> : this.state.faultsTableData.slice(this.state.faultsPage * this.state.rowsPerPage, this.state.faultsPage * this.state.rowsPerPage + this.state.rowsPerPage)}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                            {this.state.faultsTableData &&
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component={Paper}
                                count={(this.state.faultsTableData === undefined || this.state.faultsTableData === "No technical faults found" )? 0 : this.state.faultsTableData.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.faultsPage}
                                onChangePage={(event, newPage) => {

                                    this.setState({faultsPage: newPage})
                                }}
                                onChangeRowsPerPage={(event) => {
                                    this.setState({faultsPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                }}
                            />}


                        </div>
                    </div>

                        );
            case CLIENT_TABS.VISITS :
                return (
                    <div>
                        <div className="cell small-12 no-pad">

                            <div className="clientsvenues" >
                                <div className="title" style={{marginLeft:"10px"}}>List of Visits and Results</div>

                            </div>
                        </div>
                        <div className="cell small-12 no-pad">
                            <VenueVisitTable venue={this.props.venue}
                                             visits={this.state.visits}
                                             didSelectVisit={(visitID) => this.didSelectVisit(visitID)}
                            />
                        </div>

                    </div>

                );
            case CLIENT_TABS.PRODUCTS :
                return (
                    <div className="cell small-12 product-list-wrap">

                        <div className="cell small-12 no-pad">

                            <div className="clientsvenues" >
                                <div className="title" style={{marginLeft:"10px"}}>List of Products used by this venue</div>

                            </div>
                        </div>
                        {this.state.products}
                        <div className="cell small-12"/>
                    </div>

                );
            case CLIENT_TABS.VENUE_DETAILS :
                return (
                    <div>
                        <VenueDetails venue={this.props.venue} client={this.props.client} user={this.props.user}/>


                    </div>

                );
            default :
                return (<div>unknown screen</div>);

        }
    }





}
export default VenueLevelBody;
