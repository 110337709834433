import React from 'react'
import {CLIENT_TABS, FILTERS} from '../../resources/strings'
import ClientProduct from "./ClientProduct";
import Axios from "axios";
import {API} from "../../api/APIClient";
import Venue from "./venues/Venue";
import OverviewFilter2 from "./OverviewFilter2";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import MyPie from "../common/MyPie";
import {Bar} from "react-chartjs-2";
import VenueSimpleLatest from "./VenueSimpleLatest";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";


class ClientLevelBody extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            venues: [],
            filters: null,
            page: 0,
            rowsPerPage: 10,
            wastagePage:0,
            faultsPage:0,
            show: "More details"

        };
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        var matchingVenues = []
        for (var c = 0; c < this.state.venueList.length; c++) {
            if (this.state.venueList[c].locationname.toLowerCase().includes(value.toLowerCase())){
                matchingVenues.push(this.state.venueList[c])
            }
        }

        this.setState({ [name]: value, matchingVenues: matchingVenues.map((venue, c) => {

            return <Venue key={venue.id} venueID={venue.id} userid={this.props.user.id}
                          locationName={venue.locationname}
                          didSelectVenue={this.props.didSelectVenue}
            />;
        })})
    }

    componentDidMount() {
        this.updateData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.filters !== this.state.filters) {
            this.updateData();
        }
    }

    updateData = () => {
        this.getVisitCount();
        this.getProducts();
        this.getVenues();
        this.getSummaryData();
        this.getWastageData();
        this.getFaultsData();
        this.getBarChartData();
        this.getGroupChartData();
    }

    getGroupChartData() {
        const formData = new FormData();
        formData.append('clientID', this.props.client.id);

        let filters = Object.assign({},this.state.filters);
        filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.client.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.getGroupBarChartData, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        groupChartData: res.data.groupChartData
                    })
                }
            })
    }

    getVisitCount() {
        const formData = new FormData();

        let filters = Object.assign({},this.state.filters);
        filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.client.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.getVisitCount, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        visitCount: res.data.visitCount
                    })
                }
            })
    }
    getFaultsData() {
        const formData = new FormData();
        formData.append('clientID', this.props.client.id);

        let filters = Object.assign({},this.state.filters);
        filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.client.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.getFaultsTableData2, formData)
            .then((res) => {

                if (res.data.success) {

                    var totFalt = 0
                    this.setState({

                        faultsTableData: res.data.tableData.map((row, c) => {

                            totFalt = totFalt + parseInt(row.low)+parseInt(row.standard)+parseInt(row.high)
                            return <TableRow key={c} >
                                <TableCell component="th" scope="row"><span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.props.didSelectVenue(row.id, row.locationname)}>{row.locationname}</span></TableCell>
                                <TableCell>{row.low}</TableCell>
                                <TableCell>{row.standard}</TableCell>
                                <TableCell>{row.high}</TableCell>
                                <TableCell>{row.recurring}</TableCell>
                                <TableCell>{parseInt(row.low)+parseInt(row.standard)+parseInt(row.high)}</TableCell>
                            </TableRow>


                        }),
                        totalFaults:totFalt
                    })
                }
            })
    }
    getWastageData(){
        const formData = new FormData();
        formData.append('clientID', this.props.client.id);

        let filters = Object.assign({},this.state.filters);
        filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.client.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.getWastageTableData2, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({

                        wastageTableData: res.data.tableData.map((row, c) => {

                            var parsedVal = parseFloat(row.wastage, 10);
                            if (isNaN(parsedVal)) {
                                parsedVal = 0
                            }

                                return <TableRow key={c}>
                                    <TableCell><span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.props.didSelectVenue(row.id, row.locationname)}>{row.locationname}</span></TableCell>
                                    <TableCell>{parsedVal.toFixed(1)}</TableCell>
                                </TableRow>


                        })
                    })
                }
            })
    }



    getSummaryData() {

        const formData = new FormData();

        let filters = Object.assign({},this.state.filters);
        filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.client.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.getSummaryData2, formData)
            .then((res) => {

                if (res.data.success) {
                    var avgA = "-"

                    var sumTble = res.data.summaryData.chart.map((row, c) => {
                        if (row.title === 'A') {
                            avgA = row.percentage+"%"
                        }
                        return <TableRow key={c}>
                            <TableCell component="th" scope="row">{row.title}</TableCell>
                            <TableCell><span className={"dot_" + row.value} />{row.value}<span className={"dot_" + row.title}></span></TableCell>
                            <TableCell>{row.percentage}%</TableCell>
                        </TableRow>

                    })
                    sumTble.push(<TableRow key="totals">
                        <TableCell component="th" scope="row">Total</TableCell>
                        <TableCell>{res.data.summaryData.total}</TableCell>
                        <TableCell>100%</TableCell>
                    </TableRow>)

                    this.setState({
                        avgA: avgA,
                        clientCount: res.data.summaryData.numClients,
                        venueCount: res.data.summaryData.numVenues,
                        summaryPieData: res.data.summaryData.chart.map((segment, c) => {

                            var parsedVal = parseFloat(segment.percentage, 10);
                            if (isNaN(parsedVal)) {
                                parsedVal = 0
                            }

                            return {title: segment.title, value: parsedVal, color: segment.color};

                        }),
                        summaryTableData:sumTble
                    })
                }
            })
    }

    showWastageMessage()
    {
        this.setState({showWastageMessage: true})
    }

    hideWastageMessage(){
        this.setState({showWastageMessage: false})
    }

    getSelectedClient() {
        const formData = new FormData();
        formData.append('clientID', this.props.client.id);

        Axios
            .post(API.client.getClientDetails, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        selectedClient: res.data.data
                    })
                }
            })
    }


    getVenues() {
        const formData = new FormData();

        let filters = Object.assign({},this.state.filters);
        filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.client.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.locations.venuesForUserAndClient, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        venueList: res.data.data,
                        venues: res.data.data.map((venue, c) => {

                            return <Venue key={venue.id} venueID={venue.id} userid={this.props.user.id}
                                          locationName={venue.locationname}
                                           didSelectVenue={this.props.didSelectVenue}
                            />;
                        })
                    })
                }
            })
    }


    setShow(val) {
        this.setState({show: val})
    }

    getBarChartData() {
        const formData = new FormData();

        formData.append('userID', this.props.userid);
        formData.append('clientID', this.props.client.id);

        let filters = Object.assign({},this.state.filters);
        filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.client.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.clients.getBarChartDataForClient, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        barChartData: res.data.chartData
                    })
                }
            })
    }



    getProducts() {
        const formData = new FormData();
        formData.append('userID', this.props.user.id);
        formData.append('clientID', this.props.client.id);

        let filters = Object.assign({},this.state.filters);
        filters.clients = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.client.id}]};
        formData.append("filters", JSON.stringify(filters));

        Axios
            .post(API.products.productsForUser2, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        products: res.data.data.map((prod, c) => {

                            return <ClientProduct key={prod.id} prod={prod.id} client={this.props.client} userid={this.props.user.id}
                                                  name={prod.name} showBarChart={true} didSelectVenue={(clientID, clientName, venueID, venueName) => { this.props.didSelectVenue(venueID, venueName) }} />;
                        })
                    })
                }
            })
    }


    render() {

        const theme = createMuiTheme({
            overrides: {
                MuiTableCell: {
                    root: {  //This can be referred from Material UI API documentation.
                        padding: '10px 8px'},
                }
            },
        });


        //                            <div className="search"><input type="text" placeholder="Search"/></div>  PUT THIS BACK FOR SEARCH
        switch (this.props.currentTab) {

            case CLIENT_TABS.OVERVIEW :
                return (
                    <div className="clientsbody">
                        <OverviewFilter2 filters={this.state.filters} hideClients={true} hideFaults={true} clientIDS={[{id: this.props.client.id}]} user={this.props.user} onChange={(filters) => this.setState({filters: filters})}/>

                        <div className="cell small-12 small-pad"/>

                        <div className="cell small-12 container">
                            <div className="title"> Summary Of Latest Results</div>
                            <div className="cell small-6 summary-wrap">
                                <div className="cell small-12 summary-stats"><span className="loz">Number of Venues <span className="loz-stat">{this.state.venueCount}</span></span></div>
                                {/* <div className="cell small-12"/> REMOVED BUT MAY NEED */}
                                <TableContainer component={Paper}>
                                    <ThemeProvider theme={theme}>

                                    <Table  size="small" aria-label="clean results">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Score</TableCell>
                                                <TableCell>Number Of Lines By Result</TableCell>
                                                <TableCell>Total Percentage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.summaryTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.summaryTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                        </TableBody>
                                    </Table>
                                    </ThemeProvider>
                                </TableContainer>


                            </div>
                            {/* <div className="cell small-1" /> removed but may need */}
                            <div className="cell small-6 pie-wrap">
                                {this.state.summaryPieData === undefined ? <div>Loading...</div> :
                                    <div className="title pie-view" style={{textAlign: "center"}}>

                                        <MyPie data={this.state.summaryPieData} />
                                    </div>
                                }
                            </div>
                            {/* <div className="cell small-1"/> removed but may need */}

                        </div>




                        <div className="cell small-12 small-pad" />
                        <div className="cell small-12 container">
                            <div className="cell small-5 small-pad">
                                <div className="title">Total Venue Wastage From Last Visit In Pints<div className="tooltip"><span className="tooltip-reveal">Wastage is a measure of the amount of beer (pints) held in lines that has to be discarded before a ProClean.</span></div></div>

                                <div className="cell small-12" />
                                <TableContainer component={Paper}>
                                    <Table  size="small" aria-label="clean results">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Venue</TableCell>
                                                <TableCell>Wastage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.wastageTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.wastageTableData.slice(this.state.wastagePage * this.state.rowsPerPage, this.state.wastagePage * this.state.rowsPerPage + this.state.rowsPerPage)}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                                {this.state.wastageTableData &&
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.wastageTableData === undefined ? 0 : this.state.wastageTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.wastagePage}
                                    onChangePage={(event, newPage) => {

                                        this.setState({wastagePage: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({wastagePage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />}
                            </div>
                            <div className="cell small-1 small-pad"></div>

                            <div className="cell small-6 small-pad">
                                <div className="title"> Total Technical Faults</div>


                                <div className="cell small-12" />
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="clean results">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Venue</TableCell>
                                                <TableCell>Low</TableCell>
                                                <TableCell>Standard</TableCell>
                                                <TableCell>High</TableCell>
                                                <TableCell>Recurring</TableCell>
                                                <TableCell>Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.faultsTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.faultsTableData.slice(this.state.faultsPage * this.state.rowsPerPage, this.state.faultsPage * this.state.rowsPerPage + this.state.rowsPerPage)}
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                                {this.state.faultsTableData &&
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component={Paper}
                                    count={this.state.faultsTableData === undefined ? 0 : this.state.faultsTableData.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.faultsPage}
                                    onChangePage={(event, newPage) => {

                                        this.setState({faultsPage: newPage})
                                    }}
                                    onChangeRowsPerPage={(event) => {
                                        this.setState({faultsPage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                                    }}
                                />}

                            </div>
                        </div>

                        <div className="cell small-12 small-pad" />
                        <div className="title cell small-12">Latest Results From Each Venue</div>
                        <div className="cell small-12">Show: <div className="segmented"><span onClick={()=>this.setShow("More details")} className={this.state.show === "More details"? 'av-button' : 'off-button' }>More Details</span>
                            <span onClick={()=>this.setShow("Chart")}  className={this.state.show === "Chart"? 'av-button' : 'off-button' }>Chart</span></div></div>

                        <div className="cell small-12 small-latest-wrap">
                            {this.state.venueList && this.state.venueList.map((venue, c) => {

                                console.log("venueID: " + venue.id);
                                console.log(venue);

                                return (
                                    <div className="cell small-4" >
                                        <VenueSimpleLatest
                                            filters={this.state.filters}
                                            show={this.state.show}
                                            key={venue.id}
                                            venueID={venue.id}
                                            userid={this.props.user.id}
                                            locationName={venue.locationname}
                                            didSelectVenue={(id, name) => { console.log(id); this.props.didSelectVenue(id, name) }}
                                        />
                                    </div>);
                            })}
                        </div>
                        <div className="cell small-12 small-pad" />

                        {this.state.barChartData &&
                        <div className="cell small-12 container">
                            <div className="title">Graph Of Results From Each Venue</div>
                            <Bar
                                data={this.state.barChartData}
                                height={270}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        xAxes: [{
                                            stacked: true
                                        }],
                                        yAxes: [{
                                            stacked: true,
                                            ticks: {
                                                max: 100,
                                            }
                                        }]
                                    }
                                }}
                            />
                        </div>}

                    </div>

                );
            case CLIENT_TABS.VENUE_LIST :
                return (
                    <div className="clientsbody">
                        <OverviewFilter2 filters={this.state.filters} hideClients={true} hideFaults={true}  clientIDS={[{id: this.props.client.id}]} user={this.props.user} onChange={(filters) => this.setState({filters: filters})}/>

                        <div className="cell small-12 small-pad"/>
                        <div className="venues-list">

                            <div className="clientsvenues">
                                <div className="search"><input type="text" placeholder="Search"  name="venueSearch" value={this.state.venueSearch} onChange={this.handleChange}/></div>

                                <div className="title">Venues</div>
                            </div>
                            <div  className="expandbox client-expandbox" >
                                <div className="cell small-12 location locationhead small-pad">
                                    <div className="cell small-1 no-pad" />
                                    <div className="cell small-1 no-pad">ID</div>
                                    <div className="cell small-10 no-pad">
                                        Venue
                                    </div>
                                </div>
                                {this.state.venueSearch ? this.state.matchingVenues : this.state.venues}
                            </div>

                            <div className="cell small-12"/>
                        </div>
                    </div>
                );
            case CLIENT_TABS.PRODUCTS :
                return (
                    <div className="clientsbody">
                        <OverviewFilter2  filters={this.state.filters} hideClients={true} hideFaults={true}  clientIDS={[{id: this.props.client.id}]} user={this.props.user} onChange={(filters) => this.setState({filters: filters})}/>

                        <div className="cell small-12 small-pad"/>

                        <div className="product-list-wrap">

                            <div className="cell small-12 no-pad">

                                <div className="clientsvenues" >
                                    <div className="title" style={{marginLeft:"10px"}}>List of Products used by this client</div>

                                </div>
                            </div>

                            {this.state.products}

                        </div>

                        <div className="cell small-12"/>

                    </div>

                );
            case CLIENT_TABS.GROUP_TRENDS :
                return (
                    <div className="clientsbody trend-wrap">
                        <OverviewFilter2  filters={this.state.filters}
                                          hideClients={true} hideFaults={true}
                                          showBars={false} hideTechnicians={true}
                                          hideVenues={true} hideLocation={true}  hideProducts={true}
                                          clientIDS={[{id: this.props.client.id}]}
                                          user={this.props.user}
                                          onChange={(filters) => this.setState({filters: filters})}/>

                        <div className="cell small-12 small-pad"/>

                        <div className="cell small-12 container trend-summary">
                            <div className="title">Group trends over time</div>
                            <p>Use this section to compare visits for this group over the time and parameters set within the filters</p>
                            <div className="cell small-6 no-pad">
                                <div className="subtitle">Number of visits</div>
                                <div className="result">{this.state.visitCount ?
                                    this.state.visitCount : '-'}</div>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="subtitle">Average Pre-Clean A Score</div>
                                <div className="result">{this.state.avgA === undefined ? '-' : this.state.avgA}</div>
                            </div>
                            <div className="cell small-6 no-pad">
                                <div className="subtitle">Number of Issues</div>
                                <div className="result">{this.state.totalFaults ? this.state.totalFaults : '-'}</div>
                            </div>

                        </div>
                        <div className="cell small-12 small-pad"/>

                        <div className="cell small-12 container trend-summary-pro-clean">
                            <div className="cell small-12 no-pad">
                                <div className="title">Summary Of Group Results For These Parameters</div>
                            </div>
                            <div className="cell small-12 small-pad" />
                            <div className="cell small-7 no-pad">
                                <TableContainer component={Paper}>
                                    <Table  size="small" aria-label="clean results">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Score</TableCell>
                                                <TableCell>Number Of Lines By Result</TableCell>
                                                <TableCell>Total Percentage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.summaryTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.summaryTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                        </TableBody>
                                    </Table>

                                </TableContainer>

                            </div>

                            <div className="cell small-5 pie-wrap">
                                {this.state.summaryPieData === undefined ? <div>Loading...</div> :
                                    <div className="pie-view">
                                        <MyPie data={this.state.summaryPieData} />
                                    </div>
                                }
                            </div>


                        </div>

                        <div className="cell small-12 small-pad" />

                        {this.state.groupChartData &&
                        <div className="cell small-12 container">
                            <div className="cell small-12 no-pad">
                                <div className="title">Summarised Group Results</div>
                            </div>
                            <div className="title" style={{textAlign: "center"}}></div>
                            <Bar
                                data={this.state.groupChartData}
                                height={400}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        xAxes: [{
                                            stacked: true,
                                            id: "barx"
                                        }],
                                        yAxes: [{
                                            stacked: true,
                                            scaleLabel: {
                                                display: true,
                                                labelString: "%"
                                            },
                                            id: "bary"

                                        }]
                                    }
                                }}
                            />
                        </div>}
                        <div className="cell small-12 small-pad" />

                        <div className="cell small-12"/>

                    </div>

                );
            default :
                return (<div>unknown screen</div>);

        }
    }





}
export default ClientLevelBody;
