import React from "react";
import Axios from "axios";
import {API} from "../../api/APIClient";
import Paper from "@material-ui/core/Paper/Paper";
import {ThemeProvider} from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {createMuiTheme} from "@material-ui/core";
import MyPie from "../common/MyPie";


class VenueSimpleLatest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lastResult: undefined,
            page: 0,
            rowsPerPage: 10,
        };
        //this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            this.getLastVisit();
        }
    }

    componentDidMount() {

        this.getLastVisit()
    }

    getLastVisit() {
        const formData = new FormData();
        formData.append('userID', this.props.userid);
        formData.append('venueID', this.props.venueID);

        let filters = Object.assign({},this.props.filters);
        filters.venues = {isOn: true, isRestrict: true, isExclude: false, list: [{id: this.props.venueID}]}
        formData.append('filters', JSON.stringify(filters));

        Axios
            .post(API.locations.latestVisitSimple, formData)
            .then((res) => {


                if (res.data.success) {

                    var sumTble = res.data.visit.summaryData.map((row, c) => {

                        var changeClass = ""
                        if (row.change.length > 0) {

                            if (row.change.charAt(0) === "-") {
                                changeClass = "change_none"
                            } else if (row.change.charAt(0) === "^") {
                                changeClass = "change_up"

                            } else if (row.change.charAt(0) === "v") {
                                changeClass = "change_down"

                            }
                        }

                        return <TableRow key={c}>
                            <TableCell component="th" scope="row">{row.title}<span className={"dot_" + row.title}></span></TableCell>
                            <TableCell><span className={"dot_" + row.value} />{row.value}</TableCell>
                            <TableCell>{row.percentage}%</TableCell>
                            <TableCell><span className={changeClass} />{row.change.substr(1)}</TableCell>
                        </TableRow>

                    })
                    var sumTbleMIN = res.data.visit.summaryData.map((row, c) => {

                        return <TableRow key={c}>
                            <TableCell component="th" scope="row">{row.title}<span className={"dot_" + row.title}></span></TableCell>
                            <TableCell><span className={"dot_" + row.value} />{row.value}</TableCell>
                        </TableRow>

                    })
                    sumTble.push(<TableRow key="totals">
                        <TableCell component="th" scope="row">Total</TableCell>
                        <TableCell>{res.data.visit.total}</TableCell>
                        <TableCell>100%</TableCell>
                        <TableCell></TableCell>
                    </TableRow>)

                    sumTbleMIN.push(<TableRow key="totals">
                        <TableCell component="th" scope="row">Total</TableCell>
                        <TableCell>{res.data.visit.total}</TableCell>
                    </TableRow>)

                    this.setState({
                        lastResult: res.data.visit,
                        summaryPieData: res.data.visit.summaryData.map((segment, c) => {

                            var parsedVal = parseFloat(segment.percentage, 10);
                            if (isNaN(parsedVal)) {
                                parsedVal = 0
                            }

                            return {title: segment.title, value: parsedVal, color: segment.color};

                        }),
                        summaryTableData:sumTble,
                        summaryTableDataMIN:sumTbleMIN
                    })
                }

            })
    }

    render() {
        const theme = createMuiTheme({
            overrides: {
                MuiTableCell: {
                    root: {  //This can be referred from Material UI API documentation.
                        padding: '10px 8px'},
                }
            },
        });
        if (this.state.lastResult) {
            if (this.props.show === "More details"){
                return(<div className="latestbox">
                    <div className="cell large-6 small-12 small-pad title">
                        <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.props.didSelectVenue(this.props.venueID, this.props.locationName)}>{this.props.locationName}</span>
                    </div>
                    <div className="cell large-6 small-12 small-pad loz">Last Visit: {this.state.lastResult.date}</div>

                    <TableContainer component={Paper}>
                        <ThemeProvider theme={theme}>

                            <Table  size="small" padding="none" aria-label="clean results">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Score</TableCell>
                                        <TableCell>Number Of<br/>Line Results</TableCell>
                                        <TableCell>Total<br/>Percentage</TableCell>
                                        <TableCell>Change Since<br/>Last Visit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.summaryTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.summaryTableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                </TableBody>
                            </Table>
                        </ThemeProvider>
                    </TableContainer>



                </div>)
            } else {
                return(<div className="latestbox">
                    <div className="cell large-6 small-12 small-pad title" >
                        <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.props.didSelectVenue(this.props.venueID, this.props.locationName)}>{this.props.locationName}</span>
                    </div>
                    <div className="cell large-6 small-12 small-pad loz">Last Visit: {this.state.lastResult.date}</div>

                    <div className="cell small-7 no-pad">
                    <TableContainer component={Paper}>
                        <ThemeProvider theme={theme}>

                            <Table  size="small" padding="none" aria-label="clean results">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Score</TableCell>
                                        <TableCell>Number Of<br/>Line Results</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.summaryTableDataMIN === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.summaryTableDataMIN.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                                </TableBody>
                            </Table>
                        </ThemeProvider>
                    </TableContainer>
                    </div><div className="cell small-5 no-pad ">
                    <div className="title" style={{textAlign: "center"}}>

                        <MyPie fontsize="big" data={this.state.summaryPieData} />
                    </div>

                </div>


                </div>)
            }



        }  else {
            return(<div>
                <div className="cell small-12">Loading...</div>
            </div>)
        }


    }
}

export default VenueSimpleLatest