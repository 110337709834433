import React from 'react';
import DashBoard from "./components/DashBoard"
import './App.css';
import './assets/css/main.css';

function App() {
  return (
      <div className="App">
        <DashBoard />
      </div>
  );
}

export default App;
