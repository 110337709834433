import React from "react";
import OverviewFilter from "../OverviewFilter";
import {BUTTON_MSG} from "../../../resources/strings";

class FaultsBody extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
        //this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {

    }

    render() {
        if (this.props.faultDetails) {
            return <div className="cell small-12 user_deets details-wrap small-pad">

            <div className="deets_sect">
                <div className="cell small-12 small-pad">
                    <div className="title">Venue Details</div>
                </div>

                <div className="cell small-12 small-pad">
                    <div className="deet">
                        <span>Venue Name</span>
                        <div className="field">{this.props.faultDetails.locationname}</div>
                    </div>
                    <div className="deet">
                        <span>Client Name</span>
                        <div className="field">{this.props.faultDetails.clientname}</div>
                    </div>
                </div>

                <div className="cell small-6 small-pad">
                    <div className="deet">
                        <span>Address</span>
                        <div className="field">{this.props.faultDetails.address}</div>
                        <div className="field">{this.props.faultDetails.address2}</div>
                        <div className="field">{this.props.faultDetails.town}</div>
                        <div className="field">{this.props.faultDetails.county}</div>
                        <div className="field">{this.props.faultDetails.postcode}</div>
                    </div>
                </div>

                <div className="cell small-6 small-pad">
                    <div className="deet">
                        <span>Date Reported</span>
                        <div className="field">{this.props.faultDetails.date}</div>
                    </div>
                    <div className="deet">
                        <span>TSP Name</span>
                        <div className="field">{this.props.faultDetails.tsp_name}</div>
                    </div>
                    <div className="deet">
                        <span>TSP Phone</span>
                        <div className="field">{this.props.faultDetails.tsp_phone}</div>
                    </div>
                    <div className="deet">
                        <span>TSP Email</span>
                        <div className="field">{this.props.faultDetails.tsp_email}</div>
                    </div>
                </div>

                <div className="clearfix"></div>

            </div>

            <div className="deets_sect">
                <div className="cell small-12 small-pad">
                    <div className="title">Fault Details</div>
                </div>

                <div className="cell small-12 small-pad">
                    <div className="deet">
                        <span>Technician Name</span>
                        <div className="field">{this.props.faultDetails.technician}</div>
                    </div>
                    <div className="deet">
                        <span>Bar Name</span>
                        <div className="field">{this.props.faultDetails.barname}</div>
                    </div>

                </div>

                <div className="cell small-6 small-pad">


                    <div className="deet">
                        <span>Urgency</span>
                        <div className="field">{this.props.faultDetails.urgency}</div>
                    </div>
                    <div className="deet">
                        <span>Description</span>
                        <div className="field">{this.props.faultDetails.issue}</div>
                    </div>

                </div>

                <div className="cell small-6 small-pad">
                    <div className="deet">
                        <span>Actioned</span>
                        <div className="field">{this.props.faultDetails.actioned}</div>
                    </div>
                    <div className="deet">
                        <span>Who has the fault been reported to?</span>
                        <div className="field">{this.props.faultDetails.responsible_party}</div>
                    </div>


                </div>

                <div className="clearfix" />

            </div>
            
                

                

                <div className="cell small-12 deets_sect">
                    <div className="title"> Uploaded images</div>
                    {this.props.faultDetails.uploads.map((upload, c) => {
                        return <div className="cell small-3"><img className="uploadImg" src={upload.image}/></div>
                    })
                    }
                </div>

                <div className="cell small-12 deets_sect">
                    <div className="title"> Uploaded videos</div>
                    {this.props.faultDetails.videos &&

                    <div>
                        {this.props.faultDetails.videos.map((vid, c) => {
                            return <div className="cell small-3">{vid.description} <a href={vid.url}><div className="av-button">View Video</div></a> </div>
                        })
                        }
                    </div>
                    }
                </div>

                <div className="cell small-12 deets_sect">
                    <div className="title"> Fix Suggestions</div>
                    {this.props.faultDetails.fix_suggestions &&

                        <div>
                            {this.props.faultDetails.fix_suggestions.map((suggestion, c) => {
                                return <div className="cell small-4">{suggestion.productName} <a target="_blank" href={suggestion.productLink}><div className="av-button">View Product</div></a> </div>
                            })
                            }
                        </div>
                    }
                </div>
                </div>
        } else {
            return <div>Loading...</div>

        }
    }
}

export default FaultsBody;
