import React from 'react'
import {SECTIONS} from '../../resources/strings'
import { Link } from "react-router-dom";
import Axios from "axios";
import {API} from "../../api/APIClient";



class  NavBar extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            showingContact: false,
            hamburgerMenuOpen: false,
            screenWidth : window.innerWidth,
            showWelcomeMsg: this.props.showWelcomeMsg
        };
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {

        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ hamburgerMenuOpen: false, screenWidth: window.innerWidth });
    };

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });


    }



    showHideContact()
    {
        var newContact = !this.state.showingContact
        this.setState({showingContact: newContact, msg:"", title: "", msgType: "question"})
    }


    sendFeedback()
    {
        if (this.state.msg) {
            const formData = new FormData();
            formData.append('title', this.state.title );
            formData.append('msg', this.state.msg);
            formData.append('msgType', this.state.msgType);


            Axios
                .post(API.feedback.sendFeedback, formData)
                .then((res) => {

                    if (res.data.success) {

                        alert("Thank you for your feedback.")
                        this.showHideContact()
                    } else {
                        var msg = "Error sending message, please check the details entered and try again."
                        alert(msg)

                    }
                }).catch(error => {

                var msg = "Error saving user. "

                if (error.response) {
                    if (error.response.data.error) {

                        msg = msg + error.response.data.error

                    }

                    alert(msg)
                } else {
                    alert(error)

                }
            })
        } else {
            alert("Please fill in the Message field.")
        }

    }

    hideWelcome() {
        this.setState({showWelcomeMsg:false})
        this.props.welcomeHandler()
    }

    render() {
        return(
            <div className="navbar">

                <div className="navinfo"><Link to={`/` +  SECTIONS.CLIENTS + `/clid/LTFfbm9uZQ==`}><h1>Dashboard</h1></Link>
                    <span className="lia"> Logged in as {this.props.user.firstname} {this.props.user.surname}
                        {API.BASE_URL == "https://testavanidashapi.app-cms.com/"  && <span style={{background:"orange"}}><br/>!!! TEST ENVIRONMENT !!!</span>}</span>
                    <span className="hamburger" onClick={() => this.setState({hamburgerMenuOpen:!this.state.hamburgerMenuOpen})}></span>

                </div>

                {this.state.hamburgerMenuOpen && <div>{(this.props.user.admin === "1" || this.props.user.technician === "1")?

                    <div className="navbuttons">
                        {(this.props.user.admin === "1") &&
                        <Link to={`/` + SECTIONS.NOTIFICATIONS}><div className={(this.props.currentSection === SECTIONS.NOTIFICATIONS) ? "notifbutton active_section" : "notifbutton" }>⚑ {this.props.unread}</div></Link>}
                        <Link to={`/` + SECTIONS.CLIENTS+'clid/LTFfbm9uZQ=='}><div className={(this.props.currentSection === SECTIONS.CLIENTS) ? "navbutton active_section" : "navbutton"}>Clients</div></Link>
                        <Link to={`/` + SECTIONS.ADMIN}><div className={(this.props.currentSection === SECTIONS.ADMIN) ? "navbutton active_section" : "navbutton"}>Admin</div></Link>
                        <Link to={`/` + SECTIONS.SETTINGS}><div className={(this.props.currentSection === SECTIONS.SETTINGS) ? "navbutton active_section":"navbutton"} >Settings</div></Link>
                        <div className="navbutton" onClick={this.props.logoutHandler}>Logout</div>

                    </div>
                    :
                    <div className="navbuttons">
                        <Link to={`/` + SECTIONS.CLIENTS+'clid/LTFfbm9uZQ=='}><div className={(this.props.currentSection === SECTIONS.CLIENTS) ? "navbutton active_section" : "navbutton"}>Overview</div></Link>

                        <div onClick={()=>this.showHideContact()} className="navbutton">Contact Avani</div>
                        <Link to={`/` + SECTIONS.SETTINGS}><div className={(this.props.currentSection === SECTIONS.SETTINGS) ? "navbutton active_section":"navbutton"} >Settings</div></Link>

                        <div className="navbutton" onClick={this.props.logoutHandler}>Logout</div>

                    </div>
                }</div>}
                {(this.state.screenWidth > 700 && (this.props.user.admin === "1" || this.props.user.technician === "1"))?

                    <div className="navbuttons">
                        {(this.props.user.admin === "1") &&
                        <Link to={`/` + SECTIONS.NOTIFICATIONS}><div className={(this.props.currentSection === SECTIONS.NOTIFICATIONS) ? "notifbutton active_section" : "notifbutton" }>⚑ {this.props.unread}</div></Link>}
                        <Link to={`/` + SECTIONS.CLIENTS+'clid/LTFfbm9uZQ=='}><div className={(this.props.currentSection === SECTIONS.CLIENTS) ? "navbutton active_section" : "navbutton"}>Clients</div></Link>
                        <Link to={`/` + SECTIONS.ADMIN}><div className={(this.props.currentSection === SECTIONS.ADMIN) ? "navbutton active_section" : "navbutton"}>Admin</div></Link>
                        <Link to={`/` + SECTIONS.SETTINGS}><div className={(this.props.currentSection === SECTIONS.SETTINGS) ? "navbutton active_section":"navbutton"} >Settings</div></Link>
                        <div className="navbutton" onClick={this.props.logoutHandler}>Logout</div>

                    </div>
                    :
                    <>{this.state.screenWidth > 700 &&
                    <div className="navbuttons">
                        <Link to={`/` + SECTIONS.CLIENTS+'clid/LTFfbm9uZQ=='}><div className={(this.props.currentSection === SECTIONS.CLIENTS) ? "navbutton active_section" : "navbutton"}>Overview</div></Link>

                        <div onClick={()=>this.showHideContact()} className="navbutton">Contact Avani</div>
                        <Link to={`/` + SECTIONS.SETTINGS}><div className={(this.props.currentSection === SECTIONS.SETTINGS) ? "navbutton active_section":"navbutton"} >Settings</div></Link>

                        <div className="navbutton" onClick={this.props.logoutHandler}>Logout</div>

                    </div>}</>
                }

                {this.state.showWelcomeMsg && <div className="modal">
                    <div className="modal-contact-box main-wrap">
                        <div className="cell small-12 small-pad title">Welcome to Avani</div>

                        <div className="cell small-12 no-pad">
                            <div className="cell small-12 no-pad" style={{textAlign:"left", paddingTop:"20px", paddingBottom:"20px"}}>
                                <span>{this.props.user.note}</span>
                            </div>
                        </div>
                        <div className="av-button" onClick={()=>this.hideWelcome()}>Close</div>
                    </div></div>

                }
                {( this.state.showingContact ) && <div className="modal">
                    <div className="modal-contact-box main-wrap">
                        <div className="cell small-12 small-pad title">Contact Avani</div>

                        <div className="cell small-12 small-pad contact-section">
                            <div className="cell small-6">
                                <span>Type of Message</span>
                                <select className="edit-field" value={this.state.msgType} name="msgType" onChange={this.handleChange}>
                                    <option value="question">Question/Enquiry</option>
                                    <option value="observation">Observation</option>
                                    <option value="feedback">Feedback</option>
                                </select>
                            </div>
                            <div className="cell small-6">
                                <span>Related To</span>
                                <select className="edit-field" value={this.state.related} name="related" onChange={this.handleChange}>
                                    <option value="general">General</option>
                                    <option value="specific">Specific</option>
                                    <option value="venue">Venue</option>
                                </select>
                            </div>
                        </div>

                        <div className="cell small-12 small-pad contact-section">
                            <div className="cell small-12">
                                <span>Title</span>
                                <input className="edit-field" type="text" name="title" placeholder="" value={this.state.title} onChange={this.handleChange}/>
                            </div>
                            

                            <div className="cell small-12">
                                <span>Message</span>
                                <textarea value={this.state.msg} onChange={this.handleChange} name="msg"  className="edit-field" />
                            </div>
                            

                        </div>

                        <div className="cell small-12 small-pad contact-section">
                            <div className="cell small-12">
                                <span>Uploads</span>
                                <input type="file" className="form-control" multiple onChange={this.onChangeHandler}/>
                            </div>


                        </div>


                        <div className="av-button"  onClick={()=>this.sendFeedback()}>Send</div>
                        <div className="av-button" onClick={()=>this.showHideContact()}>Cancel</div>
                    </div></div>}

            </div>

        );
    }
}

export default NavBar;
