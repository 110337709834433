import Axios from "axios";
import {API} from "../../api/APIClient"
import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";


class ClientAdmin extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            tableData: undefined,
            page: 0,
            archpage:0,
            rowsPerPage: 10
        };
        this.handleChange = this.handleChange.bind(this);

    }


    handleChange(event) {

        const target = event.target;
        const name = target.name;

        this.setState({
            [name]: target.value
        }, ()=>this.updateMatching());



    }

    componentDidMount() {
        setTimeout(
            () => this.getClients(),
            100
        );
    }

    updateMatching()
    {
        var matchingClients = []
        for (var c = 0; c < this.state.clients.length; c++) {
            if (! this.state.search || this.state.clients[c].name.toLowerCase().includes(this.state.search.toLowerCase()) ){
                matchingClients.push(this.state.clients[c])
            }
        }



        var matchingArchivedClients = []
        for (var c = 0; c < this.state.archivedClients.length; c++) {
            if (! this.state.search || this.state.archivedClients[c].name.toLowerCase().includes(this.state.search.toLowerCase()) ){
                matchingArchivedClients.push(this.state.archivedClients[c])
            }
        }

        this.setState({
            tableData: matchingClients.map((client, c) => {

                return <TableRow key={c}>
                    <TableCell component="th" scope="row">{client.name}</TableCell>
                    <TableCell component="th" scope="row">{client.venues} Venues</TableCell>
                    <TableCell>
                        <div onClick={() => this.props.didSelectClient(client.id)} className="av-button" style={{padding: "2px 30px"}}>View</div>
                    </TableCell>
                </TableRow>

            }),
            archivedTableData: matchingArchivedClients.map((client, c) => {

                return <TableRow key={c}>
                    <TableCell component="th" scope="row">{client.name}</TableCell>
                    <TableCell component="th" scope="row">{client.venues} Venues</TableCell>
                    <TableCell>
                        <div onClick={() => this.props.didSelectClient(client.id)} className="av-button" style={{padding: "2px 30px"}}>View</div>
                    </TableCell>
                </TableRow>

            })
        })
    }




    getClients() {
        const formData = new FormData();

        Axios
            .post(API.clients.adminClientsList, formData)
            .then((res) => {

                if (res.data.success) {

                        this.setState({
                            clients: res.data.clients,
                            archivedClients: res.data.archived,
                        }, () => this.updateMatching())


                }
            })
    }


    render() {

        return (
            <div className="cell small-12 admin-wrap">

                    <div className="admin-set-box">

                        <div className="cell small-12 title-box">
                            <div className="cell small-4 title"  style={{borderBottom: "0px"}}>Existing Clients</div>
                            <div className="cell small-4">
                                <div className="av-button"  onClick={() => this.props.didSelectClient(-1)}>Add New Client</div>
                            </div>
                            <div className="cell small-4 search">
                                <input type="text" placeholder="Search"  name="search" value={this.state.search} onChange={this.handleChange}/>
                            </div>
                        </div>


                        
                        <div className="clearfix" />

                    </div>

                <div className="cell small-12 no-pad">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>

                            </TableHead>
                            <TableBody>
                                {this.state.tableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.tableData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.tableData &&
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component={Paper}
                        count={this.state.tableData === undefined ? 0 : this.state.tableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={(event, newPage) => {

                            this.setState({page: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({page: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>

                <div className="cell small-12 small-pad" />

                <div className="admin-set-box">
                    <div className="cell small-12 title-box">
                        <div className="title">Archived Clients</div>
                    </div>

                    <div className="cell small-8">
                        &nbsp;
                    </div>
                    <div className="cell small-4 search">
                        <input type="text" placeholder="Search"  name="search" value={this.state.search} onChange={this.handleChange}/>
                    </div>

                    <div className="clearfix" />
                </div>

                <div className="cell small-12 no-pad">

                    <TableContainer component={Paper}>
                        <Table  size="small" aria-label="clean results">
                            <TableHead>

                            </TableHead>
                            <TableBody>
                                {this.state.archivedTableData === undefined ? <TableRow><TableCell>Loading...</TableCell></TableRow> : this.state.archivedTableData.slice(this.state.archpage * this.state.rowsPerPage, this.state.archpage * this.state.rowsPerPage + this.state.rowsPerPage)}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    {this.state.archivedTableData &&
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component={Paper}
                        count={this.state.archivedTableData === undefined ? 0 : this.state.archivedTableData.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.archpage}
                        onChangePage={(event, newPage) => {

                            this.setState({archpage: newPage})
                        }}
                        onChangeRowsPerPage={(event) => {
                            this.setState({archpage: 0, rowsPerPage : parseInt(event.target.value, 10)})
                        }}
                    />}
                </div>

            </div>


        );
    }
}

export default ClientAdmin;